import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'
import { useState } from 'react'

import { EditIcon } from 'common/assets/EditIcon'
import { TrashIcon } from 'common/assets/TrashIcon'
import { Contribution, ID } from 'common/types'
import { formatDate } from 'common/utils/date'
import { cacheKeys } from 'common/constants'
import { ConfirmModal, ErrorModal } from 'common/components'
import { ButtonIcon } from 'common/components/ButtonIcon'

import { useDeleteContribution } from '../api/useDeleteContribution'

interface InstructorContributionNoEditProps extends Contribution {
    turnOnEdit: () => void
    userId: ID
}

export function InstructorContributionNoEdit({
    dueDate,
    id,
    isPaid,
    name,
    turnOnEdit,
    userId,
    paidAt
}: InstructorContributionNoEditProps) {
    const { t } = useTranslation()

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [deleteErrorModal, setDeleteErrorModal] = useState(false)

    const deleter = useDeleteContribution(userId, id)
    const cacheKey = cacheKeys.adminGetUserDetails(userId)

    const { trigger } = useSWRMutation(cacheKey, deleter, {
        throwOnError: false,
        onError: () => {
            handleDeleteModalClose()
            setDeleteErrorModal(true)
        },
        onSuccess: () => {
            handleDeleteModalClose()
        }
    })

    const handleDeleteClick = () => setShowConfirmModal(true)
    const handleDeleteModalClose = () => setShowConfirmModal(false)
    const handleDeleteErrorModalClose = () => setDeleteErrorModal(false)

    const handleDeleteConfirm = () => trigger()

    return (
        <>
            <UserPropertiesElement>
                <Content>
                    <p className="title">{name}</p>
                    <p>
                        {formatDate(isPaid ? (paidAt as string) : (dueDate as string))} -{' '}
                        {isPaid
                            ? t('admin.editUser.contributionsSection.paidLabel')
                            : t('admin.editUser.contributionsSection.toPayLabel')}
                    </p>
                </Content>
                <ButtonsManage>
                    <EditButton onClick={turnOnEdit} aria-label="Edytuj składkę">
                        <EditIcon />
                    </EditButton>
                    <DeleteButton onClick={handleDeleteClick} aria-label="Usuń składkę">
                        <TrashIcon />
                    </DeleteButton>
                </ButtonsManage>
            </UserPropertiesElement>
            <ConfirmModal
                isOpen={showConfirmModal}
                onClose={handleDeleteModalClose}
                onConfirm={handleDeleteConfirm}
                cancelLabel={t('admin.editUser.contributionsSection.delete.comfirmModal.cancel')}
                confirmLabel={t('admin.editUser.contributionsSection.delete.comfirmModal.confirm')}
                description={t('admin.editUser.contributionsSection.delete.comfirmModal.description', {
                    contributionName: name
                })}
                title={t('admin.editUser.contributionsSection.delete.comfirmModal.title')}
            />
            <ErrorModal
                isOpen={deleteErrorModal}
                onClose={handleDeleteErrorModalClose}
                onConfirm={handleDeleteErrorModalClose}
                confirmLabel={t('admin.editUser.contributionsSection.delete.deleteErrorModal.confirm')}
                description={t('admin.editUser.contributionsSection.delete.deleteErrorModal.description')}
                title={t('admin.editUser.contributionsSection.delete.deleteErrorModal.title')}
            />
        </>
    )
}

const UserPropertiesElement = styled.div`
    display: flex;

    width: 100%;
`

const Content = styled.div`
    flex-grow: 1;

    p {
        font-size: 0.75rem;

        margin: 0;
    }

    p.title {
        font-weight: 700;
    }
`

const ButtonsManage = styled.div`
    display: flex;
    align-items: center;
`

const EditButton = styled(ButtonIcon)`
    > svg {
        width: 1.125rem;
        height: 1.125rem;
    }
`

const DeleteButton = styled(EditButton)`
    > svg {
        width: 1.375rem;
        height: 1.375rem;
    }
`
