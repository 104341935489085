import { useController } from 'react-hook-form'
import { Props as ReactSelectProps } from 'react-select'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import AsyncSelect from 'react-select/async'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { ID, SelectOption } from 'common/types'
import { ValidationError } from 'common/components'
import { SearchIcon } from 'common/assets/SearchIcon'
import { useGetAdminUserSearch } from 'common/api'

import { MembersFormData } from '../types/membersFormData'

type CallbackForResults = (options: SelectOption[]) => void

const classes: ReactSelectProps['classNames'] = {
    control: props => (props.isFocused ? 'wrapper focused' : 'wrapper'),
    placeholder: () => 'placeholder',
    input: () => 'input',
    menu: () => 'menu',
    menuList: () => 'menu-list',
    option: props => (props.isSelected ? 'option selected' : 'option'),
    singleValue: () => 'placeholder',
    dropdownIndicator: () => 'icon-container',
    indicatorSeparator: () => 'separator'
}

type AdminUserSearchFieldProps = {
    groupMembersIds: ID[]
    isDisabled: boolean
}

export function AdminUserSearchField({ groupMembersIds, isDisabled }: AdminUserSearchFieldProps) {
    const { t } = useTranslation()
    const fetcher = useGetAdminUserSearch()

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController<MembersFormData, 'selectedMembers'>({
        name: 'selectedMembers'
    })

    const disabledIds = useMemo(
        () => new Set([...groupMembersIds, ...value.map(singleValue => singleValue.value)]),
        [value, groupMembersIds]
    )

    const fetchUsers = async (inputValue: string, callbackForResults: CallbackForResults) => {
        try {
            const response = await fetcher(inputValue)

            const convertedToOptions: SelectOption[] = response
                .filter(singlePerson => !disabledIds.has(singlePerson.id))
                .map(singlePerson => ({
                    label: `${singlePerson.firstName} ${singlePerson.lastName}`,
                    value: singlePerson.id
                }))
            callbackForResults(convertedToOptions)
        } catch (e) {
            callbackForResults([])
        }
    }

    const debouncedFetchUsers = debounce(
        (inputValue: string, callbackForResults: CallbackForResults) => fetchUsers(inputValue, callbackForResults),
        500
    )

    const loadOptions = (inputValue: string, callbackForResults: CallbackForResults) => {
        if (inputValue.length < 3 || inputValue.length > 128) {
            return callbackForResults([])
        }

        debouncedFetchUsers(inputValue, callbackForResults)
    }

    const handleChange = (option: SelectOption) => {
        onChange([...value, option])
    }

    return (
        <>
            <Label>{t('admin.courseDetails.addNewUsers.inputLabel')}</Label>
            <StyledReactSelect
                // @ts-ignore
                onChange={handleChange}
                value={[]}
                classNames={classes}
                loadOptions={loadOptions}
                placeholder={t('admin.courseDetails.addNewUsers.placeholder')}
                noOptionsMessage={() => t('admin.courseDetails.addNewUsers.noData')}
                loadingMessage={() => t('admin.courseDetails.addNewUsers.loading')}
                components={{
                    DropdownIndicator: () => <StyledSearchIcon />
                }}
                isDisabled={isDisabled}
            />
            {error?.message && <ValidationError>{t(error.message)}</ValidationError>}
        </>
    )
}

const Label = styled.div`
    font-size: 0.75rem;
    color: rgb(35, 60, 152);
    padding-left: 0.625rem;
`

const StyledReactSelect = styled(AsyncSelect)`
    .wrapper {
        border-color: ${props => props.theme.colors.border};
        border-radius: 0;

        box-shadow: none;

        color: ${props => props.theme.colors.blue};

        &:hover {
            border-color: ${props => props.theme.colors.borderDark};
            box-shadow: none;
        }

        &.focused {
            -webkit-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            -moz-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);

            border-color: ${props => props.theme.colors.borderDark};
        }
    }

    .icon-container {
        svg {
            fill: ${props => props.theme.colors.blue};
        }
    }

    .placeholder {
        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;
    }

    .input {
        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;
    }

    .menu {
        margin-top: 0;
        border-radius: 0;

        width: calc(100% - 0.125rem);
        left: 0.0625rem;

        z-index: ${props => props.theme.zIndex.select};
    }

    .menu-list {
        padding-top: 0;
    }

    .option {
        background-color: ${props => props.theme.colors.blueBackground};

        margin-top: 0.375rem;
        margin-bottom: 0.125rem;

        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;

        outline: unset;

        &.selected {
            font-weight: 700;
        }

        &:hover {
            background-color: ${props => props.theme.colors.darkBlueBackground};
            color: ${props => props.theme.colors.white};
        }

        &:active {
            background-color: ${props => props.theme.colors.blueBackground};
        }
    }

    .separator {
        display: none;
    }
` as typeof AsyncSelect

const StyledSearchIcon = styled(SearchIcon)`
    margin-right: 8px;
`
