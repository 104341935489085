import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationPL from './translations/translationPL.json'

const fallbackLng = ['pl']
const supportedLngs = ['pl']

const resources = {
    pl: {
        translation: translationPL
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng,
    supportedLngs
})

export default i18n
