import { useApi } from 'common/hooks'

type UseUpdateProfilePhotoParams = {
    arg: File
}

type UseUpdateProfilePhotoResponse = {
    photoHash: string
}

export function useUpdateProfilePhoto() {
    const api = useApi()

    return (_: string, { arg: file }: UseUpdateProfilePhotoParams) =>
        api.post<any, UseUpdateProfilePhotoResponse>(
            `/user-profile/upload-photo`,
            { file },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
}
