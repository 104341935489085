import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Breakpoint, Breakpoints } from 'common/theme/breakpoint'

import financedByImage from '../assets/financedBy.webp'

export function FinancedBy(props: any) {
    const { t } = useTranslation()

    return (
        <Wrapper {...props}>
            <Label>{t('financedBy')}</Label>
            <StyledImg src={financedByImage} alt="financed-by" />
        </Wrapper>
    )
}

const Wrapper = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;

    z-index: 1;
`

const Label = styled.h5`
    font-size: 0.5rem;
    font-weight: 700;

    text-align: center;

    color: ${props => props.theme.colors.darkBlue};
`

const StyledImg = styled.img`
    width: 100%;

    @media (${Breakpoint.BreakpointsUp(Breakpoints.tablet)}) {
        height: 4.875rem;
        width: auto;
    }
`
