import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppRoutes } from 'common/routing/routes'
import { usePageName } from 'common/hooks'

import { ChatWrapper } from 'features/chat'

export function ChatPage() {
    usePageName('pageHeaders.chat')

    const { chatId } = useParams<{ chatId: string }>()
    const navigate = useNavigate()

    /**
     * Redirect to chat lists if chat id is incorrect
     */
    useEffect(() => {
        if (!chatId || isNaN(Number(chatId))) {
            navigate(AppRoutes.ChatsPage.getPath())
        }
    }, [chatId, navigate])

    return <ChatWrapper chatId={Number(chatId)} />
}
