import { useMemo } from 'react'
import styled from 'styled-components'

import { UserIcon } from 'common/assets/UserIcon'
import { UsersIcon } from 'common/assets/UsersIcon'
import { InstructorIcon } from 'common/assets/InstructorIcon'

import { ChatIconWrapper } from './ChatIconWrapper'
import { UserImage } from './UserImage'

type MemberThumbnailProps = {
    photoHash?: string
    isGroup: boolean
    isInstructor?: boolean
}

export function UserThumbnail({ photoHash, isGroup, isInstructor }: MemberThumbnailProps) {
    const placeholderIcon = useMemo(() => {
        if (isInstructor) {
            return <StyledInstructorIcon />
        }

        if (isGroup) {
            return <StyledGroupIcon />
        }

        return <StyledUserIcon />
    }, [isGroup, isInstructor])

    const placeholder = <ChatIconWrapper>{placeholderIcon}</ChatIconWrapper>

    return photoHash ? <UserImage photoHash={photoHash} fallback={placeholder} /> : placeholder
}

export const ChatMessageIcon = styled.img`
    width: 1rem;
    height: 1rem;

    margin-right: 0.5rem;

    margin-left: auto;
`

const StyledUserIcon = styled(UserIcon)`
    width: 1.5rem;
    height: 1.5rem;

    fill: transparent;

    > path {
        stroke: ${props => props.theme.colors.white};
    }
`

const StyledGroupIcon = styled(UsersIcon)`
    width: 1.5rem;
    height: 1.5rem;

    fill: ${props => props.theme.colors.white};
`

const StyledInstructorIcon = styled(InstructorIcon)`
    width: 1.5rem;
    height: 1.5rem;

    fill: ${props => props.theme.colors.white};
`
