import i18n from 'i18next'

import { ID } from 'common/types'

import { BackendMessage } from '../types/backendMessage'
import { ChatMember } from '../types/chatMember'
import { FrontendMessage } from '../types/frontendMessage'

function getOutgoingMessageSenderName(senderId: ID, chatMembers: ChatMember[]) {
    const sender = chatMembers.find(user => user.userId === senderId)

    if (sender) {
        return sender.fullName
    }

    return i18n.t('chat.unknownSender')
}

export function convertBackendToFrontendMessages(
    { content, context, messageId, senderId, sentAt, type }: BackendMessage,
    chatMembers: ChatMember[]
): FrontendMessage {
    return {
        content,
        context,
        messageId,
        senderFullName: context === 'incoming' ? getOutgoingMessageSenderName(senderId, chatMembers) : '',
        sentAt,
        type
    }
}
