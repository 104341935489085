import { z } from 'zod'

import { emailRegex } from 'common/constants'

export const passwordResetSchema = z.object({
    email: z
        .string({
            required_error: 'resetPassword.emailField.validation.required'
        })
        .regex(emailRegex, 'resetPassword.emailField.validation.invalidEmail')
        .transform(val => val.trim())
})

export type PasswordResetFormData = z.infer<typeof passwordResetSchema>
