import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

export const BaseStyles = createGlobalStyle`
  body {
    margin: 0;

    line-height: 1.5;

    color: #0a0a0a;

    background-color: ${theme.colors.white};
  }

  strong {
    font-weight: 700;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Regular.ttf') format("truetype");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Medium.ttf') format("truetype");
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url('/fonts/OpenSans-Bold.ttf') format("truetype");
    font-weight: 700;
    font-display: swap;
  }

  #root {
    min-height: -webkit-fill-available;
    min-height: fill-available;

    min-height: 100dvh;

    display: flex;
    flex-direction: column;
  }

  * {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;

    color: ${theme.colors.blue};

    font-size: 1rem;

    box-sizing: border-box;
  }
`
