import { useApi } from 'common/hooks'
import { ID } from 'common/types'

export function useDeleteMember(courseId: ID, groupID: ID, memberId: ID, isAdmin?: boolean) {
    const api = useApi()

    return (_: string) => {
        const url = isAdmin
            ? `/admin/courses/${courseId}/groups/${groupID}/members/${memberId}/kick`
            : `/session/instructor/courses/${courseId}/groups/${groupID}/members/${memberId}/kick`

        return api.delete(url)
    }
}
