import { z } from 'zod'

export const getCoursesQuerySchema = z.object({
    region: z.optional(z.string().regex(/^\d+$/).transform(Number)),
    course: z.optional(z.string().regex(/^\d+$/).transform(Number)),
    instructor: z.optional(z.string()),
    startsAtFrom: z.string(),
    startsAtTo: z.string()
})

export type GetCoursesQueryData = z.infer<typeof getCoursesQuerySchema>
