import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, FormTextField } from 'common/components'

import { searchUserFormData, SearchUserFormData } from '../types/searchUserFormData'

type SearchUserFormProps = {
    onSearch: (searchValue: string) => void
}

export function SearchUserForm({ onSearch }: SearchUserFormProps) {
    const { t } = useTranslation()

    const formMethods = useForm<SearchUserFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(searchUserFormData),
        defaultValues: {
            search: ''
        }
    })

    const handleSubmit = (formData: SearchUserFormData) => onSearch(formData.search)

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <FormTextField label="admin.searchUser.field.label" name="search" />
                <StyledButton isSmall>{t('admin.searchUser.searchButtonLabel')}</StyledButton>
            </form>
        </FormProvider>
    )
}

const StyledButton = styled(Button)`
    width: 100%;
`
