import { Link } from 'react-router-dom'
import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { useAuth } from 'features/authorizationProvider/context'
import { LoginForm, loginRequest } from '../features/login'

import { FinancedBy } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

export function LoginPage() {
    const { t } = useTranslation()
    const { login } = useAuth()

    const [loginError, setLoginError] = useState<'wait' | 'other' | false>(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (email: string, password: string) => {
        setLoginError(false)
        setIsLoading(true)
        try {
            const response = await loginRequest({ email, password })

            if (!response.data?.token) {
                throw new Error()
            }

            login(response.data.token)
        } catch (e) {
            const apiError = e as AxiosError<{ message: string }>
            const responseMessage = apiError.response?.data.message
            setLoginError(
                responseMessage && responseMessage.toLowerCase().includes('too many login attempts') ? 'wait' : 'other'
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Content>
                <LoginForm onSubmit={handleSubmit} error={loginError} isLoading={isLoading} />
                <Link to={AppRoutes.GuestHome.getPath()} aria-label="Zaloguj jako gość">
                    <LoginAsGuestButton>{t('login.guestLogin')}</LoginAsGuestButton>
                </Link>
            </Content>
            <FinancedBy />
        </>
    )
}

const Content = styled.div`
    max-width: 320px;
    width: 75%;

    z-index: 1;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`

const LoginAsGuestButton = styled.button`
    cursor: pointer;

    font-weight: 700;
    line-height: 3;

    z-index: 1;

    border: 0;
    padding: 0;
    margin: 1.5rem 0 0 0;

    background: transparent;

    &:hover,
    &:focus-visible,
    &:active {
        opacity: 0.7;
    }

    &&& {
        text-decoration: none;
    }
`
