import { useApi } from 'common/hooks'
import { ID, RequiredCourse } from 'common/types'

interface UseGetGuestSearchCourseDetailsResponse {
    courseId: ID
    courseName: string
    instructorFullName: string
    startsAt: string
    voivodeshipName: string
    description: string
    photoHash: string
    requiredCourses: RequiredCourse[]
}

export function useGetGuestSearchCourseDetails(id: ID) {
    const api = useApi()
    return () => api.get<any, UseGetGuestSearchCourseDetailsResponse>(`/guest/search/course/groups/${id}`)
}
