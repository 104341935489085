import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { RequiredCourse } from 'common/types'

import { CourseSingleRequiredCourse as RequiredCourseComponent } from './CourseSingleRequiredCourse'

export function CourseRequiredCoursesList({ coursesList }: { coursesList: RequiredCourse[] }) {
    const { t } = useTranslation()

    if (!coursesList?.length) {
        return null
    }

    return (
        <Wrapper>
            <Title>{t('searchResults.courseElement.requiredCourses.listTitle')}</Title>
            {coursesList?.map(({ id, ...rest }) => (
                <RequiredCourseComponent {...rest} id={id} key={id} />
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-top: 0.75rem;

    display: flex;
    flex-direction: column;

    gap: 0.75rem;
`

const Title = styled.h6`
    font-weight: 700;

    font-size: 0.875rem;

    margin: 0;
`
