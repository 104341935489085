import axios, { AxiosResponse } from 'axios'

interface LoginRequest {
    email: string
    password: string
}

interface LoginRequestResponse {
    token: string
}

export async function loginRequest({ email, password }: LoginRequest): Promise<AxiosResponse<LoginRequestResponse>> {
    return axios.post<LoginRequestResponse>(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, {
        email,
        password
    })
}
