import styled from 'styled-components'

export const InstructorManageSectionsWrapper = styled.section`
    margin-bottom: 0.75rem;

    > h6 {
        font-weight: 700;

        font-size: 0.875rem;

        margin: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        > button.edit {
            padding: 0;
            margin: 0;

            border: 0;

            background-color: transparent;

            line-height: 0;

            padding: 0 0.75rem;
        }
    }
`
