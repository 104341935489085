import styled from 'styled-components'

export const CourseBoxWrapper = styled.li<{ isActive?: boolean; disablePadding?: boolean }>`
    padding: 1rem;

    display: flex;
    flex-direction: row;

    flex-wrap: wrap;

    background-color: ${props => props.theme.colors.blueBackground};

    transition: all 300ms;

    ${props =>
        props.isActive &&
        `
        background-color: transparent;
    `}

    ${props =>
        props.disablePadding &&
        `
        padding: 0;
    `}
`
