import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type AddUserParams = {
    arg: {
        firstName: string
        lastName: string
        roleId: ID
        email: string
        street?: string
        postalCode?: string
        city?: string
    }
}

type AddUserResponse = {
    userId: ID
}

export function useAddUser() {
    const api = useApi()

    return (_: string, { arg }: AddUserParams) => api.post<any, AddUserResponse>('/admin/users/create', arg)
}
