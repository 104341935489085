import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { CourseGroup } from '../types/courseGroup'

export const useGetCourseGroups = (courseId: ID) => {
    const api = useApi()

    return () => api.get<any, CourseGroup[]>(`/session/instructor/courses/${courseId}/groups`)
}
