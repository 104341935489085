import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type ErrorProps = {
    title: string
    description?: string
}
export function Error({ description, title, ...rest }: ErrorProps) {
    const { t } = useTranslation()

    return (
        <Wrapper {...rest}>
            <Title>{t(title)}</Title>
            {description && <Description>{t(description)}</Description>}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    padding: 1rem;

    background-color: ${props => props.theme.colors.errorBackground};

    text-align: center;

    border-radius: 0.125rem;
    border: 1px solid ${props => props.theme.colors.errorBorder};
`

const Title = styled.p`
    margin: 0;

    font-weight: 700;

    font-size: 0.875rem;

    color: ${props => props.theme.colors.errorFont};
`

const Description = styled.p`
    margin: 0.5rem 0 0 0;

    font-size: 0.75rem;

    color: ${props => props.theme.colors.errorFont};
`
