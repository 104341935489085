import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { AppRoutes } from 'common/routing/routes'
import { CrossIcon } from 'common/assets/CrossIcon'

import { StatusWrapper } from './StatusWrapper'
import { StatusTitle } from './StatusTitle'
import { StatusRedirectLink } from './StatusRedirectLink'

export function FailedPayment() {
    const { t } = useTranslation()

    return (
        <>
            <StatusWrapper>
                <FailIcon />
                <StatusTitle>{t('paymentProcess.failStatus.label')}</StatusTitle>
            </StatusWrapper>
            <StatusRedirectLink to={AppRoutes.SearchPage.getPath()}>
                {t('paymentProcess.failStatus.redirectLabel')}
            </StatusRedirectLink>
        </>
    )
}

const FailIcon = styled(CrossIcon)`
    width: 8rem;
    height: 8rem;
`
