import { useApi } from 'common/hooks'
import { ID, SearchCourse } from 'common/types'

type FetcherProps = {
    voivodeshipId?: ID
    courseId?: ID
    startsAtFrom: string
    startsAtTo: string
    instructorName?: string
    instructorId?: ID
}

export function useGetCourses() {
    const api = useApi()

    const fetcher = (props: FetcherProps) => {
        const query = new URLSearchParams()

        Object.entries(props).forEach(([key, value]) => {
            if (value !== undefined) {
                query.set(key, String(value))
            }
        })

        return api.get<any, SearchCourse[]>(`/search/course/groups?${query.toString()}`)
    }

    return fetcher
}
