import { ZodIssueCode, z } from 'zod'

export const degreeFormData = z
    .object({
        name: z.string({ required_error: 'admin.editUser.degreeSection.form.fields.name.requiredError' }),
        assignedAt: z.date({
            required_error: 'admin.editUser.degreeSection.form.fields.assignedAt.requiredError',
            invalid_type_error: 'admin.editUser.degreeSection.form.fields.assignedAt.requiredError'
        }),
        expiresAt: z.nullable(
            z.date({
                required_error: 'admin.editUser.degreeSection.form.fields.expiresAt.requiredError',
                invalid_type_error: 'admin.editUser.degreeSection.form.fields.expiresAt.requiredError'
            })
        )
    })
    .superRefine((val, ctx) => {
        const { assignedAt, expiresAt } = val

        if (expiresAt) {
            const assignedAtNumbered = Number(assignedAt)
            const expiresAtNumbered = Number(expiresAt)

            if (assignedAtNumbered > expiresAtNumbered) {
                ctx.addIssue({
                    message: 'admin.editUser.degreeSection.form.fields.assignedAt.afterExpires',
                    path: ['assignedAt'],
                    code: ZodIssueCode.custom
                })
            }
        }
    })

export type DegreeFormData = z.infer<typeof degreeFormData>
