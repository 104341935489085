import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CoursesStatuses } from 'common/types'

type CourseStatusTagProps = {
    status: CoursesStatuses
}

export function CourseStatusTag({ status }: CourseStatusTagProps) {
    const { t } = useTranslation()

    const label = useMemo(() => {
        if (status === CoursesStatuses.ACTIVE) {
            // TODO: wyniesc te tlumaczenia do commona a nie pod search
            return 'searchResults.courseElement.statusTag.inProgress'
        }

        if (status === CoursesStatuses.FUTURE) {
            return 'searchResults.courseElement.statusTag.incoming'
        }

        return undefined
    }, [status])

    if (!label) {
        return <></>
    }

    return <Tag status={status}>{t(label)}</Tag>
}

const Tag = styled.span<CourseStatusTagProps>`
    padding: 0 0.5rem;

    border-radius: 0.25rem;

    display: flex;
    width: max-content;

    font-size: 0.75rem;
    font-weight: bold;

    ${props =>
        props.status === CoursesStatuses.ACTIVE &&
        `
        color: ${props.theme.colors.warningFont};
        background-color: ${props.theme.colors.warningBackground};
        border: 1px solid ${props.theme.colors.warningBorder};
    `}

    ${props =>
        props.status === CoursesStatuses.FUTURE &&
        `
        color: ${props.theme.colors.successFont};
        background-color: ${props.theme.colors.successBackground};
        border: 1px solid ${props.theme.colors.successBorder};
    `}
`
