import { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'

import { ID } from 'common/types'
import { ButtonIcon } from 'common/components'
import { dateTimeFormatForBackend } from 'common/utils/date'

import { SendIcon } from '../assets/SendIcon'
import { useSendMessage } from '../api/useSendMessage'
import { BackendMessage } from '../types/backendMessage'

import { ChatTextInput } from './ChatTextInput'

interface ChatNewMessageSectionProps {
    newestMessageId: ID
    setNewestMessages: (messages: BackendMessage[]) => void
    stopRefetchTimer: () => void
    chatId: ID
}

export function ChatNewMessageSection({
    chatId,
    newestMessageId,
    stopRefetchTimer,
    setNewestMessages
}: ChatNewMessageSectionProps) {
    const ref = useRef<HTMLTextAreaElement | null>(null)
    const [errorMessage, setErrorMessage] = useState('')
    const { t } = useTranslation()

    /**
     * Api hooks
     */
    const sendMessage = useSendMessage(Number(chatId))

    /**
     * Api calls functions
     */

    const { trigger: triggerSendMessage, isMutating: isSendingMessage } = useSWRMutation(
        `/chats/${chatId}/send-message`,
        sendMessage,
        {
            throwOnError: false
        }
    )

    const handleSendMessage = async () => {
        const message = ref.current?.value

        if (!message) {
            return
        }

        stopRefetchTimer()

        await triggerSendMessage(
            {
                content: message,
                newestMessageId
            },
            {
                onSuccess: response => {
                    const { createdMessageId, newestMessages } = response
                    const sendedMessage: BackendMessage = {
                        senderId: -1, // its us <3
                        messageId: createdMessageId,
                        type: 'text',
                        context: 'outgoing',
                        content: message,
                        sentAt: dateTimeFormatForBackend(new Date())
                    }

                    setNewestMessages([...newestMessages, sendedMessage])

                    const sendInput = ref.current

                    if (sendInput) {
                        sendInput.value = ''
                        sendInput.style.height = '24px'
                    }
                },
                onError: error => {
                    // eslint-disable-next-line no-console
                    console.error(error)
                    if (!errorMessage) {
                        setErrorMessage(t('chat.error.sendError') as string)
                    }

                    setNewestMessages([])
                }
            }
        )
    }

    return (
        <Wrapper>
            <ChatTextInput ref={ref} />
            <ButtonIcon onClick={handleSendMessage} disabled={isSendingMessage} aria-label="Wyślij wiadomość">
                <SendIcon />
            </ButtonIcon>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;

    margin-top: 0.75rem;

    align-items: center;

    > button {
        margin: 0;
        padding: 0.3125rem 0.625rem;
        display: flex;
        align-items: center;

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }
`
