import { useApi } from 'common/hooks'
import { CourseList, VoivodeshipList } from 'common/types'
import { convertToSelectOptions } from 'common/utils/convertToSelectOptions'

export function useGetSearchConfiguration() {
    const api = useApi()

    return () =>
        Promise.all([
            api.get<any, VoivodeshipList[]>('/dictionary/voivodeships'),
            api.get<any, CourseList[]>('/dictionary/courses')
        ]).then(response => {
            const [voivodeships, courses] = response

            return {
                voivodeships: convertToSelectOptions(voivodeships),
                courses: convertToSelectOptions(courses)
            }
        })
}
