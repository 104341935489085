import { CourseBox } from 'common/components'
import { ID } from 'common/types'

import { AllCoursesSingleElementDetails } from './AllCoursesSingleElementDetails'

type AllCoursesSingleElementProps = {
    id: ID
    name: string
    setAsActive: (id: ID) => void
    isActive: boolean
    isLoggedUser: boolean
    isAdmin: boolean
}

export function AllCoursesSingleElement({
    id,
    name,
    isActive,
    setAsActive,
    isLoggedUser,
    isAdmin
}: AllCoursesSingleElementProps) {
    const handleOpen = (idToOpen: ID) => setAsActive(idToOpen)

    return (
        <CourseBox
            courseName={name}
            changeOpenElement={handleOpen}
            isActive={isActive}
            id={id}
            activeDetails={<AllCoursesSingleElementDetails id={id} isLoggedUser={isLoggedUser} isAdmin={isAdmin} />}
        />
    )
}
