import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface NumberInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export function NumberInput(props: NumberInputProps) {
    return <StyledInput type="number" {...props} />
}

const StyledInput = styled.input`
    border: 1px solid ${props => props.theme.colors.border};

    padding: 0 0.625rem;

    width: 100%;
    height: 2.375rem;

    font-size: 0.75rem;

    color: ${props => props.theme.colors.darkBlue};

    outline: unset;

    &::placeholder {
        color: ${props => props.theme.colors.darkBlue};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }
`
