import styled from 'styled-components'

import { Button } from './Button'

type ToggleButtonProps<LeftValue, RightValue> = {
    leftLabel: string
    leftValue: LeftValue
    rightLabel: string
    rightValue: RightValue
    onChange: (newValue: RightValue | LeftValue) => void
    value: string
}

export function ToggleButton<LeftValue, RightValue>({
    leftLabel,
    rightLabel,
    leftValue,
    rightValue,
    onChange,
    value,
    ...rest
}: ToggleButtonProps<LeftValue, RightValue>) {
    const handleChange = (newValue: LeftValue | RightValue) => () => {
        if (newValue !== value) {
            onChange(newValue)
        }
    }

    return (
        <Wrapper {...rest}>
            <StyledButton isSmall isActive={leftValue === value} onClick={handleChange(leftValue)}>
                {leftLabel}
            </StyledButton>
            <StyledButton isSmall isActive={rightValue === value} onClick={handleChange(rightValue)}>
                {rightLabel}
            </StyledButton>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;

    width: 100%;

    margin-bottom: 0.75rem;
`

const StyledButton = styled(Button)<{ isActive: boolean }>`
    width: 100%;

    border: 1px solid ${props => props.theme.colors.blue};

    text-transform: none;

    font-size: 0.875rem;

    &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:hover {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.blue};
    }

    ${props =>
        !props.isActive &&
        `
        color: ${props.theme.colors.blue};
        background-color: ${props.theme.colors.white};

        &:hover {
            color: ${props.theme.colors.blue};
            background-color: ${props.theme.colors.white};
        }
    `}
`
