import React from 'react'

import { AppRoutes } from 'common/routing/routes'
import { UnauthorizedLayoutState } from 'common/types/unauthorizedLayoutState'
import { Trans } from 'react-i18next'

export const unauthorizedRoutesLayoutConfiguration: Record<string, UnauthorizedLayoutState> = {
    [AppRoutes.LoginPage.getPath()]: {
        isSmallLabel: true,
        pageTitle: <Trans i18nKey="unauthorizedLayout.title" components={{ 1: <br /> }} />,
        showMenu: false,
        useGreyBackground: true
    },
    [AppRoutes.ResetPassword.getPath()]: {
        isSmallLabel: true,
        pageTitle: <Trans i18nKey="unauthorizedLayout.title" components={{ 1: <br /> }} />,
        showMenu: false,
        useGreyBackground: true
    },
    [AppRoutes.Register.getPath()]: {
        isSmallLabel: true,
        pageTitle: <Trans i18nKey="unauthorizedLayout.title" components={{ 1: <br /> }} />,
        showMenu: false,
        useGreyBackground: false
    },

    [AppRoutes.GuestHome.getPath()]: {
        isSmallLabel: false,
        pageTitle: <Trans i18nKey="pageHeaders.home" />,
        showMenu: true,
        useGreyBackground: true
    },
    [AppRoutes.GuestSearch.getPath()]: {
        isSmallLabel: false,
        pageTitle: <Trans i18nKey="pageHeaders.search" />,
        showMenu: true,
        useGreyBackground: false
    },

    [AppRoutes.GuestSearchResults.getPath()]: {
        isSmallLabel: false,
        pageTitle: <Trans i18nKey="pageHeaders.search" />,
        showMenu: true,
        useGreyBackground: false
    },
    [AppRoutes.GuestAllCourses.getPath()]: {
        isSmallLabel: false,
        pageTitle: <Trans i18nKey="pageHeaders.guestCourses" />,
        showMenu: true,
        useGreyBackground: false
    }
}
