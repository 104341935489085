import styled from 'styled-components'

import { CourseMember, UserRoles } from 'common/types'
import { UserThumbnail, ChatWithMember } from 'common/components'

import { MemberName } from './MemberName'

interface CourseMemberProps extends CourseMember {
    isGroup?: boolean
}

export function CourseMembersListElement({ fullName, id, roleName, photoHash, isGroup }: CourseMemberProps) {
    return (
        <Member key={id}>
            <UserThumbnail photoHash={photoHash} isGroup={!!isGroup} isInstructor={roleName === UserRoles.INSTRUCTOR} />
            <MemberName fullName={fullName} roleName={roleName} />
            <ChatWithMember id={id} />
        </Member>
    )
}

const Member = styled.li`
    display: flex;
    align-items: center;
`
