import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import styled from 'styled-components'

import { ID } from 'common/types'
import { Button, ConfirmModal, ControlsWrapper, ErrorModal, SuccessModal } from 'common/components'
import { cacheKeys } from 'common/constants'
import { resetPassword } from 'common/api'

import { useBlockUser } from '../api/useBlockUser'
import { ExtendedProfile } from '../types/extendedUserProfile'

import { EditUserDataForm } from './EditUserDataForm'
import { UserNoEditDetails } from './UserNoEditDetails'

type ModalProps = {
    handleClose: () => void
    handleConfirm: () => void
    cancelLabel: string
    confirmLabel: string
    description: string
    title: string
}

type UserDetailsProps = {
    profile: ExtendedProfile
    userId: ID
}

export function UserDetails({ profile, userId }: UserDetailsProps) {
    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const [confirmModal, setConfirmModal] = useState<null | ModalProps>(null)
    const [errorModal, setErrorModal] = useState<null | ModalProps>(null)
    const [successModal, setSuccessModal] = useState<null | ModalProps>(null)

    const blocker = useBlockUser(userId)
    const cacheKeyToRevalidate = cacheKeys.adminGetUserDetails(userId)

    const { trigger } = useSWRMutation(cacheKeyToRevalidate, blocker, {
        onError: () => {
            handleShowBlockError()
        },
        throwOnError: false
    })

    const onEdit = () => setEdit(true)
    const onCancel = () => setEdit(false)

    const onBlock = () => {
        setConfirmModal({
            handleClose: handleHideConfirmModal,
            handleConfirm: onBlockUserConfirm,
            cancelLabel: t('admin.editUser.blockConfirmModal.cancel'),
            confirmLabel: t('admin.editUser.blockConfirmModal.confirm'),
            description: t('admin.editUser.blockConfirmModal.description'),
            title: t('admin.editUser.blockConfirmModal.title')
        })
    }

    const handleShowBlockError = () => {
        setErrorModal({
            cancelLabel: '',
            confirmLabel: t('admin.editUser.blockErrorModal.confirm'),
            description: t('admin.editUser.blockErrorModal.description'),
            handleClose: handleHideErrorModal,
            handleConfirm: handleHideErrorModal,
            title: t('admin.editUser.blockErrorModal.title')
        })
    }

    const handleHideErrorModal = () => {
        setErrorModal(null)
    }

    const handleHideConfirmModal = () => {
        setConfirmModal(null)
    }

    const handleHideSuccessModal = () => {
        setSuccessModal(null)
    }

    const onBlockUserConfirm = () => {
        trigger()
        handleHideConfirmModal()
    }

    const onPasswordReset = () => {
        setConfirmModal({
            handleClose: handleHideConfirmModal,
            handleConfirm: handlePasswordReset,
            cancelLabel: t('admin.editUser.resetPasswordConfirmModal.cancel'),
            confirmLabel: t('admin.editUser.resetPasswordConfirmModal.confirm'),
            description: t('admin.editUser.resetPasswordConfirmModal.description'),
            title: t('admin.editUser.resetPasswordConfirmModal.title')
        })
    }

    const handlePasswordReset = async () => {
        handleHideConfirmModal()
        try {
            await resetPassword(profile.email)
            setSuccessModal({
                cancelLabel: '',
                confirmLabel: t('admin.editUser.resetPasswordSuccessModal.confirm'),
                description: t('admin.editUser.resetPasswordSuccessModal.description'),
                handleClose: handleHideSuccessModal,
                handleConfirm: handleHideSuccessModal,
                title: t('admin.editUser.resetPasswordSuccessModal.title')
            })
        } catch (e) {
            setErrorModal({
                cancelLabel: '',
                confirmLabel: t('admin.editUser.resetPasswordErrorModal.confirm'),
                description: t('admin.editUser.resetPasswordErrorModal.description'),
                handleClose: handleHideErrorModal,
                handleConfirm: handleHideErrorModal,
                title: t('admin.editUser.resetPasswordErrorModal.title')
            })
        }
    }

    return edit ? (
        <EditUserDataForm profile={profile} onCancel={onCancel} userId={userId} />
    ) : (
        <>
            <ResetPasswordButton isSmall type="button" onClick={onPasswordReset}>
                {t('admin.editUser.controls.resetPassword')}
            </ResetPasswordButton>

            <ControlsWrapper>
                <Button isSmall type="button" onClick={onBlock} disabled={profile.isBlocked}>
                    {t('admin.editUser.controls.block')}
                </Button>
                <Button isSmall type="button" onClick={onEdit}>
                    {t('admin.editUser.controls.edit')}
                </Button>
            </ControlsWrapper>

            <UserNoEditDetails profile={profile} userId={userId} />

            {confirmModal && (
                <ConfirmModal
                    isOpen
                    cancelLabel={confirmModal?.cancelLabel}
                    confirmLabel={confirmModal?.confirmLabel}
                    description={confirmModal?.description}
                    onClose={confirmModal.handleClose}
                    onConfirm={confirmModal.handleConfirm}
                    title={confirmModal.title}
                />
            )}

            {errorModal && (
                <ErrorModal
                    confirmLabel={errorModal.confirmLabel}
                    title={errorModal.title}
                    description={errorModal.description}
                    isOpen
                    onClose={errorModal.handleClose}
                    onConfirm={errorModal.handleConfirm}
                />
            )}

            {successModal && (
                <SuccessModal
                    confirmLabel={successModal.confirmLabel}
                    title={successModal.title}
                    description={successModal.description}
                    isOpen
                    onClose={successModal.handleClose}
                    onConfirm={successModal.handleConfirm}
                />
            )}
        </>
    )
}

const ResetPasswordButton = styled(Button)`
    margin-bottom: 0.875rem;
`
