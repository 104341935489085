import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { ExtendedProfile } from '../types/extendedUserProfile'

export function useGetUserProfile(userId: ID) {
    const api = useApi()

    return () => api.get<any, ExtendedProfile>(`/admin/users/${userId}/profile`)
}
