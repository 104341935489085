import { useApi } from 'common/hooks'
import { ID } from 'common/types'
import { AxiosResponse } from 'axios'

type AddToWalletParams = {
    arg: {
        certId: ID
    }
}

export function useAddToAppleWallet() {
    const api = useApi('v1', true)

    return (_: string, { arg: { certId } }: AddToWalletParams) =>
        api.post<any, AxiosResponse<Blob>>(
            `/certificates/${certId}/add-to-apple-wallet`,
            {},
            {
                responseType: 'blob'
            }
        )
}
