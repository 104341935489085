import { useApi } from 'common/hooks'

import { ProfileFormData } from '../types/profileFormData'

type UsePaymentMockParams = {
    arg: ProfileFormData
}

export function useUpdateProfile() {
    const api = useApi()

    return (_: string, { arg: formData }: UsePaymentMockParams) =>
        api.put<any, undefined>(`/user-profile/update`, formData)
}
