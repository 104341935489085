import styled from 'styled-components'

export const CourseAttachmentsSectionWrapper = styled.section`
    margin-top: 0.75rem;

    > h6 {
        font-weight: 700;

        font-size: 0.875rem;

        margin: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
