import { ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { FormFieldWrapper } from './FormFieldWrapper'
import { NumberInput, NumberInputProps } from '../NumberInput'

interface FormNumberFieldProps extends NumberInputProps {
    name: string
    label?: string
}

export function FormNumberField({ name, defaultValue, label, ...rest }: FormNumberFieldProps) {
    const {
        field: { onChange, ...restField },
        fieldState: { error }
    } = useController({
        name,
        defaultValue
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(Number(event.target.value) || '')
    }

    return (
        <FormFieldWrapper error={error?.message} label={label} name={name}>
            <NumberInput {...rest} {...restField} onChange={handleChange} id={name} />
        </FormFieldWrapper>
    )
}
