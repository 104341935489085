import { useApi } from 'common/hooks'
import { ID, UserRoles } from 'common/types'

type GetRolesResponse = {
    id: ID
    name: UserRoles
    translation: string
}[]

export const useGetRoles = () => {
    const api = useApi()

    return () =>
        api.get<any, GetRolesResponse>(`/dictionary/user-roles`).then(response =>
            response.map(singleRole => ({
                label: singleRole.translation,
                value: singleRole.id
            }))
        )
}
