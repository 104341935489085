import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { formatDate } from 'common/utils/date'
import { Contribution } from 'common/types'

import { UserPropertiesElement } from './UserPropertiesElement'

export function UserContributionBox({ dueDate, isPaid, name, paidAt }: Contribution) {
    const { t } = useTranslation()

    return (
        <Wrapper isPaid={isPaid}>
            <p className="title">{name}</p>
            <p>
                {formatDate(isPaid ? (paidAt as string) : (dueDate as string))} -{' '}
                {isPaid ? t('profile.contributions.paidLabel') : t('profile.contributions.toPayLabel')}
            </p>
        </Wrapper>
    )
}

const Wrapper = styled(UserPropertiesElement)<{ isPaid: boolean }>`
    ${props =>
        !props.isPaid &&
        `
        background-color: ${props.theme.colors.darkBlueBackground};
    `}
`
