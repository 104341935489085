import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AxiosError } from 'axios'

import { AddGroupForm, getAddGroupError } from 'features/addGroupForm'

import { ErrorModal } from 'common/components'
import { SelectOption } from 'common/types'
import { dateFormatForBackend } from 'common/utils/date'
import { AppRoutes } from 'common/routing/routes'

import { AddGroupFormData, addGroupFormData } from '../types/addGroupFormData'
import { useCreateGroup } from '../api/useCreateGroup'

type AddGroupFormProps = {
    voivodeships: SelectOption[]
    courses: SelectOption[]
    instructors: SelectOption[]
}

export function AddAdminGroupForm({ courses, instructors, voivodeships }: AddGroupFormProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const creator = useCreateGroup()

    const [submitError, setSubmitError] = useState('')

    const formMethods = useForm<AddGroupFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(addGroupFormData)
    })

    const { trigger: createGroup, isMutating } = useSWRMutation(`/create-group`, creator, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string }>) => {
            const errorMessage = error.response?.data.message

            const translatedError = t(getAddGroupError(errorMessage || ''))

            setSubmitError(translatedError as string)
        },
        onSuccess: response => {
            navigate(
                AppRoutes.AdminCourseDetailsPage.getPath({
                    courseId: response.courseId,
                    groupId: response.courseGroupId
                })
            )
        }
    })

    const handleSubmit = (dataForm: AddGroupFormData) => {
        createGroup({
            courseId: dataForm.courseId,
            instructorId: dataForm.instructorId,
            maximumMembers: dataForm.maximumMembers,
            voivodeshipId: dataForm.voivodeshipId,
            startsAt: dateFormatForBackend(dataForm.dateRange.from),
            endsAt: dateFormatForBackend(dataForm.dateRange.to)
        })
    }

    const closeError = () => setSubmitError('')

    return (
        <>
            <FormProvider {...formMethods}>
                <AddGroupForm
                    courses={courses}
                    instructors={instructors}
                    voivodeships={voivodeships}
                    handleSubmit={formMethods.handleSubmit(handleSubmit)}
                    isMutating={isMutating}
                    useInstructorField
                />
            </FormProvider>
            <ErrorModal
                confirmLabel={t('addGroup.submitError.confirm')}
                description={submitError}
                isOpen={!!submitError}
                onClose={closeError}
                onConfirm={closeError}
                title={t('addGroup.submitError.title')}
            />
        </>
    )
}
