import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type useSendPushMessageParams = {
    arg: {
        message: string
    }
}

export function useSendPushMessage(courseId: ID, groupId: ID, isAdmin?: boolean) {
    const api = useApi()
    const url = isAdmin
        ? `/admin/courses/${courseId}/groups/${groupId}/push-message`
        : `/session/instructor/courses/${courseId}/groups/${groupId}/push-message`

    return (_: string, { arg }: useSendPushMessageParams) => api.post<any, any>(url, arg)
}
