import { useTranslation } from 'react-i18next'

import { TickIcon } from 'common/assets/TickIcon'
import { CrossIcon } from 'common/assets/CrossIcon'
import { PendingIcon } from 'common/assets/PendingIcon'
import { TrashIcon } from 'common/assets/TrashIcon'
import { CourseCompletionStatus } from 'common/types'

import { MemberMenuElement } from './MemberMenuElement'

type CourseMemberListElementControlsProps = {
    setMemberCourseStatus: (status: CourseCompletionStatus) => void
    userName: string
    handleDelete: () => void
    isAdmin: boolean
    userCompletionStatusName: CourseCompletionStatus
}

export function CourseMemberListElementControls({
    setMemberCourseStatus,
    userName,
    handleDelete,
    isAdmin,
    userCompletionStatusName
}: CourseMemberListElementControlsProps) {
    const { t } = useTranslation()

    const showDelete = true

    const showConfirmDeclineButtons = userCompletionStatusName !== CourseCompletionStatus.WAITING_FOR_APPROVAL

    const showPendingButton = isAdmin || userCompletionStatusName === CourseCompletionStatus.WAITING_FOR_APPROVAL

    const setStatus = (status: CourseCompletionStatus) => () => {
        setMemberCourseStatus(status)
    }

    return (
        <>
            {showConfirmDeclineButtons && (
                <>
                    <MemberMenuElement
                        icon={<TickIcon />}
                        onClick={setStatus(CourseCompletionStatus.SUCCESS)}
                        label={t('memberManage.markAsPass.menuItemLabel')}
                        cancelLabel={t('memberManage.markAsPass.cancelLabel')}
                        confirmLabel={t('memberManage.markAsPass.confirmLabel')}
                        description={t('memberManage.markAsPass.modalDescription', {
                            userName
                        })}
                        title={t('memberManage.markAsPass.modalTitle')}
                    />

                    <MemberMenuElement
                        icon={<CrossIcon />}
                        onClick={setStatus(CourseCompletionStatus.FAILED)}
                        label={t('memberManage.markAsFailed.menuItemLabel')}
                        cancelLabel={t('memberManage.markAsFailed.cancelLabel')}
                        confirmLabel={t('memberManage.markAsFailed.confirmLabel')}
                        description={t('memberManage.markAsFailed.modalDescription', {
                            userName
                        })}
                        title={t('memberManage.markAsFailed.modalTitle')}
                    />
                </>
            )}

            {showPendingButton && (
                <MemberMenuElement
                    icon={<PendingIcon />}
                    onClick={setStatus(CourseCompletionStatus.PENDING)}
                    label={t('memberManage.markAsPending.menuItemLabel')}
                    cancelLabel={t('memberManage.markAsPending.cancelLabel')}
                    confirmLabel={t('memberManage.markAsPending.confirmLabel')}
                    description={t('memberManage.markAsPending.modalDescription', {
                        userName
                    })}
                    title={t('memberManage.markAsPending.modalTitle')}
                />
            )}

            {showDelete && (
                <MemberMenuElement
                    icon={<TrashIcon />}
                    onClick={handleDelete}
                    label={t('memberManage.deleteUser.menuItemLabel')}
                    cancelLabel={t('memberManage.deleteUser.cancelLabel')}
                    confirmLabel={t('memberManage.deleteUser.confirmLabel')}
                    description={t('memberManage.deleteUser.modalDescription', {
                        userName
                    })}
                    title={t('memberManage.deleteUser.modalTitle')}
                />
            )}
        </>
    )
}
