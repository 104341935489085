import useSWR from 'swr'

import { useGetAdminSearchConfiguration } from 'common/api'
import { usePageName } from 'common/hooks'
import { Error, Loader } from 'common/components'

import { AddAdminGroupForm } from 'features/administratorFeatures/addGroup'

export function AdminAddCourseGroup() {
    usePageName('pageHeaders.courseGroupAdd')

    const fetcher = useGetAdminSearchConfiguration()
    const { data, error, isLoading } = useSWR('/admin-search-configurator', fetcher)

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="addGroup.getConfiguratorError.title"
                description="addGroup.getConfiguratorError.description"
            />
        )
    }

    return (
        <AddAdminGroupForm
            courses={data?.courses || []}
            instructors={data?.instructors || []}
            voivodeships={data?.voivodeships || []}
        />
    )
}
