import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { usePageName } from 'common/hooks'
import { Loader, Error, UserProfilePropertiesList } from 'common/components'
import { AppRoutes } from 'common/routing/routes'
import { cacheKeys } from 'common/constants'
import { Certificate, Contribution, Degrees, UserRoles } from 'common/types'

import {
    useGetUserProfile,
    UserDetails,
    UserPhotoSection,
    UserCoursesElement,
    InstructorCourseElement,
    InstructorPropertiesManage,
    InstructorDegreesEdit,
    InstructorDegreesNoEdit,
    InstructorContributionEdit,
    InstructorContributionNoEdit
} from 'features/administratorFeatures/userEdit'

import { UserCertificateBox } from 'features/profile'

export function AdminUserEdit() {
    usePageName('pageHeaders.editUser')

    const { t } = useTranslation()

    const navigate = useNavigate()
    const { userId } = useParams<{ userId: string }>()
    const cacheKey = cacheKeys.adminGetUserDetails(Number(userId))

    /**
     * redirect to search when there is no course id or its not a number
     */
    useEffect(() => {
        if (isNaN(Number(userId))) {
            navigate(AppRoutes.AdminSearchUser.getPath())
        }
    }, [userId, navigate])

    const fetcher = useGetUserProfile(Number(userId))

    const { data: user, error, isLoading } = useSWR(cacheKey, fetcher)

    if (error) {
        return (
            <Error
                title="admin.editUser.getProfileError.title"
                description="admin.editUser.getProfileError.description"
            />
        )
    }

    if (!user || isLoading) {
        return <Loader flexFullPage />
    }

    return (
        <>
            {user.isBlocked && <ErrorWrapper title="admin.editUser.blockedUserLable" />}
            <UserPhotoSection userID={Number(userId)} userPhotoHash={user.photoHash || ''} />
            <UserDetails profile={user} userId={Number(userId)} />

            {user.roleName === UserRoles.USER && (
                <>
                    <UserProfilePropertiesList<Certificate>
                        title={t('admin.editUser.certsLabel')}
                        elements={user.certificates || []}
                        Component={UserCertificateBox as FC}
                    />

                    <UserProfilePropertiesList
                        title={t('admin.editUser.userCourses')}
                        elements={user.courseGroupsAsMember || []}
                        Component={UserCoursesElement}
                    />
                </>
            )}

            {user.roleName === UserRoles.INSTRUCTOR && (
                <>
                    <UserProfilePropertiesList
                        title={t('admin.editUser.instructorCourses')}
                        elements={user.courseGroupsAsInstructor || []}
                        Component={InstructorCourseElement}
                    />
                </>
            )}

            <InstructorPropertiesManage<Degrees>
                elements={user.degrees || []}
                label="admin.editUser.degreeSection.label"
                userId={Number(userId)}
                editComponent={InstructorDegreesEdit as FC}
                noEditComponent={InstructorDegreesNoEdit as FC}
                createEmptyState={{
                    expiresAt: '',
                    id: 0,
                    name: '',
                    obtainedAt: ''
                }}
            />

            <InstructorPropertiesManage<Contribution>
                elements={user.contributions || []}
                label="admin.editUser.contributionsSection.label"
                userId={Number(userId)}
                editComponent={InstructorContributionEdit as FC}
                noEditComponent={InstructorContributionNoEdit as FC}
                createEmptyState={{
                    dueDate: '',
                    id: 0,
                    isPaid: false,
                    name: '',
                    paidAt: ''
                }}
            />
        </>
    )
}

const ErrorWrapper = styled(Error)`
    margin-bottom: 0.75rem;
`
