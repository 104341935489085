import { ChangeEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'

import { convertToMB } from 'common/utils/convertToMB'
import { Button, Error, ValidationError } from 'common/components'

const MAX_FILE_SIZE_IN_MB = 8

type UserImageUploadProps = {
    onSetNewFile: (file: File | undefined) => void
    newFile?: File
    userHasPhoto: boolean
    updater: (_: string, params: { arg: File }) => Promise<unknown>
    onSuccessCallback?: (newFileUrl: string) => void
    revalidateCacheKey: string
}

export function UserImageUpload({
    onSetNewFile,
    newFile,
    userHasPhoto,
    updater,
    revalidateCacheKey,
    onSuccessCallback
}: UserImageUploadProps) {
    const [sizeError, setSizeError] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const inputRef = useRef<null | HTMLInputElement>(null)
    const { t } = useTranslation()

    const { trigger, isMutating } = useSWRMutation(revalidateCacheKey, updater, {
        onError: () => {
            setUploadError(true)
        },
        onSuccess: () => {
            if (newFile) {
                onSuccessCallback?.(URL.createObjectURL(newFile))
            }
            onSetNewFile(undefined)
        },
        throwOnError: false
    })

    const handleUploadButtonClick = () => inputRef.current?.click()

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSizeError(false)

        if (!e.target.files?.length) {
            return
        }

        const file = e.target.files[0]

        if (convertToMB(file.size) > MAX_FILE_SIZE_IN_MB) {
            return setSizeError(true)
        }

        onSetNewFile(file)
    }

    const handleCancel = () => {
        setUploadError(false)
        onSetNewFile(undefined)
        if (inputRef.current?.value) {
            inputRef.current.value = ''
        }
    }

    const handleSave = () => {
        setUploadError(false)
        newFile && trigger(newFile)
    }

    return (
        <UploadWrapper>
            {!newFile ? (
                <UploadButton isSmall onClick={handleUploadButtonClick}>
                    {userHasPhoto ? t('updatePhotoSection.editButtonLabel') : t('updatePhotoSection.addButtonLabel')}
                </UploadButton>
            ) : (
                <SubmitControls>
                    <SubmitButtons isSmall onClick={handleSave} disabled={isMutating}>
                        {t('updatePhotoSection.confirmButtonLabel')}
                    </SubmitButtons>
                    <SubmitButtons isSmall onClick={handleCancel} disabled={isMutating} secondary>
                        {t('updatePhotoSection.cancelButtonLabel')}
                    </SubmitButtons>
                </SubmitControls>
            )}
            {sizeError && (
                <ValidationError>
                    {t('updatePhotoSection.toBigError', { fileSize: MAX_FILE_SIZE_IN_MB })}
                </ValidationError>
            )}
            {uploadError && (
                <StyledError
                    title={t('updatePhotoSection.uploadErrorTitle')}
                    description={t('updatePhotoSection.uploadErrorDescription') as string}
                />
            )}
            <FileInput type="file" ref={inputRef} accept="image/png, image/jpeg" onChange={handleFileChange} />
        </UploadWrapper>
    )
}

const UploadWrapper = styled.div`
    margin-bottom: 0.875rem;
`

const UploadButton = styled(Button)`
    width: 100%;
`

const FileInput = styled.input`
    display: none;
`

const SubmitControls = styled.div`
    display: flex;
    gap: 1rem;
`

const SubmitButtons = styled(Button)`
    width: 100%;

    text-align: center;
`

const StyledError = styled(Error)`
    margin-top: 0.875rem;
`
