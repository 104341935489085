import React from 'react'
import styled from 'styled-components'

interface UserProfilePropertiesListProps<ListElement> {
    title: string
    elements: ListElement[]
    Component: React.FC<ListElement>
    additionalProps?: Record<string, unknown>
}

export function UserProfilePropertiesList<ListElement>({
    elements,
    title,
    Component,
    additionalProps
}: UserProfilePropertiesListProps<ListElement>) {
    if (!elements?.length) {
        return null
    }

    return (
        <Wrapper>
            <Title>{title}</Title>
            <CertsList>
                {elements.map(singleCertificate => (
                    <Component {...singleCertificate} {...additionalProps} key={JSON.stringify(singleCertificate)} />
                ))}
            </CertsList>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 0.75rem;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const Title = styled.h6`
    font-weight: 700;

    font-size: 0.75rem;

    margin: 0 0 0.5rem 0;

    text-transform: uppercase;
`

const CertsList = styled.ul`
    list-style: none;

    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    gap: 0.75rem;
`
