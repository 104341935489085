import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { TickIcon } from 'common/assets/TickIcon'
import { AppRoutes } from 'common/routing/routes'
import { ID } from 'common/types'

import { StatusWrapper } from './StatusWrapper'
import { StatusTitle } from './StatusTitle'
import { StatusRedirectLink } from './StatusRedirectLink'

type SuccessPaymentProps = {
    courseId: ID
}

export function SuccessPayment({ courseId }: SuccessPaymentProps) {
    const { t } = useTranslation()

    return (
        <>
            <StatusWrapper>
                <SuccessIcon />
                <StatusTitle>{t('paymentProcess.successStatus.label')}</StatusTitle>
            </StatusWrapper>
            <StatusRedirectLink to={AppRoutes.CourseDetails.getPath({ courseId })}>
                {t('paymentProcess.successStatus.redirectLabel')}
            </StatusRedirectLink>
        </>
    )
}

const SuccessIcon = styled(TickIcon)`
    width: 8rem;
    height: 8rem;
`
