import styled from 'styled-components'

export const ChatTitle = styled.h3`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;

    margin: 0;
    padding: 0;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`
