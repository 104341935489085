import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { UserRoles } from 'common/types'

type MemberNameProps = {
    roleName?: UserRoles
    fullName: string
    onClick?: () => void
}
export function MemberName({ fullName, roleName, onClick }: MemberNameProps) {
    const { t } = useTranslation()
    return (
        <UserName onClick={onClick}>
            {roleName === UserRoles.INSTRUCTOR ? t('membersList.instructorLabel') : fullName}
        </UserName>
    )
}

const UserName = styled.p`
    font-size: 0.75rem;
    font-weight: 700;

    margin: 0 0 0 0.8125rem;

    width: 100%;
`
