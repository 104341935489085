import { useApi } from 'common/hooks'
import { ID, SearchCourse } from 'common/types'

type GetGuestSearchResultsProps = {
    voivodeshipId?: ID
    startsAtFrom: string
    startsAtTo: string
}

export function useGetGuestSearchResults() {
    const api = useApi()

    const fetcher = (props: GetGuestSearchResultsProps) => {
        const query = new URLSearchParams()

        Object.entries(props).forEach(([key, value]) => {
            if (value !== undefined) {
                query.set(key, String(value))
            }
        })

        return api.get<any, SearchCourse[]>(`/guest/search/course/groups?${query.toString()}`)
    }

    return fetcher
}
