import { ZodIssueCode, z } from 'zod'

import { emailRegex } from 'common/constants'

export const registerFormData = z
    .object({
        firstName: z
            .string({ required_error: 'basicProfileForm.field.firstName.validation.required' })
            .min(3, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
            .max(64, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
            .transform(val => val.trim()),
        lastName: z
            .string({ required_error: 'basicProfileForm.field.lastName.validation.required' })
            .min(3, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
            .max(64, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
            .transform(val => val.trim()),

        street: z
            .string({ required_error: 'basicProfileForm.field.street.validation.required' })
            .min(3, { message: 'basicProfileForm.field.street.validation.lengthError' })
            .max(255, { message: 'basicProfileForm.field.street.validation.lengthError' })
            .transform(val => val.trim()),
        postalCode: z
            .string({ required_error: 'basicProfileForm.field.postalCode.validation.required' })
            .min(5, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
            .max(32, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
            .transform(val => val.trim()),
        city: z
            .string({ required_error: 'basicProfileForm.field.city.validation.required' })
            .min(3, { message: 'basicProfileForm.field.city.validation.lengthError' })
            .max(32, { message: 'basicProfileForm.field.city.validation.lengthError' })
            .transform(val => val.trim()),

        email: z
            .string({ required_error: 'basicProfileForm.field.email.validation.requiredError' })
            .regex(emailRegex, 'basicProfileForm.field.email.validation.incorrectEmail')
            .transform(val => val.trim()),

        password: z
            .string({ required_error: 'basicProfileForm.field.password.validation.requiredError' })
            .min(6, { message: 'basicProfileForm.field.password.validation.tooShort' }),
        passwordRepeat: z
            .string({ required_error: 'basicProfileForm.field.passwordRepeat.validation.requiredError' })
            .min(6, { message: 'basicProfileForm.field.passwordRepeat.validation.tooShort' }),
        note: z.optional(z.string().max(1024, { message: 'basicProfileForm.field.note.validation.tooLong' }))
    })
    .superRefine((val, ctx) => {
        const { password, passwordRepeat } = val

        if (password !== passwordRepeat) {
            ctx.addIssue({
                message: 'basicProfileForm.field.password.validation.incorrectRepeat',
                path: ['password'],
                code: ZodIssueCode.custom
            })
            ctx.addIssue({
                message: 'basicProfileForm.field.passwordRepeat.validation.incorrectRepeat',
                path: ['passwordRepeat'],
                code: ZodIssueCode.custom
            })
        }
    })

export type RegisterFormData = z.infer<typeof registerFormData>
