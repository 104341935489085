import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { AxiosError } from 'axios'

import { Contribution, ID } from 'common/types'
import { Button, ErrorModal, FormDateField, FormTextField } from 'common/components'
import { dateFormatForBackend } from 'common/utils/date'
import { cacheKeys } from 'common/constants'

import { ContributionFormData, contributionFormData } from '../types/contributionFormData'
import { useContributeUpdate } from '../api/useContributeUpdate'
import { useCreateContribute } from '../api/useCreateContribute'

interface InstructorContributionEditProps extends Contribution {
    userId: ID
    turnOffEdit: () => void
    isCreate?: boolean
}

export function InstructorContributionEdit({
    dueDate,
    id,
    name,
    turnOffEdit,
    userId,
    paidAt,
    isCreate = false
}: InstructorContributionEditProps) {
    const [error, setError] = useState('')

    const { t } = useTranslation()
    const updater = useContributeUpdate(userId, id)
    const creator = useCreateContribute(userId)

    const cacheKey = cacheKeys.adminGetUserDetails(userId)

    const formMethods = useForm<ContributionFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(contributionFormData),
        defaultValues: {
            dueDate: dueDate ? new Date(dueDate) : undefined,
            name,
            paidAt: paidAt ? new Date(paidAt) : null
        }
    })

    const { trigger, isMutating } = useSWRMutation(cacheKey, isCreate ? creator : updater, {
        onSuccess: () => {
            turnOffEdit()
        },
        onError: (error: AxiosError) => {
            if (error.response?.status === 409) {
                setError(
                    t('admin.editUser.contributionsSection.form.submitErrorModal.alreadyExistDescription') as string
                )
            } else {
                setError(t('admin.editUser.contributionsSection.form.submitErrorModal.description') as string)
            }
        },
        throwOnError: false
    })

    const handleSubmit = (data: ContributionFormData) => {
        trigger({
            dueDate: dateFormatForBackend(data.dueDate),
            paidAt: data.paidAt ? dateFormatForBackend(data.paidAt) : null,
            name: data.name
        })
    }

    const handleCloseErrorModal = () => setError('')

    return (
        <>
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <FormTextField
                        name="name"
                        placeholder={t('admin.editUser.contributionsSection.form.fields.name.placeholder') as string}
                        label={t('admin.editUser.contributionsSection.form.fields.name.label') as string}
                    />
                    <DatesWrapper>
                        <FormDateField
                            name="dueDate"
                            label={t('admin.editUser.contributionsSection.form.fields.dueDate.label') as string}
                        />
                        <FormDateField
                            name="paidAt"
                            label={t('admin.editUser.contributionsSection.form.fields.paidAt.label') as string}
                        />
                    </DatesWrapper>
                    <ButtonWrapper>
                        <Button secondary isSmall disabled={isMutating} type="button" onClick={turnOffEdit}>
                            {t('admin.editUser.contributionsSection.form.cancel')}
                        </Button>
                        <Button isSmall type="submit" disabled={isMutating}>
                            {t('admin.editUser.contributionsSection.form.confirm')}
                        </Button>
                    </ButtonWrapper>
                </Form>
            </FormProvider>
            <ErrorModal
                confirmLabel={t('admin.editUser.contributionsSection.form.submitErrorModal.confirm')}
                title={t('admin.editUser.contributionsSection.form.submitErrorModal.title')}
                description={error}
                isOpen={!!error}
                onClose={handleCloseErrorModal}
                onConfirm={handleCloseErrorModal}
            />
        </>
    )
}

const Form = styled.form`
    display: flex;
    flex-direction: column;

    width: 100%;
`

const DatesWrapper = styled.div`
    display: flex;

    gap: 0.75rem;
`

const ButtonWrapper = styled.div`
    display: flex;

    gap: 0.75rem;

    > button {
        width: 100%;
    }
`
