import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export function CheckboxInput(props: CheckboxInputProps) {
    return <StyledInput type="checkbox" {...props} />
}

const StyledInput = styled.input`
    width: 1rem;
    height: 1rem;
`
