import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { AdminCourseGroup } from '../types/adminCourseGroup'

export const useGetAdminCourseGroups = (courseId: ID) => {
    const api = useApi()

    return () => api.get<any, AdminCourseGroup[]>(`/admin/courses/${courseId}/groups`)
}
