import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NoDataElement } from 'common/components'
import { InstructorAdminCourse } from 'common/types'

import { InstructorAdminElement } from './InstructorAdminElement'

type InstructorCoursesListByStatusProps = {
    coursesList: InstructorAdminCourse[]
    title: string
    noDataLabel: string
    isAdmin?: boolean
}

export function InstructorAdminCoursesListByStatus({
    coursesList,
    title,
    noDataLabel,
    isAdmin = false
}: InstructorCoursesListByStatusProps) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <Title>{t(title)}</Title>
            <List>
                {coursesList.length ? (
                    coursesList.map(({ id, ...rest }) => (
                        <InstructorAdminElement key={id} {...rest} id={id} isAdmin={isAdmin} />
                    ))
                ) : (
                    <NoDataElement title={noDataLabel} />
                )}
            </List>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0rem;
    }
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    list-style: unset;

    padding: 0;
    margin: 0;
`

const Title = styled.h4`
    text-transform: uppercase;

    font-weight: 700;

    margin: 0 0 0.5rem 0;
`
