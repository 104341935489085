import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type UseUpdateProfilePhotoParams = {
    arg: {
        instructorId: ID
        voivodeshipId: ID
        startsAt: string
        endsAt: string
        maximumMembers: number
    }
}

export function useUpdateCourseGroup(courseId: ID, groupId: ID) {
    const api = useApi()

    return (_: string, { arg }: UseUpdateProfilePhotoParams) =>
        api.put<any, any>(`/admin/courses/${courseId}/groups/${groupId}/update`, arg)
}
