import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'

import { ID } from 'common/types'
import { useGetStatistics } from 'common/api'
import { downloadFile } from 'common/utils/downloadFile'

import { Button } from './Button'

type GetStatisticsBoxProps = {
    type: 'course' | 'group'
    courseId: ID
    groupId?: ID
}

export function GetStatisticsBox({ courseId, type, groupId, ...rest }: GetStatisticsBoxProps) {
    const { t } = useTranslation()

    const fetcher = useGetStatistics(courseId, groupId)

    const { trigger: triggerGetStatistics } = useSWRMutation(`/admin/courses/statistics`, fetcher, {
        throwOnError: false,
        onError: e => {
            // eslint-disable-next-line no-console
            console.error(e)
        },
        onSuccess: response => {
            downloadFile(response, 'statistics.xlsx')
        }
    })

    const handleGet = () => triggerGetStatistics()

    return (
        <GetButton onClick={handleGet} isSmall {...rest}>
            {t(type === 'course' ? 'getStatisticsBox.courseLabel' : 'getStatisticsBox.groupLabel')}
        </GetButton>
    )
}

const GetButton = styled(Button)`
    width: 100%;
`
