import { CourseBoxWrapper } from 'common/components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/date'
import { ID } from 'common/types'

import { CourseGroup } from '../types/courseGroup'

interface GroupElementProps extends CourseGroup {
    isActive: boolean
    setIsActive: (groupId: ID) => void
    number: number
}

export function GroupElement({
    endsAt,
    id,
    participantsCount,
    startsAt,
    voivodeshipName,
    isActive,
    setIsActive,
    number
}: GroupElementProps) {
    const { t } = useTranslation()

    const handleClick = () => {
        setIsActive(id)
    }

    return (
        <StyledWrapper onClick={handleClick} isSelected={isActive} role="button" aria-label="Wybierz grupę">
            <GroupProperty className="title">
                {t('instructorCourseDetails.list.groupLabel')} {number}
            </GroupProperty>
            <GroupProperty>
                {participantsCount} {t('instructorCourseDetails.list.membersLabel')}
            </GroupProperty>
            <GroupProperty>{voivodeshipName}</GroupProperty>
            <GroupProperty>
                {formatDate(startsAt)} - {formatDate(endsAt)}
            </GroupProperty>
        </StyledWrapper>
    )
}

const GroupProperty = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;

    margin: 0;

    line-height: 1.125rem;

    &.title {
        font-weight: 700;
    }
`

const StyledWrapper = styled(CourseBoxWrapper)<{ isSelected: boolean }>`
    flex-direction: column;

    ${props =>
        props.isSelected &&
        `
        background-color: ${props.theme.colors.blue};

        > p {
            color: ${props.theme.colors.white};

        }
    `}
`
