import useSWR from 'swr'

import { Loader, Error, InstructorAdminCoursesListByStatus } from 'common/components'
import { usePageName } from 'common/hooks'

import { useGetAdminCourses } from 'features/administratorFeatures/courses'

export function AdminCourses() {
    usePageName('pageHeaders.adminCourses')

    const fetcher = useGetAdminCourses()

    const { data, error, isLoading } = useSWR(`/admin/courses/collapsed`, fetcher)

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="admin.courses.getCoursesError.title"
                description="admin.courses.getCoursesError.description"
            />
        )
    }

    return (
        <>
            <InstructorAdminCoursesListByStatus
                isAdmin
                coursesList={data?.active || []}
                noDataLabel="admin.courses.noDataLabels.active"
                title="admin.courses.groupsLabel.active"
            />
            <InstructorAdminCoursesListByStatus
                isAdmin
                coursesList={data?.future || []}
                noDataLabel="admin.courses.noDataLabels.future"
                title="admin.courses.groupsLabel.future"
            />
            <InstructorAdminCoursesListByStatus
                isAdmin
                coursesList={data?.past || []}
                noDataLabel="admin.courses.noDataLabels.past"
                title="admin.courses.groupsLabel.past"
            />
        </>
    )
}
