import axios from 'axios'

import { useAuth } from 'features/authorizationProvider/context'

export function useApi(apiVersion: 'v1' | 'v2' | 'v3' = 'v1', getFullResponse = false) {
    const { token, logout } = useAuth()

    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/api/${apiVersion}`,
        headers: {
            Authorization: token
        }
    })

    instance.interceptors.response.use(
        response => (getFullResponse ? response : response?.data),
        error => {
            const statusCode = error.response ? error.response.status : null
            if (statusCode === 401) {
                logout()
            } else {
                return Promise.reject(error)
            }
        }
    )

    return instance
}
