import { ReactElement, useState } from 'react'
import styled from 'styled-components'

import { ButtonIcon, ConfirmModal, MenuItem } from 'common/components'

type MemberMenuElementProps = {
    label: string
    cancelLabel: string
    confirmLabel: string
    description: string
    title: string
    onClick: () => void
    icon: ReactElement
}

export function MemberMenuElement({
    icon,
    label,
    onClick,
    cancelLabel,
    confirmLabel,
    description,
    title
}: MemberMenuElementProps) {
    const [showModal, setShowModal] = useState(false)

    const onHideModal = () => setShowModal(false)
    const onShowModal = () => setShowModal(true)

    const handleConfirm = () => {
        onHideModal()
        onClick()
    }

    return (
        <>
            <StyledMenuItem onClick={onShowModal}>
                <ButtonIcon>
                    {icon}
                    {label}
                </ButtonIcon>
            </StyledMenuItem>
            <ConfirmModal
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}
                description={description}
                isOpen={showModal}
                onClose={onHideModal}
                onConfirm={handleConfirm}
                title={title}
            />
        </>
    )
}

const StyledMenuItem = styled(MenuItem)`
    line-height: 0;

    button {
        margin: 0;

        display: flex;
        align-items: center;

        svg {
            width: 1.5rem;
            height: 1.5rem;

            margin-right: 0.25rem;
        }
    }
`
