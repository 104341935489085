import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NoDataElement } from 'common/components'
import { ID } from 'common/types'

import { UserCourse as UserCourseType } from '../types/userCourse'

import { UserCourse } from './UserCourse'

type CoursesListByStatusProps = {
    coursesList: UserCourseType[]
    title: string
    noDataLabel: string
    activeCourse: ID
    setActiveCourse: (id: ID) => void
}

export function CoursesListByStatus({
    coursesList,
    title,
    activeCourse,
    setActiveCourse,
    noDataLabel
}: CoursesListByStatusProps) {
    const { t } = useTranslation()

    useEffect(() => {
        const element = document.getElementById(`course-${activeCourse}`)

        setTimeout(() => {
            element &&
                element.scrollIntoView({
                    behavior: 'smooth'
                })
        }, 250)
    }, [activeCourse])

    return (
        <Wrapper>
            <Title>{t(title)}</Title>
            <List>
                {coursesList.length ? (
                    coursesList.map(({ id, ...rest }) => (
                        <UserCourse
                            {...rest}
                            key={id}
                            id={id}
                            isActive={activeCourse === id}
                            setAsActive={setActiveCourse}
                        />
                    ))
                ) : (
                    <NoDataElement title={noDataLabel} />
                )}
            </List>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 1rem;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    list-style: unset;

    padding: 0;
    margin: 0;
`

const Title = styled.h4`
    text-transform: uppercase;

    font-weight: 700;

    margin: 0 0 0.5rem 0;
`
