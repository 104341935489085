import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePageName } from 'common/hooks'
import { ToggleButton } from 'common/components'

import { UserCoursesList } from 'features/userCoursesList'
import { AllCoursesComponent } from 'features/allCourses'

export function UserCourses() {
    const { t } = useTranslation()

    usePageName('pageHeaders.userCourses')

    const [displayedSection, setDisplayedSection] = useState<'yourCourses' | 'allCourses'>('yourCourses')

    return (
        <>
            <ToggleButton<'yourCourses', 'allCourses'>
                leftLabel={t('userCourses.yourCourses')}
                rightLabel={t('userCourses.allCourses')}
                leftValue="yourCourses"
                rightValue="allCourses"
                value={displayedSection}
                onChange={setDisplayedSection}
            />

            {displayedSection === 'yourCourses' && <UserCoursesList />}
            {displayedSection === 'allCourses' && <AllCoursesComponent isLoggedUser isAdmin={false} />}
        </>
    )
}
