import useSWR from 'swr'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ID } from 'common/types'
import { Loader, Error, CourseDescriptionAndPhoto, Button, GetStatisticsBox } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

import { useGetCourseDetail } from '../api/useGetCourseDetail'

type AllCoursesSingleElementProps = {
    id: ID
    isLoggedUser: boolean
    isAdmin: boolean
}

export function AllCoursesSingleElementDetails({ id, isLoggedUser, isAdmin }: AllCoursesSingleElementProps) {
    const { t } = useTranslation()
    const fetcher = useGetCourseDetail(id, isLoggedUser)

    const { data, error, isLoading } = useSWR(`/get-all-course-details-${id}`, fetcher)

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return <Error title="allCourses.getDetailsError.title" description="allCourses.getDetailsError.description" />
    }

    return (
        <>
            {isAdmin && <GetStatisticsBox courseId={id} type="course" />}

            <CourseDescriptionAndPhoto
                description={data?.content || ''}
                photoHash={data?.photoHash}
                authorizedImage={isLoggedUser}
            />

            {!isLoggedUser && (
                <Link to={AppRoutes.Register.getPath()} arial-label="Przejdź do rejestracji">
                    <StyledButton isSmall type="button">
                        {t('allCourses.registerToParticipate')}
                    </StyledButton>
                </Link>
            )}
        </>
    )
}

const StyledButton = styled(Button)`
    width: 100%;
`
