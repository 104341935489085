import { useApi } from 'common/hooks'
import { User } from 'common/types'

interface GetUserResponse extends Omit<User, 'photo'> {
    photoHash: string
}

export function useGetUser() {
    const api = useApi()

    return () => api.get<any, GetUserResponse>(`/session`)
}
