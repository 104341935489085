import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useSWR from 'swr'
import { zodResolver } from '@hookform/resolvers/zod'
import useSWRMutation from 'swr/mutation'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetRoles } from 'common/api'
import { Button, Loader, UserBasicForm, Error, FormSelectField, FormTextField, ErrorModal } from 'common/components'
import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'

import { addUserFormSchema, AddUserFormData, useAddUser } from 'features/administratorFeatures/addUser'

export function AdminAddUser() {
    usePageName('pageHeaders.adminAddUser')

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [errorMessage, setErrorMessage] = useState('')

    const fetcher = useGetRoles()
    const creator = useAddUser()

    const { data: roles, error, isLoading } = useSWR(`/dictionary/user-roles`, fetcher)

    const { trigger } = useSWRMutation(`/create/user`, creator, {
        onSuccess: response => {
            navigate(AppRoutes.AdminUserEditPage.getPath({ userId: response.userId }))
        },
        onError: (error: AxiosError<{ message: string }>) => {
            const errorMessage = error.response?.data.message

            if (errorMessage === 'The email has already been taken.') {
                setErrorMessage(t('admin.addUser.createError.alreadyExist') as string)
            } else {
                setErrorMessage(t('admin.addUser.createError.otherError') as string)
            }
        },
        throwOnError: false
    })

    const formMethods = useForm<AddUserFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(addUserFormSchema),
        defaultValues: {
            city: '',
            email: '',
            firstName: '',
            lastName: '',
            postalCode: '',
            street: ''
        }
    })

    const isMutating = false

    const handleSubmit = (formData: AddUserFormData) => {
        trigger({
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            roleId: formData.roleId,
            city: formData.city || undefined,
            postalCode: formData.postalCode || undefined,
            street: formData.street || undefined
        })
    }

    const handleErrorModalClose = () => setErrorMessage('')

    const revalidateAddressFields = () => {
        const errorsFields = Object.keys(formMethods.formState.errors)
        const addressFields = ['street', 'city', 'postalCode']

        if (errorsFields.some(singleErrorField => addressFields.includes(singleErrorField))) {
            addressFields.forEach(singleField => {
                formMethods.trigger(singleField as keyof AddUserFormData)
            })
        }
    }

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return <Error title="admin.addUser.getRolesError.title" description="admin.addUser.getRolesError.description" />
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <FormSelectField
                        name="roleId"
                        label="basicProfileForm.field.role.label"
                        placeholder={t('basicProfileForm.field.role.placeholder')}
                        options={roles || []}
                    />
                    <FormTextField
                        name="email"
                        label="basicProfileForm.field.email.label"
                        placeholder={t('basicProfileForm.field.email.placeholder') as string}
                    />
                    <UserBasicForm useNoteField={false} revalidateAddressFields={revalidateAddressFields} />
                    <SubmitButton type="submit" disabled={isMutating}>
                        {t('admin.addUser.addButtonLabel')}
                    </SubmitButton>
                </form>
            </FormProvider>

            <ErrorModal
                confirmLabel={t('admin.addUser.createError.modalConfirmButton')}
                title={t('admin.addUser.createError.title')}
                description={errorMessage}
                isOpen={!!errorMessage}
                onClose={handleErrorModalClose}
                onConfirm={handleErrorModalClose}
            />
        </>
    )
}

const SubmitButton = styled(Button)`
    width: 100%;

    margin-top: 0.25rem;
`
