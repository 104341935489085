import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'
import { useState } from 'react'

import { formatDate } from 'common/utils/date'
import { Certificate } from 'common/types'
import { useGetCertificate } from 'common/api'
import { DotsIcon } from 'common/assets/DotsIcon'
import { ButtonIcon, ErrorModal, Menu, MenuItem, SuccessModal } from 'common/components'
import { DownloadIcon } from 'common/assets/DownloadIcon'
import { downloadFile } from 'common/utils/downloadFile'

import { UserPropertiesElement } from './UserPropertiesElement'

import { AppleWalletIcon } from '../assets/AppleWalletIcon'
import { GoogleWalletIcon } from '../assets/GoogleWalletIcon'

import { useAddToGoogleWallet } from '../api/useAddToGoogleWallet'
import { useAddToAppleWallet } from '../api/useAddToAppleWallet'

interface UserCertificateBoxPRops extends Certificate {
    displayMenu: boolean
}

export function UserCertificateBox({ courseName, obtainedAt, courseId, id, displayMenu }: UserCertificateBoxPRops) {
    const { t } = useTranslation()

    const [showConfirm, setShowConfirm] = useState('')
    const [showError, setShowError] = useState(false)

    const fetcher = useGetCertificate(courseId)

    const addToGoogleWalletMethod = useAddToGoogleWallet()
    const addToAppleWalletMethod = useAddToAppleWallet()

    const { trigger: triggerGetCert } = useSWRMutation(`download/certificate?courseId=${courseId}`, fetcher, {
        throwOnError: false,
        onSuccess: response => {
            downloadFile(response, 'certificate.pdf')
        }
    })

    const { trigger: addToWalletApple } = useSWRMutation(`/add-to-wallet-apple`, addToAppleWalletMethod, {
        throwOnError: false,
        onError: () => {
            setShowError(true)
        },
        onSuccess: response => {
            downloadFile(response, 'pass.pkpass')
            setShowConfirm('profile.certs.success.appleDescription')
        }
    })

    const { trigger: addToWalletGoogle } = useSWRMutation(`/add-to-wallet-google`, addToGoogleWalletMethod, {
        throwOnError: false,
        onError: () => {
            setShowError(true)
        },
        onSuccess: () => {
            setShowConfirm('profile.certs.success.androidDescription')
        }
    })

    const handleGet = () => triggerGetCert()

    const addToGoogleWallet = () => addToWalletGoogle({ certId: id })
    const addToAppleWallet = () => addToWalletApple({ certId: id })

    const closeError = () => setShowError(false)
    const closeConfirm = () => setShowConfirm('')

    return (
        <>
            <Wrapper>
                <div>
                    <p className="title">{courseName}</p>
                    <p>
                        {formatDate(obtainedAt)} - {t('profile.certs.acquisitionDate')}
                    </p>
                </div>

                {displayMenu && (
                    <Menu
                        arrow
                        menuButton={
                            <ButtonIcon className="menu-icon" aria-label="Pokaż menu">
                                <DotsIcon />
                            </ButtonIcon>
                        }
                        transition
                        direction="bottom"
                        align="end"
                    >
                        <StyledMenuItem onClick={handleGet}>
                            <ButtonIcon>
                                <StyledDownloadIcon /> {t('profile.certs.download')}
                            </ButtonIcon>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={addToGoogleWallet}>
                            <ButtonIcon>
                                <GoogleWalletIcon />
                                {t('profile.certs.addToGoogleWallet')}
                            </ButtonIcon>
                        </StyledMenuItem>
                        <StyledMenuItem onClick={addToAppleWallet}>
                            <ButtonIcon>
                                <AppleWalletIcon /> {t('profile.certs.addToAppleWallet')}
                            </ButtonIcon>
                        </StyledMenuItem>
                    </Menu>
                )}
            </Wrapper>
            <SuccessModal
                isOpen={!!showConfirm}
                onClose={closeConfirm}
                onConfirm={closeConfirm}
                confirmLabel={t('profile.certs.modalConfirmButton')}
                description={t(showConfirm)}
                title={t('profile.certs.success.title')}
            />
            <ErrorModal
                confirmLabel={t('profile.certs.modalConfirmButton')}
                description={t('profile.certs.error.description')}
                title={t('profile.certs.error.title')}
                isOpen={showError}
                onClose={closeError}
                onConfirm={closeError}
            />
        </>
    )
}

const Wrapper = styled(UserPropertiesElement)`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-icon {
        margin: 0;
    }
`

const StyledDownloadIcon = styled(DownloadIcon)`
    fill: ${props => props.theme.colors.blue};
`

const StyledMenuItem = styled(MenuItem)`
    line-height: 0;

    font-size: 0.875rem;

    button {
        margin: 0;

        display: flex;
        align-items: center;

        font-size: 0.875rem;

        svg {
            width: 1rem;
            height: 1rem;

            margin-right: 0.25rem;
        }
    }
`
