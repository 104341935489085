import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { FinancedBy } from 'common/components'
import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'

import { useUser } from 'features/userProvider/contexts'
import { Breakpoint, Breakpoints } from 'common/theme/breakpoint'

export function AdminHome() {
    usePageName('pageHeaders.home')

    const { t } = useTranslation()
    const { user } = useUser()

    return (
        <>
            <Welcome>{t('home.title', { username: user?.firstName || '' })}</Welcome>

            <Wrapper>
                <LinkCard to={AppRoutes.AdminAddUserGroup.getPath()}>{t('admin.home.createGroup')}</LinkCard>
                <LinkCard to={AppRoutes.AdminAllCourses.getPath()}>{t('admin.home.allCourses')}</LinkCard>
                <LinkCard to={AppRoutes.AdminExternalIdentifiers.getPath()}>
                    {t('admin.home.externalIdentifiers')}
                </LinkCard>
            </Wrapper>

            <StyledFinancedBy />
        </>
    )
}

const Welcome = styled.h2`
    font-size: 2.5rem;
    font-weight: 700;

    margin: 0 0 1.5rem 0;
`

const StyledFinancedBy = styled(FinancedBy)`
    margin-top: auto;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
`

const LinkCard = styled(Link)`
    width: 100%;

    @media (${Breakpoint.BreakpointsUp(Breakpoints.tablet)}) {
        width: calc(50% - 0.375rem);
    }

    padding: 1rem;

    background-color: ${props => props.theme.colors.blueBackground};

    text-decoration: none;

    font-weight: 700;
    line-height: 1.25;

    text-align: center;
`
