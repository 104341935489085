import useSWR from 'swr'
import { FC } from 'react'

import { Loader, Error, SearchCoursesList } from 'common/components'

import { useGetAllCourses } from '../api/useGetAllCourses'

import { AllCoursesSingleElement } from './AllCoursesSingleElement'

type AllCoursesComponentProps = {
    isLoggedUser: boolean
    isAdmin: boolean
}

export function AllCoursesComponent({ isLoggedUser, isAdmin }: AllCoursesComponentProps) {
    const fetcher = useGetAllCourses()

    const { data, error, isLoading } = useSWR(`/all-courses`, fetcher)

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="allCourses.getListError.title" description="allCourses.getListError.description" />
    }

    return (
        <SearchCoursesList
            additionalProps={{ isLoggedUser, isAdmin }}
            Component={AllCoursesSingleElement as FC}
            coursesList={data || []}
            noDataTitle="allCourses.noData.title"
            noDataDescription="allCourses.noData.description"
        />
    )
}
