import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ArrowEmpty } from 'common/assets/ArrowEmpty'
import { AppRoutes } from 'common/routing/routes'
import { CourseBoxWrapper, CourseTitle } from 'common/components'
import { InstructorAdminCourse } from 'common/types'
import { formatDate } from 'common/utils/date'

interface InstructorAdminElementProps extends InstructorAdminCourse {
    isAdmin: boolean
}

export function InstructorAdminElement({ courseName, id, isAdmin, endsAt, startsAt }: InstructorAdminElementProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleRedirectToCourseDetails = () => {
        const url = isAdmin
            ? AppRoutes.AdminCourseDetailsPage.getPath({ courseId: id, groupId: undefined })
            : AppRoutes.InstructorCourseDetails.getPath({ courseId: id, groupId: undefined })
        navigate(url)
    }

    return (
        <CourseBoxWrapper onClick={handleRedirectToCourseDetails} role="button" aria-label="Przejdź do kursu">
            <Header>
                <Content>
                    <CourseTitle>{courseName}</CourseTitle>
                    <Dates>
                        {t('common.startDate')}: {formatDate(startsAt)}
                    </Dates>
                    <Dates>
                        {t('common.endDate')}: {formatDate(endsAt)}
                    </Dates>
                </Content>
                <StyledArrow />
            </Header>
        </CourseBoxWrapper>
    )
}

const Header = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
`

const Content = styled.div`
    width: 100%;
`

const StyledArrow = styled(ArrowEmpty)`
    flex-shrink: 0;
    fill: ${props => props.theme.colors.blue};

    rotate: 90deg;

    transition: all 300ms;

    margin-left: 0.75rem;
`

const Dates = styled.p`
    font-size: 0.75rem;

    margin: 0;

    &:first-of-type {
        margin-top: 0.25rem;
    }
`
