import styled from 'styled-components'

export const ControlsWrapper = styled.div`
    display: flex;
    gap: 1rem;

    margin-bottom: 0.875rem;

    button {
        width: 100%;

        justify-content: center;
    }
`
