import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import chats from 'common/assets/chats.svg'
import { ID } from 'common/types'
import { AppRoutes } from 'common/routing/routes'
import { useGetChatId } from 'common/api'
import { ButtonIcon } from './ButtonIcon'

type ChatWithMemberProps = {
    id: ID
    isGroupChat?: boolean
}

export function ChatWithMember({ id, isGroupChat, ...rest }: ChatWithMemberProps) {
    const navigate = useNavigate()

    const getChatId = useGetChatId(id)

    const { trigger } = useSWRMutation(`chats/individual?userId=${id}`, getChatId, {
        onSuccess: response => {
            navigate(AppRoutes.ChatPage.getPath({ chatId: response.id }))
        },
        onError: error => {
            // eslint-disable-next-line no-console
            console.error(error)
        },
        throwOnError: false
    })

    const handleClick = () => {
        isGroupChat ? navigate(AppRoutes.ChatPage.getPath({ chatId: id })) : trigger()
    }

    return (
        <ButtonIcon aria-label="Przejdź do czatu z użytkownikiem">
            <ChatMessageIcon src={chats} onClick={handleClick} {...rest} />
        </ButtonIcon>
    )
}

const ChatMessageIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;

    margin-right: 0.5rem;

    margin-left: auto;
`
