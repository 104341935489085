import { useCallback, useEffect, useRef, useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { useTranslation } from 'react-i18next'

import { ID } from 'common/types'
import { ErrorModal } from 'common/components'

import { convertBackendToFrontendMessages } from '../utils/convertBackendToFrontendMessages'

import { useGetMessages } from '../api/useGetMessages'

import { BackendMessage } from '../types/backendMessage'
import { ChatMember } from '../types/chatMember'
import { FrontendMessage } from '../types/frontendMessage'

import { ChatHeader } from './ChatHeader'
import { ChatMessagesSection } from './ChatMessagesSection'
import { ChatNewMessageSection } from './ChatNewMessageSection'

const AUTO_REFRESH_TIME = 5000

type ChatProps = {
    chatId: ID
    chatName: string
    isGroup: boolean
    chatMembers: ChatMember[]
    chatImageHash?: string
}

export function Chat({ chatId, chatName, isGroup, chatMembers, chatImageHash }: ChatProps) {
    const ref = useRef<HTMLDivElement | null>(null)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()
    const { t } = useTranslation()

    /**
     * Fetching is used only to show loading indicator when we fetchin init and previous messages
     */
    const [fetching, setIsFetching] = useState(false)
    const [morePreviousMessages, setMorePreviousMessages] = useState(true)
    const [wasMessagesFetched, setWasMessagesFetched] = useState(false)
    const [messages, setMessages] = useState<FrontendMessage[]>([])
    const [itemsPerPage, setItemsPerPage] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')

    /**
     * Api hooks
     */
    const getMessages = useGetMessages(Number(chatId))

    /**
     * Api calls functions
     */

    const { trigger: triggerGetMessages } = useSWRMutation(`/chats/${chatId}/messages`, getMessages, {
        throwOnError: false,
        revalidate: false
    })

    /**
     * Stop auto-refresh timer
     */
    const stopAutoRefreshTimer = () => {
        clearTimeout(timerRef.current)
    }

    /**
     * Start auto-refresh timer
     */
    const startAutoRefreshTimer = (callback: () => void) => {
        timerRef.current = setTimeout(callback, AUTO_REFRESH_TIME)
    }

    /**
     * Set messages
     */
    const convertAndSetMessage = useCallback(
        (messages: BackendMessage[], messageAge: 'new' | 'prev') => {
            const convertedMessages = messages.map(singleMessage =>
                convertBackendToFrontendMessages(singleMessage, chatMembers)
            )

            setMessages(prev =>
                messageAge === 'new' ? [...prev, ...convertedMessages] : [...convertedMessages, ...prev]
            )
        },
        [chatMembers]
    )

    /**
     * Handlers for request
     */
    const handleGetPreviousMessages = useCallback(async () => {
        setIsFetching(true)
        const response = await triggerGetMessages(
            {
                itemsPerPage,
                latestMessageId: messages[0]?.messageId
            },
            {
                onSuccess: response => {
                    /**
                     * Init call
                     */
                    if (!wasMessagesFetched) {
                        setWasMessagesFetched(true)
                    }

                    convertAndSetMessage(response.messages, 'prev')
                    setMorePreviousMessages(response.hasMoreMessages)
                },
                onError: error => {
                    // eslint-disable-next-line no-console
                    console.error(error)
                    if (!errorMessage) {
                        setErrorMessage(t('chat.error.previousFetchError') as string)
                    }
                }
            }
        )
        setIsFetching(false)

        return response
    }, [itemsPerPage, messages, triggerGetMessages, wasMessagesFetched, convertAndSetMessage, errorMessage, t])

    const handleGetNewestMessages = useCallback(
        async (newestMessageId?: ID) => {
            await triggerGetMessages(
                {
                    itemsPerPage,
                    newestMessageId
                },
                {
                    onSuccess: response => {
                        const responseMessages = response.messages

                        if (responseMessages.length) {
                            convertAndSetMessage(responseMessages, 'new')
                        }

                        startAutoRefreshTimer(() =>
                            handleGetNewestMessages(
                                responseMessages.length
                                    ? responseMessages[responseMessages.length - 1].messageId
                                    : newestMessageId
                            )
                        )
                    },

                    onError: error => {
                        // eslint-disable-next-line no-console
                        console.error(error)
                        if (!errorMessage) {
                            setErrorMessage(t('chat.error.newestFetchError') as string)
                        }

                        startAutoRefreshTimer(() => {
                            handleGetNewestMessages(newestMessageId)
                        })
                    }
                }
            )
        },
        [triggerGetMessages, itemsPerPage, convertAndSetMessage, errorMessage, t]
    )

    const setNewestMessagesAfterSend = (newMessages: BackendMessage[]) => {
        let newestMessageId = messages[messages.length - 1]?.messageId

        if (newMessages.length) {
            convertAndSetMessage(newMessages, 'new')
            newestMessageId = newMessages[newMessages.length - 1].messageId
        }

        startAutoRefreshTimer(() => {
            handleGetNewestMessages(newestMessageId)
        })

        const messagesWrapper = ref.current

        if (messagesWrapper) {
            setTimeout(() => {
                messagesWrapper.scrollTo(0, messagesWrapper.scrollHeight)
            }, 500)
        }
    }

    const setErrorModalClose = () => {
        setErrorMessage('')
    }

    /**
     * Initial message get
     */
    useEffect(() => {
        if (itemsPerPage && !wasMessagesFetched) {
            handleGetPreviousMessages().then(response => {
                const responseMessages = response?.messages

                const newestMessageId = responseMessages
                    ? responseMessages[responseMessages.length - 1]?.messageId
                    : undefined

                startAutoRefreshTimer(() => {
                    handleGetNewestMessages(newestMessageId)
                })
            })
        }
    }, [itemsPerPage, wasMessagesFetched, handleGetPreviousMessages, handleGetNewestMessages])

    /**
     * Clear timout on destroy
     */
    useEffect(() => stopAutoRefreshTimer, [])

    const newestMessageId = messages[messages.length - 1]?.messageId || 0

    return (
        <>
            <ChatHeader chatName={chatName} isGroup={isGroup} photoHash={chatImageHash} />
            <ChatMessagesSection
                messages={messages}
                hasMorePreviousMessages={morePreviousMessages}
                isLoading={fetching}
                ref={ref}
                getPreviousMessages={handleGetPreviousMessages}
                setItemsPerPage={setItemsPerPage}
            />
            <ChatNewMessageSection
                chatId={chatId}
                newestMessageId={newestMessageId}
                stopRefetchTimer={stopAutoRefreshTimer}
                setNewestMessages={setNewestMessagesAfterSend}
            />
            <ErrorModal
                title={t('chat.error.errorTitle')}
                confirmLabel={errorMessage}
                description={t('chat.error.confirmModalError')}
                isOpen={!!errorMessage}
                onClose={setErrorModalClose}
                onConfirm={setErrorModalClose}
            />
        </>
    )
}
