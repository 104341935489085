import { ID } from 'common/types'

export const cacheKeys = {
    adminCourseGroups: (courseId: ID) => `/admin/courses/${courseId}/groups`,
    adminGroupDetails: (courseId: ID, selectedGroup: ID) => `/admin/courses/${courseId}/groups/${selectedGroup}`,
    adminGetUserDetails: (userId: ID) => `/admin/users/${userId}/profile`,
    instructorCourseGroupDetails: (courseId: ID, selectedGroup: ID) =>
        `get-course-groups-details-${courseId}-${selectedGroup}`,
    getFileAuthorized: (fileHash: string) => `/download/files/${fileHash}`,
    getFileUnauthorized: (fileHash: string) => `/guest/download/files/${fileHash}`
}
