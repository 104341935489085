import { z } from 'zod'

export const searchCourseFormSchema = z.object({
    region: z.optional(z.number()).nullable(),
    course: z.optional(z.number()).nullable(),
    instructor: z.optional(
        z
            .string()
            .max(64, { message: 'search.fields.instructor.tooLong' })
            .transform(val => val.trim())
    ),
    dateRange: z.object(
        {
            from: z.date({
                required_error: 'search.fields.dateRange.from.required',
                invalid_type_error: 'search.fields.dateRange.from.required'
            }),
            to: z.date({
                required_error: 'search.fields.dateRange.to.required',
                invalid_type_error: 'search.fields.dateRange.to.required'
            })
        },
        {
            required_error: 'search.fields.dateRange.required'
        }
    )
})

export type SearchCourseFormData = z.infer<typeof searchCourseFormSchema>
