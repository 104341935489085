import styled from 'styled-components'

export const ChatIconWrapper = styled.div`
    width: 2.8125rem;
    height: 2.8125rem;

    background-color: ${props => props.theme.colors.blue};

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`
