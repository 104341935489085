import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { BackendMessage } from '../types/backendMessage'

type UseGetMessagesParams = {
    arg: {
        latestMessageId?: ID
        newestMessageId?: ID
        itemsPerPage?: number
    }
}

type UseGetMessagesResponse = {
    hasMoreMessages: boolean
    messages: BackendMessage[]
}

export function useGetMessages(chatId: ID) {
    const api = useApi('v2')

    return (_: string, { arg }: UseGetMessagesParams) => {
        const queryStringObject = new URLSearchParams()

        Object.entries(arg).forEach(singlePair => {
            const [key, value] = singlePair

            if (value) {
                queryStringObject.append(key, String(value))
            }
        })

        const qs = queryStringObject.toString()

        const url = `/chats/${chatId}/messages${qs ? `?${qs}` : ''}`

        return api.get<any, UseGetMessagesResponse>(url)
    }
}
