import { z } from 'zod'

export const adminGetCoursesQuerySchema = z.object({
    region: z.optional(z.string().regex(/^\d+$/).transform(Number)),
    course: z.optional(z.string().regex(/^\d+$/).transform(Number)),
    instructor: z.optional(z.string().regex(/^\d+$/).transform(Number)),
    startsAtFrom: z.string(),
    startsAtTo: z.string()
})

export type AdminGetCoursesQueryData = z.infer<typeof adminGetCoursesQuerySchema>
