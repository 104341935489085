export function ProfileImageIcon(props: {}) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="40" height="40" fill="#233C98" />
            <circle cx="20" cy="22" r="10.5" stroke="white" />
            <circle cx="20" cy="22" r="7.5" stroke="white" />
            <circle cx="34" cy="6" r="2.5" stroke="white" />
        </svg>
    )
}
