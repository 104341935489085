import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { ReactNode, useEffect, useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { useSWRConfig } from 'swr'
import { AxiosError } from 'axios'

import { Button, ErrorModal } from 'common/components'
import { AdminUserSearchField } from './AdminUserSearchField'
import { ID } from 'common/types'
import { cacheKeys } from 'common/constants'

import { membersFormData, MembersFormData } from '../types/membersFormData'
import { useAddUsersToGroup } from '../api/useAddUsersToGroup'
import { addMembersErrorsMessages } from '../constants/addMemberErrorsMessages'

import { AdminSelectedNewUsers } from './AdminSelectedNewUsers'
import { AdminAddNewUsersHeader } from './AdminAddNewUsersHeader'
import { AdminUsersError } from './AdminUsersError'

type AdminAddNewUsersFormProps = {
    groupMembersIds: ID[]
    maximumUsersCount: number
    currentUsersCount: number
    courseId: ID
    groupId: ID
    closeEdit: () => void
}

export function AdminAddNewUsersForm({
    groupMembersIds,
    maximumUsersCount,
    currentUsersCount,
    courseId,
    groupId,
    closeEdit
}: AdminAddNewUsersFormProps) {
    const { t } = useTranslation()
    const { mutate } = useSWRConfig()

    const [errorModal, setErrorModal] = useState<string | ReactNode>('')

    const addUsers = useAddUsersToGroup(courseId, groupId)

    const courseGroupsKey = cacheKeys.adminCourseGroups(courseId)
    const courseGroupDetailsKey = cacheKeys.adminGroupDetails(courseId, groupId)

    const formMethods = useForm<MembersFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(membersFormData),
        defaultValues: {
            selectedMembers: [],
            newMembersMaximumCount: maximumUsersCount - currentUsersCount
        }
    })

    const validateGroupError = (mainMessage: string) => {
        const availableErrorList = [
            addMembersErrorsMessages.courseGroupSlotsExceeded,
            addMembersErrorsMessages.courseGroupSignTimeExpired,
            addMembersErrorsMessages.courseGroupAreFull
        ]

        const error = availableErrorList.find(singleAvailableError =>
            mainMessage.startsWith(singleAvailableError.message)
        )

        return error?.translationKey || false
    }

    const validateUserErrors = (errors: Record<string, string>) => {
        const formValues = formMethods.getValues()

        return Object.entries(errors)
            .map(errorEntry => {
                const [key, errorMessage] = errorEntry

                const dotInKeyIndex = key.indexOf('.')

                const userId = Number(key.substring(dotInKeyIndex + 1))

                if (isNaN(userId)) {
                    return ''
                }

                const invalidUserObject = formValues.selectedMembers.find(
                    selectedMember => selectedMember.value === userId
                )

                if (!invalidUserObject) {
                    return ''
                }

                const errorDefinition = Object.values(addMembersErrorsMessages).find(
                    errorMessageDefinition => errorMessageDefinition.message === errorMessage[0]
                )

                return `${invalidUserObject.label} - ${t(
                    errorDefinition?.translationKey ||
                        'admin.courseDetails.addNewUsers.backendValidationErrors.unknownError',
                    t('admin.courseDetails.addNewUsers.backendValidationErrors.unknownError')
                )}`
            })
            .filter(Boolean)
    }

    const { trigger, isMutating } = useSWRMutation(courseGroupsKey, addUsers, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string; errors: Record<string, string> }>) => {
            if (!error.response) {
                return setErrorModal(t('admin.courseDetails.addNewUsers.errorModal.title'))
            }

            const { status, data } = error.response

            if (status === 422) {
                const groupErrorTranslationKey = validateGroupError(data.message)
                if (groupErrorTranslationKey) {
                    return setErrorModal(t(groupErrorTranslationKey))
                }

                const usersError = validateUserErrors(data.errors)

                setErrorModal(<AdminUsersError errorList={usersError} />)
            } else {
                setErrorModal(t('admin.courseDetails.addNewUsers.errorModal.title'))
            }
        },
        onSuccess: () => {
            mutate(courseGroupDetailsKey)
            closeEdit()
        }
    })

    const handleSubmit = (formData: MembersFormData) => {
        const userIds = formData.selectedMembers.map(singleMember => singleMember.value)
        trigger({
            userIds
        })
    }

    const handleCancel = () => closeEdit()

    const closeErrorModal = () => setErrorModal(false)

    useEffect(() => {
        formMethods.setValue('newMembersMaximumCount', maximumUsersCount - currentUsersCount)
        formMethods.trigger('selectedMembers')
    }, [maximumUsersCount, currentUsersCount, formMethods])

    const hasFormErrors = Boolean(Object.values(formMethods.formState.errors).length)

    return (
        <>
            <Wrapper>
                <FormProvider {...formMethods}>
                    <AdminAddNewUsersHeader
                        maximumUsersCount={maximumUsersCount}
                        currentUsersCount={currentUsersCount}
                    />
                    <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                        <AdminSelectedNewUsers />
                        <AdminUserSearchField groupMembersIds={groupMembersIds} isDisabled={isMutating} />

                        <ButtonsWrapper>
                            <Button isSmall secondary type="button" onClick={handleCancel}>
                                {t('admin.courseDetails.addNewUsers.cancel')}
                            </Button>
                            <Button isSmall disabled={hasFormErrors || isMutating} type="submit">
                                {t('admin.courseDetails.addNewUsers.confirm')}
                            </Button>
                        </ButtonsWrapper>
                    </Form>
                </FormProvider>
            </Wrapper>
            <ErrorModal
                confirmLabel={t('admin.courseDetails.addNewUsers.errorModal.confirm')}
                title={t('admin.courseDetails.addNewUsers.errorModal.title')}
                description={errorModal}
                isOpen={!!errorModal}
                onClose={closeErrorModal}
                onConfirm={closeErrorModal}
            />
        </>
    )
}

const Wrapper = styled.div`
    padding-bottom: 0.75rem;

    border-bottom: 1px solid ${props => props.theme.colors.border};
`

const Form = styled.form``

const ButtonsWrapper = styled.div`
    display: flex;

    margin: 0.75rem 0 0;

    gap: 0.75rem;

    > button {
        width: 100%;
    }
`
