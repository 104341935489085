import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ProfileDetails } from 'common/types'

type UserDetailsNoEditProps = Pick<ProfileDetails, 'address' | 'email' | 'firstName' | 'lastName' | 'note'>

export function UserBasicDetailsNoEdit({ email, firstName, lastName, address, note }: UserDetailsNoEditProps) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <NameAndSurname>
                {firstName} {lastName}
            </NameAndSurname>
            <EmailAndAddress>
                {email}

                {address && (
                    <>
                        <br />
                        <br />
                        {address.street} <br />
                        {address.postalCode} {address.city}
                    </>
                )}
            </EmailAndAddress>
            <Note>
                <span>{t('profile.noteSection.label')}</span>:<br />
                {note || t('profile.noteSection.noData')}
            </Note>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 0.875rem;
`

const NameAndSurname = styled.p`
    font-weight: 700;

    margin: 0;
`

const EmailAndAddress = styled.p`
    font-size: 0.75rem;

    margin: 0;
`

const Note = styled.p`
    font-size: 0.75rem;

    white-space: pre-line;

    word-break: break-word;

    margin: 0.75rem 0 0 0;

    > span {
        font-size: 0.75rem;
        font-weight: 500;
    }
`
