import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type AddToWalletParams = {
    arg: {
        certId: ID
    }
}

export function useAddToGoogleWallet() {
    const api = useApi()

    return (_: string, { arg: { certId } }: AddToWalletParams) =>
        api.post<any, any>(`/certificates/${certId}/add-to-google-wallet`)
}
