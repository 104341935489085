import { z } from 'zod'

export const addExternalIdentifiersFormData = z.object({
    numbers: z.string({
        required_error: 'admin.externalIdentifiers.fields.numbers.requiredError',
        invalid_type_error: 'admin.externalIdentifiers.fields.numbers.requiredError'
    })
})

export type AddExternalIdentifiersFormData = z.infer<typeof addExternalIdentifiersFormData>
