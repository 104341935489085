import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/date'

import { AdminCourseGroup } from '../types/adminCourseGroup'

export function AdminSelectedGroupDetailsNoEdit({
    endsAt,
    instructorFullName,
    maximumMembers,
    startsAt,
    voivodeshipName
}: Pick<AdminCourseGroup, 'instructorFullName' | 'voivodeshipName' | 'startsAt' | 'endsAt' | 'maximumMembers'>) {
    const { t } = useTranslation()

    return (
        <div>
            <GroupProperty>
                <span>{t('admin.courseDetails.groupManage.instructor')}:</span> {instructorFullName}
            </GroupProperty>
            <GroupProperty>
                <span>{t('admin.courseDetails.groupManage.voivoidship')}:</span> {voivodeshipName}
            </GroupProperty>
            <GroupProperty>
                <span>{t('admin.courseDetails.groupManage.dates')}:</span> {formatDate(startsAt)} - {formatDate(endsAt)}
            </GroupProperty>
            <GroupProperty>
                <span>{t('admin.courseDetails.groupManage.maxMembersCount')}:</span> {maximumMembers}
            </GroupProperty>
        </div>
    )
}

const GroupProperty = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;

    margin: 0;

    line-height: 1.125rem;

    span {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
    }
`
