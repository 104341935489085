import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { NoDataElement } from 'common/components'
import { ID } from 'common/types'

interface SearchCoursesListProps<CourseType> {
    coursesList: CourseType[]
    Component: FC<CourseType & { setAsActive: (id: ID) => void; isActive: boolean }>
    noDataTitle: string
    noDataDescription: string
    additionalProps?: Record<string, unknown>
}

export function SearchCoursesList<CourseType extends { id: ID }>({
    coursesList,
    Component,
    noDataDescription,
    noDataTitle,
    additionalProps = {}
}: SearchCoursesListProps<CourseType>) {
    const [activeCourse, setActiveCourse] = useState<ID | null>(null)

    useEffect(() => {
        const element = document.getElementById(`course-${activeCourse}`)

        setTimeout(() => {
            element &&
                element.scrollIntoView({
                    behavior: 'smooth'
                })
        }, 250)
    }, [activeCourse])

    if (!coursesList?.length) {
        return <NoDataElement title={noDataTitle} description={noDataDescription} />
    }

    return (
        <Wrapper>
            {coursesList.map(singleCourse => (
                <Component
                    key={singleCourse.id}
                    {...singleCourse}
                    setAsActive={setActiveCourse}
                    isActive={activeCourse === singleCourse.id}
                    {...additionalProps}
                />
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-direction: column;

    gap: 1rem;

    margin-bottom: 2.375rem;
`
