import { useTranslation } from 'react-i18next'

import { Attachment } from 'common/types'

import { CourseAttachmentsSectionWrapper } from './CourseAttachmentsSectionWrapper'
import { CourseAttachmentsListComponents } from './CourseAttachmentsListComponents'

type CourseAttachmentsProps = {
    attachmentList: Attachment[]
    label: string
}

export function CourseAttachments({ attachmentList, label }: CourseAttachmentsProps) {
    const { t } = useTranslation()

    if (!attachmentList?.length) {
        return null
    }

    return (
        <CourseAttachmentsSectionWrapper>
            <h6>{t(label)}</h6>
            <CourseAttachmentsListComponents attachmentList={attachmentList} />
        </CourseAttachmentsSectionWrapper>
    )
}
