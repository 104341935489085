import styled from 'styled-components'

import { CourseImage } from './CourseImage'

type CourseDescriptionAndPhotoProps = {
    description: string
    photoHash?: string
    authorizedImage?: boolean
}

export function CourseDescriptionAndPhoto({
    description,
    photoHash,
    authorizedImage = true
}: CourseDescriptionAndPhotoProps) {
    return (
        <Wrapper>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            {!!photoHash && <CourseImage fileHash={photoHash} authorizedImage={authorizedImage} />}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 0.75rem;
`

const Description = styled.article`
    color: ${props => props.theme.colors.darkBlue};

    font-size: 14px;

    * {
        font-size: 0.875rem;
        color: ${props => props.theme.colors.darkBlue};
    }
    p {
        line-height: 1.125rem;
    }
    ul,
    ol {
        padding-left: 25px;
    }

    h1 {
        font-size: 2.125rem;
    }

    h2 {
        font-size: 1.625rem;
    }

    h3 {
        font-size: 1.295rem;
    }

    h4 {
        font-size: 1.125rem;
    }

    h5 {
        font-size: 0.955rem;
    }

    h6 {
        font-size: 0.875rem;
    }
`
