import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { useState } from 'react'

import {
    Button,
    ControlsWrapper,
    Error,
    ErrorModal,
    FormSelectField,
    FormTextField,
    Loader,
    UserBasicForm
} from 'common/components'
import { ID } from 'common/types'
import { cacheKeys } from 'common/constants'
import { useGetRoles } from 'common/api'

import { ExtendedProfileFormData, extendedProfileFormData } from '../types/extendedProfileFormData'
import { useUpdateUser } from '../api/useUpdateUser'
import { ExtendedProfile } from '../types/extendedUserProfile'

type EditUserDataFormProps = {
    profile: ExtendedProfile
    onCancel: () => void
    userId: ID
}

export function EditUserDataForm({ profile, onCancel, userId }: EditUserDataFormProps) {
    const { t } = useTranslation()
    const [updateError, setUpdateError] = useState(false)

    const cacheKey = cacheKeys.adminGetUserDetails(userId)

    const fetcher = useGetRoles()
    const updater = useUpdateUser(userId)

    const { data: roles, error, isLoading } = useSWR(`/dictionary/user-roles`, fetcher)

    const formMethods = useForm<ExtendedProfileFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(extendedProfileFormData),
        defaultValues: {
            firstName: profile.firstName,
            lastName: profile.lastName,
            city: profile.address?.city || '',
            postalCode: profile.address?.postalCode || '',
            street: profile.address?.street || '',
            email: profile.email,
            roleId: profile.roleId,
            note: profile.note || undefined
        }
    })

    const { trigger: updateUser, isMutating } = useSWRMutation(cacheKey, updater, {
        throwOnError: false,
        onError: () => {
            setUpdateError(true)
        },
        onSuccess: () => {
            onCancel()
        }
    })

    const handleSubmit = (formData: ExtendedProfileFormData) => {
        updateUser({
            ...formData,
            note: formData.note || null,
            city: formData.city || undefined,
            postalCode: formData.city || undefined,
            street: formData.street || undefined
        })
    }

    const handleErrorModalClose = () => setUpdateError(false)

    const revalidateAddressFields = () => {
        const errorsFields = Object.keys(formMethods.formState.errors)
        const addressFields = ['street', 'city', 'postalCode']

        if (errorsFields.some(singleErrorField => addressFields.includes(singleErrorField))) {
            addressFields.forEach(singleField => {
                formMethods.trigger(singleField as keyof ExtendedProfileFormData)
            })
        }
    }

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return (
            <Error title="admin.editUser.getRolesError.title" description="admin.editUser.getRolesError.description" />
        )
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <ControlsWrapper>
                        <Button isSmall secondary type="button" onClick={onCancel}>
                            {t('admin.editUser.controls.cancel')}
                        </Button>
                        <Button isSmall type="submit" disabled={isMutating}>
                            {t('admin.editUser.controls.save')}
                        </Button>
                    </ControlsWrapper>
                    <FormTextField
                        name="email"
                        label="basicProfileForm.field.email.label"
                        placeholder={t('basicProfileForm.field.email.placeholder') as string}
                    />
                    <FormSelectField
                        name="roleId"
                        label="basicProfileForm.field.role.label"
                        placeholder={t('basicProfileForm.field.role.placeholder')}
                        options={roles || []}
                    />
                    <UserBasicForm revalidateAddressFields={revalidateAddressFields} />
                </form>
            </FormProvider>
            <ErrorModal
                confirmLabel={t('admin.editUser.updateDetailsError.confirm')}
                title={t('admin.editUser.updateDetailsError.title')}
                description={t('admin.editUser.updateDetailsError.description')}
                isOpen={updateError}
                onClose={handleErrorModalClose}
                onConfirm={handleErrorModalClose}
            />
        </>
    )
}
