import { ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import logo from '../assets/logo.webp'

interface TopBarProps {
    label: string | ReactNode
    smallLabel?: boolean
}

export function TopBar({ label, smallLabel = false }: TopBarProps) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <Header smallSize={smallLabel}>{typeof label === 'string' ? t(label) : label}</Header>
            <Link to="/" aria-label="Przejdź do strony głównej" role="button">
                <img src={logo} alt="logo" />
            </Link>
        </Wrapper>
    )
}

const Wrapper = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 1.5rem;

    height: 5.5rem;
`

const Header = styled.h1<{ smallSize: boolean }>`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;

    text-transform: uppercase;

    color: ${props => props.theme.colors.blue};

    ${props =>
        props.smallSize &&
        `
        font-size: 1rem;

        text-transform: none;
    `}
`
