import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { BackendMessage } from '../types/backendMessage'

type UseSendMessageParams = {
    arg: {
        content: string
        newestMessageId?: ID
    }
}

type UseSendMessageResponse = {
    createdMessageId: ID
    newestMessages: BackendMessage[]
}

export function useSendMessage(chatId: ID) {
    const api = useApi('v3')

    return (_: string, { arg: { content, newestMessageId } }: UseSendMessageParams) => {
        const queryStringObject = new URLSearchParams()

        queryStringObject.set('itemsPerPage', '500')

        if (newestMessageId) {
            queryStringObject.set('newestMessageId', String(newestMessageId))
        }

        const qs = queryStringObject.toString()

        const url = `/chats/${chatId}/send-message?${qs}`

        return api.post<any, UseSendMessageResponse>(url, {
            content,
            type: 'text'
        })
    }
}
