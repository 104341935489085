import styled from 'styled-components'

export const ButtonIcon = styled.button`
    transition: 500ms all;

    cursor: pointer;

    background-color: transparent;

    border: 0;

    padding: 0;
    margin: 0.5rem;

    line-height: 1rem;

    &:hover,
    &:focus-visible,
    &:active {
        opacity: 0.7;
    }
`
