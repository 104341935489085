import { AxiosResponse } from 'axios'

import { useApi } from 'common/hooks'

export function useGetAsset(url: string) {
    const api = useApi('v1', true)

    return () =>
        api.get<any, AxiosResponse<Blob>>(url, {
            responseType: 'blob'
        })
}
