import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, FormCalendarField, FormSelectField } from 'common/components'
import { SelectOption } from 'common/types'

import { GuestSearchCourseFormData, guestSearchCourseFormData } from '../types/guestSearchCourseFormData'

type GuestCoursesSearchFormProps = {
    regions: SelectOption[]
    defaultData: GuestSearchCourseFormData
    handleSubmit: (formData: GuestSearchCourseFormData) => void
}

export function GuestCoursesSearchForm({ regions, defaultData, handleSubmit }: GuestCoursesSearchFormProps) {
    const { t } = useTranslation()

    const formMethods = useForm<GuestSearchCourseFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(guestSearchCourseFormData),
        defaultValues: defaultData
    })

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <FormSelectField
                    name="region"
                    label="guest.search.fields.region.label"
                    placeholder={t('guest.search.fields.region.placeholder')}
                    options={regions}
                />
                <CalendarInputLabel>{t('guest.search.fields.dateRange.label')}</CalendarInputLabel>

                <FormCalendarField name="dateRange" />
                <StyledButton isSmall type="submit">
                    {t('guest.search.submitLabel')}
                </StyledButton>
            </form>
        </FormProvider>
    )
}

const CalendarInputLabel = styled.div`
    font-size: 0.75rem;

    color: ${props => props.theme.colors.blue};

    padding-left: 0.625rem;
`

const StyledButton = styled(Button)`
    width: 100%;
`
