import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export function PushMessageHeader() {
    const { t } = useTranslation()

    return <Label>{t('pushMessage.pushMessageLabel')}</Label>
}

const Label = styled.h6`
    font-size: 0.875rem;
    font-weight: 700;

    margin: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;

    margin-bottom: 0.75rem;
`
