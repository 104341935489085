import { z } from 'zod'

export const contributionFormData = z.object({
    name: z.string({ required_error: 'admin.editUser.contributionsSection.form.fields.name.requiredError' }),
    dueDate: z.date({
        required_error: 'admin.editUser.contributionsSection.form.fields.dueDate.requiredError',
        invalid_type_error: 'admin.editUser.contributionsSection.form.fields.dueDate.requiredError'
    }),
    paidAt: z.date().nullable()
})

export type ContributionFormData = z.infer<typeof contributionFormData>
