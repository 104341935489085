import { useTheme } from 'styled-components'

type ArrowEmptyProps = {
    onClick?: any
}

export function ArrowEmpty(props: ArrowEmptyProps) {
    const { colors } = useTheme()

    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6.26172 2.0957L1.26172 12.0957L11.2617 12.0957L6.26172 2.0957Z" stroke={colors.blue} />
        </svg>
    )
}
