import { useApi } from 'common/hooks'
import { CoursesStatuses } from 'common/types'

import { UserCourse } from '../types/userCourse'

export function useGetUserCourses() {
    const api = useApi()
    return () =>
        api.get<any, UserCourse[]>(`/courses/groups`).then(response => ({
            past: response.filter(elem => elem.statusName === CoursesStatuses.PAST),
            future: response.filter(elem => elem.statusName === CoursesStatuses.FUTURE),
            active: response.filter(elem => elem.statusName === CoursesStatuses.ACTIVE)
        }))
}
