import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { ChatMember } from '../types/chatMember'

type GetChatDetailsResponse = {
    id: ID
    type: 'individual'
    courseName?: string
    members: ChatMember[]
}

export function useGetChatDetails(chatId: ID) {
    const api = useApi('v2')

    return () => api.get<any, GetChatDetailsResponse>(`/chats/${chatId}`)
}
