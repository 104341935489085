import { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'

import { AppRoutes } from 'common/routing/routes'
import { Button, FormTextField, Error } from 'common/components'

import { LoginFormData, loginFormData } from '../types/loginFormData'

interface LoginFormProps {
    onSubmit: (email: string, password: string) => void
    error: 'wait' | 'other' | false
    isLoading: boolean
}

export function LoginForm({ onSubmit, error, isLoading }: LoginFormProps) {
    const { t } = useTranslation()

    const formMethods = useForm<LoginFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(loginFormData)
    })

    const errorMessage = useMemo(() => {
        if (!error) {
            return ''
        }

        return error === 'other' ? 'login.loginError.normalDescription' : 'login.loginError.waitDescription'
    }, [error])

    const handleSubmit = (values: LoginFormData) => {
        onSubmit(values.email, values.password)
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                    <Label htmlFor="email">{t('login.fields.login.label')}</Label>
                    <StyledInput id="email" name="email" placeholder={t('login.fields.login.placeholder') as string} />
                    <Label htmlFor="password">{t('login.fields.password.label')}</Label>
                    <StyledInput
                        id="password"
                        name="password"
                        type="password"
                        placeholder={t('login.fields.password.placeholder') as string}
                    />

                    <NewPassword to={AppRoutes.ResetPassword.getPath()}>{t('login.passwordResetLabel')}</NewPassword>

                    <StyledButton type="submit" disabled={isLoading}>
                        {t('login.submitButtonLabel')}
                    </StyledButton>
                    <StyledRegisterLink to={AppRoutes.Register.getPath()}>
                        <StyledButton type="submit" disabled={isLoading}>
                            {t('login.registerButtonLabel')}
                        </StyledButton>
                    </StyledRegisterLink>

                    {errorMessage && <StyledError title="login.loginError.title" description={errorMessage} />}
                </Form>
            </FormProvider>
        </>
    )
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    z-index: 1;
`

const StyledButton = styled(Button)`
    width: 100%;

    text-transform: none;

    font-size: 0.875rem;

    margin-top: 1rem;
`

const Label = styled.label`
    font-size: 0.75rem;
    padding-bottom: 0.25rem;

    width: 100%;
`

const StyledInput = styled(FormTextField)`
    background-color: rgba(255, 255, 255, 0.6);

    font-size: 1rem;

    height: 3.375rem;

    &:first-of-type {
        margin-bottom: 0.5rem;
    }
`

const NewPassword = styled(Link)`
    width: 100%;

    padding: 0 0 0 0.875rem;

    font-size: 0.75rem;
    text-decoration: none;
`

const StyledError = styled(Error)`
    margin-top: 1rem;

    background-color: ${props => props.theme.colors.white};
`

const StyledRegisterLink = styled(Link)`
    width: 100%;
`
