import useSWR from 'swr'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { usePageName } from 'common/hooks'
import { Loader, Error, ToggleButton } from 'common/components'

import { GroupChatList, IndividualChatList, useGetChatsList } from 'features/chatsList'

export function Chats() {
    usePageName('pageHeaders.chats')

    const [selectedTab, setSelectedTab] = useState<'individual' | 'groups'>('individual')

    const { t } = useTranslation()

    const { data, error, isLoading } = useSWR('/chats/collapsed', useGetChatsList())

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="chats.fetchError.title" description="chats.fetchError.description" />
    }

    return (
        <>
            <ToggleButton<'individual', 'groups'>
                leftLabel={t('chats.toggle.individual')}
                rightLabel={t('chats.toggle.groups')}
                leftValue="individual"
                rightValue="groups"
                value={selectedTab}
                onChange={setSelectedTab}
            />

            <List>
                {selectedTab === 'individual' ? (
                    <IndividualChatList chats={data?.individualChats || []} />
                ) : (
                    <GroupChatList chats={data?.groupChats || []} />
                )}
            </List>
        </>
    )
}

const List = styled.ul`
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    list-style: none;
    gap: 0.75rem;
`
