import { ID } from 'common/types'

import { SelectOption } from '../types/selectOption'

export function convertToSelectOptions(data: Array<{ id: ID; name: string }>): SelectOption[] {
    return data.map(singleDataElement => ({
        label: singleDataElement.name,
        value: singleDataElement.id
    }))
}
