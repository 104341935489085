import { useTranslation } from 'react-i18next'

import { Button, ControlsWrapper } from 'common/components'
import { useAuth } from 'features/authorizationProvider/context'

type UserControlsProps = {
    edit: boolean
    onCancel: () => void
    onEdit: () => void
    onSubmit: () => void
}

export function UserControls({ edit, onCancel, onEdit, onSubmit }: UserControlsProps) {
    const { t } = useTranslation()
    const { logout } = useAuth()

    return (
        <ControlsWrapper>
            {edit ? (
                <>
                    <Button isSmall type="button" onClick={onSubmit}>
                        {t('profile.controls.save')}
                    </Button>
                    <Button isSmall type="button" onClick={onCancel}>
                        {t('profile.controls.cancel')}
                    </Button>
                </>
            ) : (
                <>
                    <Button isSmall type="button" onClick={onEdit}>
                        {t('profile.controls.edit')}
                    </Button>
                    <Button isSmall type="button" onClick={logout}>
                        {t('profile.controls.logout')}
                    </Button>
                </>
            )}
        </ControlsWrapper>
    )
}
