import { ZodIssueCode, z } from 'zod'

export const membersFormData = z
    .object({
        selectedMembers: z.array(
            z.object({
                label: z.string(),
                value: z.number()
            })
        ),
        newMembersMaximumCount: z.number()
    })
    .superRefine((val, ctx) => {
        if (val.selectedMembers.length > val.newMembersMaximumCount) {
            ctx.addIssue({
                message: 'admin.courseDetails.addNewUsers.tooMuchUsers',
                path: ['selectedMembers'],
                code: ZodIssueCode.custom
            })
        }
    })

export type MembersFormData = z.infer<typeof membersFormData>
