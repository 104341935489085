import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Attachment, ID } from 'common/types'
import { Button } from 'common/components'

import { UploadNewAttachment } from './UploadNewAttachment'
import { SingleAttachmentEdit } from './SingleAttachmentEdit'

type CourseAttachmentsEditProps = {
    attachmentList: Attachment[]
    courseId: ID
    groupId: ID
    closeEdit: () => void
    revalidateKey: string
    isAdmin: boolean
}

export function CourseAttachmentsEdit({
    attachmentList,
    courseId,
    groupId,
    closeEdit,
    revalidateKey,
    isAdmin
}: CourseAttachmentsEditProps) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <List>
                {attachmentList.map(({ id, ...rest }) => (
                    <SingleAttachmentEdit
                        {...rest}
                        key={id}
                        id={id}
                        courseId={courseId}
                        groupId={groupId}
                        revalidateKey={revalidateKey}
                        isAdmin={isAdmin}
                    />
                ))}
            </List>
            <UploadNewAttachment
                courseId={courseId}
                groupId={groupId}
                revalidateKey={revalidateKey}
                isAdmin={isAdmin}
            />
            <Button isSmall onClick={closeEdit}>
                {t('attachmentsManage.editAttachments.closeEditMode')}
            </Button>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const List = styled.ul`
    list-style: none;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    margin: 0.75rem 0;
`
