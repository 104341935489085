import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { Loader, Error, UserProfilePropertiesList } from 'common/components'
import { usePageName } from 'common/hooks'
import { Certificate, Contribution, Degrees } from 'common/types'

import {
    UserCertificateBox,
    UserContributionBox,
    UserDegreeBox,
    UserManageableSection,
    useGetProfileDetails
} from 'features/profile'

export function Profile() {
    usePageName('pageHeaders.profile')

    const { t } = useTranslation()

    const { data: user, error, isLoading } = useSWR('/user-profile', useGetProfileDetails())

    if (error) {
        return <Error title="profile.getProfileError.title" description="profile.getProfileError.description" />
    }

    if (!user || isLoading) {
        return <Loader flexFullPage />
    }

    return (
        <>
            <UserManageableSection profile={user} />

            <UserProfilePropertiesList<Certificate>
                title={t('profile.certs.sectionLabel')}
                elements={user.certificates}
                Component={UserCertificateBox as FC}
                additionalProps={{ displayMenu: true }}
            />

            <UserProfilePropertiesList<Degrees>
                title={t('profile.degrees.sectionLabel')}
                elements={user.degrees}
                Component={UserDegreeBox}
            />

            <UserProfilePropertiesList<Contribution>
                title={t('profile.contributions.sectionLabel')}
                elements={user.contributions}
                Component={UserContributionBox}
            />
        </>
    )
}
