import { useMemo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useAuth } from 'features/authorizationProvider/context'
import { useUser } from 'features/userProvider/contexts'

import { AuthorizedLayout } from 'common/layouts/AuthorizedLayout'
import { UnauthorizedLayout } from 'common/layouts/UnauthorizedLayout'
import { UserRoles } from 'common/types/userRoles'
import { administratorRoutes, instructorRoutes, unauthorizedRoutes, userRoutes } from 'common/routing/routesGroups'

export function Router() {
    const { isLogged } = useAuth()
    const { user } = useUser()

    const routeGroup = useMemo(() => {
        if (isLogged && user) {
            if (user.roleName === UserRoles.INSTRUCTOR) {
                return instructorRoutes
            }

            if (user.roleName === UserRoles.ADMINISTRATOR) {
                return administratorRoutes
            }

            return userRoutes
        }

        return unauthorizedRoutes
    }, [isLogged, user])

    return (
        <Routes>
            <Route element={isLogged ? <AuthorizedLayout /> : <UnauthorizedLayout />}>
                {routeGroup.routes.map(singleRoute => {
                    const Component = singleRoute.routeComponent
                    const path = singleRoute.getPath()

                    return <Route key={path} path={path} element={<Component />} />
                })}
                <Route path="*" element={<Navigate to={routeGroup.redirectRout.getPath()} />} />
            </Route>
        </Routes>
    )
}
