import styled from 'styled-components'

export function GoogleWalletIcon(props: any) {
    return (
        <StyledSvg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                className="a"
                d="M43.5,21.09V13.6252a6.21,6.21,0,0,0-6.1995-6.1995H10.7a6.2507,6.2507,0,0,0-6.1995,6.2v7.718"
            />
            <path className="a" d="M43.5,18.844a6.21,6.21,0,0,0-6.1995-6.1995H10.7A6.21,6.21,0,0,0,4.5,18.844" />
            <path
                className="a"
                d="M42.9532,21.4883a6.2117,6.2117,0,0,0-5.6527-3.656H10.7a6.2115,6.2115,0,0,0-5.645,3.6391"
            />
            <path
                className="a"
                d="M29.298,27.1,4.5,21.3432V34.3748a6.21,6.21,0,0,0,6.2,6.2h26.601a6.21,6.21,0,0,0,6.2-6.1995V21.09l-5.915,4.3017A10.1987,10.1987,0,0,1,29.298,27.1Z"
            />
        </StyledSvg>
    )
}

const StyledSvg = styled.svg`
    .a {
        fill: none;
        stroke: ${props => props.theme.colors.blue};
        stroke-linecap: round;
        stroke-linejoin: round;
        fill-rule: evenodd;
    }
`
