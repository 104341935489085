import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/date'
import { RequiredCourse } from 'common/types'

export function CourseSingleRequiredCourse({ isCompleted, name, obtainedAt }: RequiredCourse) {
    const { t } = useTranslation()

    return (
        <Wrapper isActive={isCompleted}>
            <Details>
                <span>{name}</span> <br />
                {isCompleted ? (
                    <>
                        {formatDate(obtainedAt)} - {t('searchResults.courseElement.requiredCourses.obtainDateLabel')}
                    </>
                ) : (
                    t('searchResults.courseElement.requiredCourses.noObtain')
                )}
            </Details>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ isActive: boolean }>`
    background-color: ${props =>
        props.isActive ? props.theme.colors.blueBackground : props.theme.colors.disabledBackground};

    padding: 0.5rem;

    > p {
        color: ${props => (props.isActive ? props.theme.colors.blue : props.theme.colors.white)};
    }
`

const Details = styled.p`
    margin: 0;

    font-size: 0.75rem;

    > span {
        font-weight: 700;
        font-size: inherit;
        color: inherit;
    }
`
