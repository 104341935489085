import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StatusRedirectLink = styled(Link)`
    position: fixed;
    bottom: 5rem;
    width: calc(100vw - 3rem);

    font-size: 0.75rem;
    font-weight: 700;

    text-transform: uppercase;
    text-decoration: none;

    background-color: ${props => props.theme.colors.blue};

    color: ${props => props.theme.colors.white};

    padding: 0.25rem 0;

    text-align: center;
`
