import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useEffect } from 'react'

import { SelectOption } from 'common/types'
import { Button, FormCalendarField, FormNumberField, FormSelectField } from 'common/components'

import { GroupFormData, groupFormData } from '../types/groupFormData'

type AdminSelectedGroupDetailsFormProps = {
    defaultData: GroupFormData
    voivodeshipOptions: SelectOption[]
    instructorsOptions: SelectOption[]
    closeEdit: () => void
    handleSubmit: (data: GroupFormData) => void
    participantsCount: number
}

export function AdminSelectedGroupDetailsForm({
    defaultData,
    instructorsOptions,
    voivodeshipOptions,
    closeEdit,
    handleSubmit,
    participantsCount
}: AdminSelectedGroupDetailsFormProps) {
    const { t } = useTranslation()

    const formMethods = useForm<GroupFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(groupFormData),
        defaultValues: defaultData
    })

    useEffect(() => {
        formMethods.setValue('participantsCount', participantsCount)
        formMethods.trigger('maximumMembers')
    }, [participantsCount, formMethods])

    return (
        <FormProvider {...formMethods}>
            <StyledForm onSubmit={formMethods.handleSubmit(handleSubmit)}>
                <FormSelectField
                    name="instructorId"
                    options={instructorsOptions}
                    placeholder={t('admin.courseDetails.groupManage.fields.instructor.placeholder')}
                    label="admin.courseDetails.groupManage.fields.instructor.label"
                />

                <FormSelectField
                    name="voivodeshipId"
                    options={voivodeshipOptions}
                    placeholder={t('admin.courseDetails.groupManage.fields.voivoidship.placeholder')}
                    label="admin.courseDetails.groupManage.fields.voivoidship.label"
                />
                <FormNumberField
                    name="maximumMembers"
                    placeholder={t('admin.courseDetails.groupManage.fields.maximumMembers.placeholder') as string}
                    min={1}
                    label="admin.courseDetails.groupManage.fields.maximumMembers.label"
                />

                <FormCalendarField name="dateRange" label="admin.courseDetails.groupManage.fields.dateRange.label" />

                <ButtonWrapper>
                    <Button type="submit" isSmall secondary onClick={closeEdit}>
                        {t('admin.courseDetails.groupManage.cancelButtonLabel')}
                    </Button>
                    <Button type="submit" isSmall>
                        {t('admin.courseDetails.groupManage.saveButtonLabel')}
                    </Button>
                </ButtonWrapper>
            </StyledForm>
        </FormProvider>
    )
}

const ButtonWrapper = styled.div`
    display: flex;

    gap: 0.75rem;

    > button {
        width: 100%;
    }
`

const StyledForm = styled.form`
    margin-bottom: 0.75rem;
`
