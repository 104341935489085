import { useApi } from 'common/hooks'
import { CourseList, ID, VoivodeshipList } from 'common/types'
import { convertToSelectOptions } from 'common/utils/convertToSelectOptions'

export function useGetAdminSearchConfiguration() {
    const api = useApi()

    return () =>
        Promise.all([
            api.get<any, VoivodeshipList[]>('/dictionary/voivodeships'),
            api.get<any, CourseList[]>('/dictionary/courses'),
            api.get<any, { id: ID; name: string }[]>('/dictionary/instructors')
        ]).then(response => {
            const [voivodeships, courses, instructors] = response

            return {
                voivodeships: convertToSelectOptions(voivodeships),
                courses: convertToSelectOptions(courses),
                instructors: convertToSelectOptions(instructors)
            }
        })
}
