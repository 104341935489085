import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type DegreeUpdateParams = {
    arg: {
        name: string
        assignedAt: string
        expiresAt: string | null
    }
}

export function useDegreeUpdate(userId: ID, degreeId: ID) {
    const api = useApi()

    return (_: string, { arg }: DegreeUpdateParams) =>
        api.put<any, any>(`/admin/users/${userId}/degrees/${degreeId}/update`, arg)
}
