import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ChatLinkWrapper = styled(Link)`
    display: flex;
    align-items: center;

    text-decoration: none;

    padding-right: 0.375rem;

    &:hover {
        background-color: ${props => props.theme.colors.blueBackground};
        border-radius: 1.25rem;
    }
`
