import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SearchUser } from 'common/types'
import { UserThumbnail } from 'common/components'
import { UserRoles } from 'common/types/userRoles'
import { AppRoutes } from 'common/routing/routes'

type SearchUserResultsProps = {
    users: SearchUser[]
}

export function SearchUserResults({ users }: SearchUserResultsProps) {
    const { t } = useTranslation()
    return (
        <>
            <Title>{t('admin.searchUser.resultsLabel')}</Title>
            <Wrapper>
                {users.map(singleUser => (
                    <li key={singleUser.id}>
                        <StyledLink to={AppRoutes.AdminUserEditPage.getPath({ userId: singleUser.id })}>
                            <UserThumbnail
                                photoHash={singleUser.photoHash}
                                isInstructor={singleUser.roleName === UserRoles.INSTRUCTOR}
                                isGroup={false}
                            />
                            <UserName>
                                {singleUser.firstName} {singleUser.lastName}
                            </UserName>
                        </StyledLink>
                    </li>
                ))}
            </Wrapper>
        </>
    )
}

const Title = styled.h6`
    font-weight: 700;

    font-size: 0.875rem;

    margin: 0.75rem 0 0 0;
`

const Wrapper = styled.ul`
    margin: 0.75rem 0 0 0;
    padding: 0;

    list-style: none;

    gap: 0.5rem;

    display: flex;
    flex-direction: column;
`

const StyledLink = styled(Link)`
    text-decoration: none;

    display: flex;
    align-items: center;
`

const UserName = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    margin: 0px 0px 0px 0.8125rem;
    width: 100%;
`
