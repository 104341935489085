import { useTranslation } from 'react-i18next'

import { FormTextField } from '../../Form'

type UserEmailAndPasswordFormProps = {
    revalidatePasswordFields: () => void
}

export function UserEmailAndPasswordForm({ revalidatePasswordFields }: UserEmailAndPasswordFormProps) {
    const { t } = useTranslation()

    return (
        <>
            <FormTextField
                name="email"
                label="basicProfileForm.field.email.label"
                placeholder={t('basicProfileForm.field.email.placeholder') as string}
            />
            <FormTextField
                name="password"
                label="basicProfileForm.field.password.label"
                placeholder={t('basicProfileForm.field.password.placeholder') as string}
                type="password"
                onKeyUp={revalidatePasswordFields}
            />
            <FormTextField
                name="passwordRepeat"
                label="basicProfileForm.field.passwordRepeat.label"
                placeholder={t('basicProfileForm.field.passwordRepeat.placeholder') as string}
                type="password"
                onKeyUp={revalidatePasswordFields}
            />
        </>
    )
}
