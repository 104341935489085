import { useState } from 'react'
import useSWR from 'swr'
import { useNavigate, useParams } from 'react-router-dom'

import {
    FailedPayment,
    PaymentStatus as IPaymentStatus,
    SuccessPayment,
    usePaymentStatus,
    PendingPayment
} from 'features/coursePayment'

import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'
import { Loader } from 'common/components'

export function PaymentStatus() {
    usePageName('pageHeaders.paymentStatus')

    const [waitForStatus, setWaitForStatus] = useState(false)

    const navigate = useNavigate()
    const fetcher = usePaymentStatus()

    const { paymentId } = useParams<{ paymentId: string }>()

    const { data, isLoading } = useSWR(paymentId ? `/payments/${paymentId}` : null, () => fetcher(Number(paymentId)), {
        onSuccess: response => {
            setWaitForStatus(response.statusName === IPaymentStatus.PENDING)
        },
        onError: () => {
            navigate(AppRoutes.HomePage.getPath())
        },
        refreshInterval: waitForStatus ? 10000 : 0
    })

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (data?.statusName === IPaymentStatus.FAILURE) {
        return <FailedPayment />
    }

    if (data?.statusName === IPaymentStatus.SUCCESS) {
        return <SuccessPayment courseId={data.courseGroupId} />
    }

    return <PendingPayment />
}
