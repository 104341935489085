import { useApi } from 'common/hooks'
import { ID } from 'common/types'

export function useDeleteCourseGroupFile(courseId: ID, groupID: ID, fileId: ID, isAdmin?: boolean) {
    const api = useApi()

    const url = isAdmin
        ? `/admin/courses/${courseId}/groups/${groupID}/files/${fileId}/delete`
        : `/session/instructor/courses/${courseId}/groups/${groupID}/files/${fileId}/delete`

    return () => api.delete(url)
}
