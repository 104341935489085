import { useMemo } from 'react'
import styled from 'styled-components'
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker'

import { DateRange } from 'common/types'

import { polishLocales } from '../types/locales'

import calendarArrow from '../assets/calendar-arrow.svg'

type CalendarPickerProps = {
    onChange: (dateRange: DateRange) => void
    value: DateRange
}

type CalendarDate = {
    day: number
    month: number
    year: number
}

type NativeCalendarValue = { from: CalendarDate | null; to: CalendarDate | null }

export function CalendarPicker({ onChange, value }: CalendarPickerProps) {
    const handleChange = ({ from, to }: NativeCalendarValue) => {
        onChange({
            from: from ? castSelectedDate(from) : null,
            to: to ? castSelectedDate(to) : null
        })
    }

    const castedValue = useMemo<NativeCalendarValue>(
        () => ({
            from: castDateToCalendarDate(value?.from),
            to: castDateToCalendarDate(value?.to)
        }),
        [value]
    )

    return (
        <Wrapper>
            <Calendar value={castedValue} onChange={handleChange} locale={polishLocales} />
        </Wrapper>
    )
}

const castSelectedDate = ({ day, month, year }: CalendarDate) =>
    new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T00:00:00`)

const castDateToCalendarDate = (date: Date | null): CalendarDate | null => {
    if (!date) {
        return null
    }

    return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;

    .Calendar {
        width: 18.75rem;
        min-height: unset;

        z-index: 1;

        box-shadow: none;

        .Calendar__header {
            padding: 0;

            margin-bottom: 1rem;

            button {
                color: ${props => props.theme.colors.darkBlue};

                font-size: 0.75rem;
                font-weight: 700;
            }

            .Calendar__monthText,
            .Calendar__yearText {
                padding: 0;
            }

            .Calendar__monthArrow {
                background-image: url(${calendarArrow});
            }
        }

        .Calendar__weekDays {
            padding: 0;

            margin-bottom: 1rem;

            abbr {
                color: ${props => props.theme.colors.darkBlue};

                font-size: 0.75rem;
            }
        }

        .Calendar__sectionWrapper {
            min-height: 13.5rem;

            .Calendar__section {
                padding: 0;

                &.-hiddenPrevious {
                    transform: translateX(-100%);
                }

                &.-hiddenNext {
                    transform: translateX(100%);
                }

                .Calendar__day {
                    height: 2.25rem;
                    min-height: unset;

                    padding: 0;
                    margin: 0;

                    box-sizing: border-box;

                    color: ${props => props.theme.colors.darkBlue};

                    font-size: 0.75rem;

                    &.-today {
                        font-weight: 400;

                        color: inherit;

                        &:after {
                            content: none;
                        }
                    }

                    &[aria-selected='true'] {
                        background-color: ${props => props.theme.colors.blueBackground};
                    }

                    &.-selectedStart {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;

                        background-color: ${props => props.theme.colors.darkBlueBackground};
                    }

                    &.-selectedEnd {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-radius: 0;

                        background-color: ${props => props.theme.colors.darkBlueBackground};
                    }
                }
            }
        }
    }
`
