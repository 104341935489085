import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import { ID } from 'common/types'

import { PushMessageForm } from './PushMessageForm'

type PushMessageSectionProps = {
    courseId: ID
    groupId: ID
    isAdmin?: boolean
}

export function PushMessageSection({ courseId, groupId, isAdmin }: PushMessageSectionProps) {
    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleEditOn = () => setEdit(true)
    const handleEditOff = () => setEdit(false)

    return (
        <Wrapper>
            {edit ? (
                <PushMessageForm courseId={courseId} groupId={groupId} isAdmin={isAdmin} closeEdit={handleEditOff} />
            ) : (
                <StyledButton isSmall onClick={handleEditOn}>
                    {t('pushMessage.pushMessageLabel')}
                </StyledButton>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 0.75rem;
`

const StyledButton = styled(Button)`
    width: 100%;
`
