import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/date'
import { Degrees } from 'common/types'

import { UserPropertiesElement } from './UserPropertiesElement'

export function UserDegreeBox({ expiresAt, name, obtainedAt }: Degrees) {
    const { t } = useTranslation()

    return (
        <UserPropertiesElement>
            <p className="title">{name}</p>
            <p>
                {formatDate(obtainedAt)} - {t('profile.degrees.obtained')}
            </p>
            {expiresAt && (
                <p>
                    {formatDate(expiresAt)} - {t('profile.degrees.expiresAt')}
                </p>
            )}
        </UserPropertiesElement>
    )
}
