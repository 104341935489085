export function SendIcon(props: {}) {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                id="Vector 4"
                d="M9.53674e-07 0.499999L13 7.24074L3.85426e-07 13.5L9.53674e-07 0.499999Z"
                fill="#233C98"
            />
        </svg>
    )
}
