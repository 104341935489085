import { useApi } from 'common/hooks'
import { VoivodeshipList } from 'common/types'
import { convertToSelectOptions } from 'common/utils/convertToSelectOptions'

export function useGetRegions() {
    const api = useApi()

    return () =>
        api.get<any, VoivodeshipList[]>('/dictionary/voivodeships').then(response => convertToSelectOptions(response))
}
