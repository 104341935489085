import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'

import { Attachment, ID } from 'common/types'
import { Button, ConfirmModal, ErrorModal } from 'common/components'
import { useDeleteCourseGroupFile } from 'common/api'

interface SingleAttachmentEditPRops extends Attachment {
    courseId: ID
    groupId: ID
    revalidateKey: string
    isAdmin: boolean
}

export function SingleAttachmentEdit({
    id,
    name,
    courseId,
    groupId,
    revalidateKey,
    isAdmin
}: SingleAttachmentEditPRops) {
    const { t } = useTranslation()

    const deleteMutation = useDeleteCourseGroupFile(courseId, groupId, id, isAdmin)

    const [showModal, setShowModal] = useState(false)
    const [deleteError, setDeleteError] = useState('')

    const handleShowModal = () => setShowModal(true)
    const handleCloseModal = () => setShowModal(false)
    const handleErrorModalClose = () => setDeleteError('')

    const { trigger } = useSWRMutation(revalidateKey, deleteMutation, {
        onError: error => {
            const statusCode: number = error.response.status

            let errorMessage = t('attachmentsManage.editAttachments.deleteErrorModal.otherError')

            if (statusCode === 403) {
                errorMessage = t('attachmentsManage.editAttachments.deleteErrorModal.noLead')
            } else if (statusCode === 404) {
                errorMessage = t('attachmentsManage.editAttachments.deleteErrorModal.notFound')
            }

            setDeleteError(errorMessage)
        },
        throwOnError: false
    })

    const handleDelete = () => {
        handleCloseModal()
        trigger()
    }

    return (
        <>
            <Wrapper>
                <Title>{name}</Title>
                <Button isSmall onClick={handleShowModal}>
                    {t('attachmentsManage.editAttachments.deleteButton')}
                </Button>
            </Wrapper>
            <ConfirmModal
                isOpen={showModal}
                onClose={handleCloseModal}
                onConfirm={handleDelete}
                cancelLabel={t('attachmentsManage.editAttachments.deleteModal.cancelLabel')}
                confirmLabel={t('attachmentsManage.editAttachments.deleteModal.confirmLabel')}
                description={t('attachmentsManage.editAttachments.deleteModal.description', { fileName: name })}
                title={t('attachmentsManage.editAttachments.deleteModal.title')}
            />
            <ErrorModal
                confirmLabel={t('attachmentsManage.editAttachments.deleteErrorModal.confirmLabel')}
                title={t('attachmentsManage.editAttachments.deleteErrorModal.title')}
                description={deleteError}
                isOpen={!!deleteError}
                onClose={handleErrorModalClose}
                onConfirm={handleErrorModalClose}
            />
        </>
    )
}

const Wrapper = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Title = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
`
