import { PropsWithChildren, useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'

import { Loader } from 'common/components'

import { logoutRequest } from '../api/logoutRequest'

import { AuthorizationContext, AuthorizationContextData } from './authorizationContext'

// In feature change for cookie and name
const LOCAL_STORAGE_TOKEN_KEY = 'authToken'

interface AuthorizationState extends Pick<AuthorizationContextData, 'isLogged' | 'token'> {
    isBootstrapped: boolean
}

export function AuthorizationContextProvider({ children }: PropsWithChildren<{}>) {
    const [authState, setAuthState] = useState<AuthorizationState>({
        token: null,
        isLogged: false,
        isBootstrapped: false
    })
    const { cache } = useSWRConfig()

    const removeAuthorizationToken = () => localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)

    const logout = async () => {
        try {
            if (authState.token) {
                await logoutRequest(authState.token)

                const cacheKeysArray = Array.from(cache.keys())
                cacheKeysArray.forEach(key => cache.delete(key))
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e)
        }

        setAuthState({
            isLogged: false,
            token: null,
            isBootstrapped: true
        })
        removeAuthorizationToken()

        return true
    }

    const checkForToken = () => {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

        if (!token) {
            logout()
        } else {
            login(token)
        }
    }

    const login = async (token: string) => {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)
        setAuthState({
            isLogged: true,
            isBootstrapped: true,
            token
        })
    }

    useEffect(() => {
        checkForToken()
    }, [])

    const contextValue: AuthorizationContextData = {
        ...authState,
        logout,
        login
    }

    return (
        <AuthorizationContext.Provider value={contextValue}>
            {!authState.isBootstrapped ? <Loader flexFullPage /> : children}
        </AuthorizationContext.Provider>
    )
}
