import useSWR from 'swr'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ID } from 'common/types'
import { CheckboxInput, CourseDescriptionAndPhoto, CourseRequiredCoursesList, Error, Loader } from 'common/components'
import { useGetSearchCourseDetails } from 'common/api'

import { PaymentBar } from 'features/coursePayment'

type SearchCourseDetailsProps = {
    id: ID
}

export function SearchCourseDetails({ id }: SearchCourseDetailsProps) {
    const fetcher = useGetSearchCourseDetails(id)

    const { t } = useTranslation()

    const [statementValue, setStatementValue] = useState(false)

    const { data, error, isLoading } = useSWR(`/search/course/groups/${id}`, fetcher)

    const hasUserAllCertificates = useMemo(() => {
        const courses = data?.requiredCourses

        if (!courses?.length) {
            return true
        }

        return courses.every(singleCourse => singleCourse.isCompleted)
    }, [data])

    const canUserBuyCourse = statementValue || hasUserAllCertificates

    const handleCheckboxClick = () => {
        setStatementValue(prev => !prev)
    }

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return (
            <StyledError
                title="searchResults.getCourseDetailsError.title"
                description="searchResults.getCourseDetailsError.description"
            />
        )
    }

    return (
        <>
            <CourseDescriptionAndPhoto description={data?.description || ''} photoHash={data?.photoHash} />
            <CourseRequiredCoursesList coursesList={data?.requiredCourses || []} />
            {!hasUserAllCertificates && (
                <>
                    <Description>
                        {t('searchResults.courseElement.statementSection.firstRow')}
                        <br />
                        <br />
                        {t('searchResults.courseElement.statementSection.secondRow')}
                    </Description>
                    <AgreeContainer>
                        <CheckboxInput id="agree" checked={statementValue} onChange={handleCheckboxClick} />
                        <Label htmlFor="agree">{t('searchResults.courseElement.statementSection.checkboxLabel')}</Label>
                    </AgreeContainer>
                </>
            )}
            <PaymentBar courseId={id} disabled={!canUserBuyCourse} withUserStatement={statementValue} />
        </>
    )
}

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`

const Description = styled.p`
    margin: 0.75rem 0 0 0;
    font-size: 0.875rem;
`

const AgreeContainer = styled.div`
    margin-top: 0.75rem;
    display: flex;

    > input {
        margin-right: 0.5rem;

        flex-shrink: 0;

        position: relative;
        top: 1px;
    }
`

const Label = styled.label`
    font-size: 0.875rem;
`
