import styled from 'styled-components'

import { Loader } from 'common/components'
import { useGetFileOnce } from 'common/hooks'

type CourseImageProps = {
    fileHash: string
    authorizedImage: boolean
}

export function CourseImage({ fileHash, authorizedImage }: CourseImageProps) {
    const { error, fileUrl, isLoading } = useGetFileOnce({ fileHash, isAuthorized: authorizedImage })

    if (isLoading) {
        return <Loader isSmall />
    }

    if (error || !fileUrl) {
        return null
    }

    return <StyledImg src={fileUrl} alt="course-img" />
}

const StyledImg = styled.img`
    width: 100%;

    margin-top: 0.75rem;
`
