import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { MenuElement as IMenuElement } from '../types/menuElement'

export function MenuElement({ image, url, label, rounded, ariaLabel }: IMenuElement) {
    return (
        <li>
            <StyledLink to={url} className={({ isActive }) => (isActive ? 'active' : '')} aria-label={ariaLabel}>
                <Image src={image} alt={`${label}-ikona`} rounded={rounded} />
            </StyledLink>
        </li>
    )
}

const StyledLink = styled(NavLink)`
    &.active {
        opacity: 0.2;
    }

    &:hover,
    &:active {
        opacity: 0.2;
    }
`

const Image = styled.img<Pick<IMenuElement, 'rounded'>>`
    height: 1.625rem;
    width: 1.625rem;

    ${props =>
        props.rounded &&
        `
        border-radius: 50%;
    `}
`
