import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type UpdateUserParams = {
    arg: {
        firstName: string
        lastName: string
        roleId: number
        email: string
        street?: string
        postalCode?: string
        city?: string
        note: string | null
    }
}

export function useUpdateUser(userId: ID) {
    const api = useApi()

    return (_: string, { arg }: UpdateUserParams) => api.put<any, any>(`/admin/users/${userId}/update`, arg)
}
