import { useApi } from 'common/hooks'
import { CourseCompletionStatus, ID } from 'common/types'

type UseSendMessageParams = {
    arg: CourseCompletionStatus
}

export function useSetMemberCourseStatus(courseId: ID, groupID: ID, memberId: ID, isAdmin?: boolean) {
    const api = useApi()

    return (_: string, { arg }: UseSendMessageParams) => {
        const url = isAdmin
            ? `/admin/courses/${courseId}/groups/${groupID}/members/${memberId}/mark-as-${arg}`
            : `/session/instructor/courses/${courseId}/groups/${groupID}/members/${memberId}/mark-as-${arg}`

        return api.put(url)
    }
}
