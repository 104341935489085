import { useState } from 'react'
import useSWR from 'swr'

import { ID } from 'common/types'
import { Loader, Error } from 'common/components'

import { useGetUserCourses } from '../api/useGetUserCourses'

import { CoursesListByStatus } from './CoursesListByStatus'

export function UserCoursesList() {
    const [activeCourse, setActiveCourse] = useState<ID>(-1)
    const fetcher = useGetUserCourses()

    const { data, error, isLoading } = useSWR('/courses/groups', fetcher)

    if (error) {
        return <Error title="userCourses.getError.title" description="userCourses.getError.description" />
    }

    if (isLoading) {
        return <Loader flexFullPage />
    }

    return (
        <>
            <CoursesListByStatus
                title="userCourses.activeGroupLabel"
                noDataLabel="userCourses.noData.active"
                coursesList={data?.active || []}
                activeCourse={activeCourse}
                setActiveCourse={setActiveCourse}
            />
            <CoursesListByStatus
                title="userCourses.futureGroupLabel"
                noDataLabel="userCourses.noData.future"
                coursesList={data?.future || []}
                activeCourse={activeCourse}
                setActiveCourse={setActiveCourse}
            />
            <CoursesListByStatus
                title="userCourses.completedGroupLabel"
                noDataLabel="userCourses.noData.completed"
                coursesList={data?.past || []}
                activeCourse={activeCourse}
                setActiveCourse={setActiveCourse}
            />
        </>
    )
}
