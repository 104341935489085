import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, FormCalendarField, FormSelectField } from 'common/components'
import { SelectOption } from 'common/types'

import { adminSearchCourseFormSchema, AdminSearchCourseFormData } from '../types/adminSearchCourseFormData'

interface CoursesSearchFormProps {
    defaultData: AdminSearchCourseFormData
    voivodeships: SelectOption[]
    courses: SelectOption[]
    instructors: SelectOption[]
    onSubmit: (data: AdminSearchCourseFormData) => void
}

export function AdminCoursesSearchForm({
    courses,
    voivodeships,
    onSubmit,
    instructors,
    defaultData
}: CoursesSearchFormProps) {
    const { t } = useTranslation()
    const formMethods = useForm<AdminSearchCourseFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(adminSearchCourseFormSchema),
        defaultValues: defaultData
    })

    return (
        <FormProvider {...formMethods}>
            <StyledForm onSubmit={formMethods.handleSubmit(onSubmit)}>
                <div>
                    <FormSelectField
                        name="region"
                        options={voivodeships}
                        placeholder={t('admin.search.fields.region.placeholder')}
                    />
                    <FormSelectField
                        name="course"
                        options={courses}
                        placeholder={t('admin.search.fields.course.placeholder')}
                    />
                    <FormSelectField
                        name="instructor"
                        options={instructors}
                        placeholder={t('admin.search.fields.instructor.placeholder')}
                    />
                    <CalendarInputLabel>{t('admin.search.fields.dateRange.label')}</CalendarInputLabel>
                    <FormCalendarField name="dateRange" />
                </div>

                <StyledSubmitButton type="submit">{t('admin.search.searchLabel')}</StyledSubmitButton>
            </StyledForm>
        </FormProvider>
    )
}

const StyledSubmitButton = styled(Button)`
    width: 100%;
`

const StyledForm = styled.form`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CalendarInputLabel = styled.div`
    font-size: 0.75rem;

    color: ${props => props.theme.colors.blue};

    padding-left: 0.625rem;
`
