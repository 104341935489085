import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type ContributeUpdateProps = {
    arg: {
        name: string
        dueDate: string
        paidAt: string | null
    }
}

export function useContributeUpdate(userId: ID, contributionId: ID) {
    const api = useApi()

    return (_: string, { arg }: ContributeUpdateProps) =>
        api.put<any, any>(`/admin/users/${userId}/contributions/${contributionId}/update`, arg)
}
