import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormEvent } from 'react'

import { Button, FormCalendarField, FormNumberField, FormSelectField } from 'common/components'
import { SelectOption } from 'common/types'

type AddGroupFormProps = {
    voivodeships: SelectOption[]
    courses: SelectOption[]
    instructors: SelectOption[]
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void
    useInstructorField: boolean
    isMutating: boolean
}

export function AddGroupForm({
    courses,
    handleSubmit,
    instructors,
    voivodeships,
    useInstructorField,
    isMutating
}: AddGroupFormProps) {
    const { t } = useTranslation()

    return (
        <form onSubmit={handleSubmit}>
            <FormSelectField
                name="courseId"
                options={courses}
                label="addGroup.fields.course.label"
                placeholder={t('addGroup.fields.course.placeholder')}
            />
            {useInstructorField && (
                <FormSelectField
                    name="instructorId"
                    options={instructors}
                    label="addGroup.fields.instructor.label"
                    placeholder={t('addGroup.fields.instructor.placeholder')}
                />
            )}
            <FormSelectField
                name="voivodeshipId"
                options={voivodeships}
                label="addGroup.fields.voivodeship.label"
                placeholder={t('addGroup.fields.voivodeship.placeholder')}
            />
            <FormNumberField
                name="maximumMembers"
                label="addGroup.fields.maximumMembers.label"
                placeholder={t('addGroup.fields.maximumMembers.placeholder') as string}
            />
            <CalendarInputLabel>{t('addGroup.fields.dateRange.label')}</CalendarInputLabel>
            <FormCalendarField name="dateRange" />

            <SubmitButton disabled={isMutating}>{t('addGroup.submitLabel')}</SubmitButton>
        </form>
    )
}

const CalendarInputLabel = styled.div`
    font-size: 0.75rem;

    color: ${props => props.theme.colors.blue};
`

const SubmitButton = styled(Button)`
    width: 100%;
`
