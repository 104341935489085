import { ID } from 'common/types'

import { LoginPage } from 'pages/LoginPage'
import { ResetPassword } from 'pages/ResetPassword'
import { Register } from 'pages/Register'

import { Home } from 'pages/Home'
import { SearchResults } from 'pages/SearchResults'
import { Search } from 'pages/Search'
import { UserCourses } from 'pages/UserCourses'
import { Chats } from 'pages/Chats'
import { Profile } from 'pages/Profile'
import { PaymentStatus } from 'pages/PaymentStatus'
import { CourseDetails } from 'pages/CourseDetails'
import { ChatPage } from 'pages/Chat'

import { InstructorCoursesPage } from 'pages/instructor/InstructorCoursesPage'
import { InstructorCourseDetails } from 'pages/instructor/InstructorCourseDetails'
import { InstructorAddCourseGroup } from 'pages/instructor/InstructorAddCourseGroup'

import { AdminSearch } from 'pages/administrator/AdminSearch'
import { AdminSearchResult } from 'pages/administrator/AdminSearchResult'
import { AdminCourseDetails } from 'pages/administrator/AdminCourseDetails'
import { AdminUserEdit } from 'pages/administrator/AdminUserEdit'
import { AdminAddCourseGroup } from 'pages/administrator/AdminAddCourseGroup'
import { AdminHome } from 'pages/administrator/AdminHome'
import { AdminUserSearch } from 'pages/administrator/AdminUserSearch'
import { AdminAllCourses } from 'pages/administrator/AdminAllCourses'
import { AdminCourses } from 'pages/administrator/AdminCourses'
import { AdminAddUser } from 'pages/administrator/AdminAddUser'
import { AdminExternalIdentifiers } from 'pages/administrator/AdminExternalIdentifiers'

import { GuestHome } from 'pages/guest/GuestHome'
import { GuestSearch } from 'pages/guest/GuestSearch'
import { GuestSearchResults } from 'pages/guest/GuestSearchResults'
import { GuestAllCourses } from 'pages/guest/GuestAllCourses'

import { AppRoute } from './appRoute'

export const AppRoutes = {
    /** Unautorized routes */
    LoginPage: new AppRoute('/login', LoginPage),
    ResetPassword: new AppRoute('/reset-password', ResetPassword),
    Register: new AppRoute('/register', Register),

    /** User routes */
    HomePage: new AppRoute('/', Home),
    SearchPage: new AppRoute('/search', Search),
    SearchResultsPage: new AppRoute('/search/results', SearchResults),
    UserCourses: new AppRoute('/courses', UserCourses),
    ChatsPage: new AppRoute('/chats', Chats),
    ChatPage: new AppRoute<{ chatId: ID }>('/chat/:chatId', ChatPage),
    ProfilePage: new AppRoute('/profile', Profile),
    PaymentStatus: new AppRoute<{ paymentId: ID }>('/payment/status/:paymentId', PaymentStatus),
    CourseDetails: new AppRoute<{ courseId: ID }>('/course/:courseId', CourseDetails),

    /**
     * Instructor only routes
     */
    InstructorCoursesPage: new AppRoute('/instructor/courses', InstructorCoursesPage),
    InstructorCourseDetails: new AppRoute<{ courseId: ID; groupId: ID | undefined }>(
        '/instructor/course/:courseId/:groupId?',
        InstructorCourseDetails
    ),
    InstructorAddUserGroup: new AppRoute('/instructor/add-course-group', InstructorAddCourseGroup),
    /**
     * Admin only routes
     */
    AdminSearchPage: new AppRoute('/administrator/search', AdminSearch),
    AdminSearchResultPage: new AppRoute('/administrator/search/results', AdminSearchResult),
    AdminCourseDetailsPage: new AppRoute<{ courseId: ID; groupId: ID | undefined }>(
        '/administrator/course/:courseId/:groupId?',
        AdminCourseDetails
    ),
    AdminUserEditPage: new AppRoute<{ userId: ID }>('/administrator/user/:userId', AdminUserEdit),
    AdminAddUserGroup: new AppRoute('/administrator/add-course-group', AdminAddCourseGroup),
    AdminHome: new AppRoute('/', AdminHome),
    AdminSearchUser: new AppRoute('/administrator/user/search', AdminUserSearch),
    AdminAllCourses: new AppRoute('/administrator/all-courses', AdminAllCourses),
    AdminCourses: new AppRoute('/administrator/courses', AdminCourses),
    AdminAddUser: new AppRoute('/administrator/add-user', AdminAddUser),
    AdminExternalIdentifiers: new AppRoute('/administrator/external-identifiers', AdminExternalIdentifiers),

    /**
     * Guest only routes
     */
    GuestHome: new AppRoute('/home', GuestHome),
    GuestSearch: new AppRoute('/guest/search', GuestSearch),
    GuestSearchResults: new AppRoute('/guest/search/results', GuestSearchResults),
    GuestAllCourses: new AppRoute('/guest/courses', GuestAllCourses)
}
