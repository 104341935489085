import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type GetAllCoursesResponse = {
    id: ID
    name: string
}

export function useGetAllCourses() {
    const api = useApi()

    return () => api.get<any, GetAllCoursesResponse[]>(`/dictionary/courses`)
}
