import React from 'react'
import { SWRConfig, SWRConfiguration } from 'swr'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { UserContextProvider } from 'features/userProvider/contexts'
import { AuthorizationContextProvider } from 'features/authorizationProvider/context'
import { Router } from 'features/router'
import { ThemeProvider } from 'common/theme/ThemeProvider'

if (process.env.REACT_APP_SENTRY) {
    Sentry.init({
        dsn: 'https://114d7a661ff4e1eae59b02807b0ccf56@o1134180.ingest.sentry.io/4505456073768960',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new Sentry.Replay()
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    })
}

function App() {
    const swrConfig: SWRConfiguration = {
        revalidateOnMount: true,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        errorRetryCount: 0
    }

    return (
        <SWRConfig value={swrConfig}>
            <ThemeProvider>
                <AuthorizationContextProvider>
                    <UserContextProvider>
                        <Router />
                    </UserContextProvider>
                </AuthorizationContextProvider>
            </ThemeProvider>
        </SWRConfig>
    )
}

export default App
