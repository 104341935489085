import useSWR from 'swr'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CourseMember, ID } from 'common/types'
import {
    Loader,
    Error,
    CourseBox,
    ToggleButton,
    CourseDescriptionAndPhoto,
    CourseAttachmentsWithEdit,
    CourseMemberListElementWithControls,
    CourseAttachments
} from 'common/components'
import { cacheKeys } from 'common/constants'

import { CourseMembersList, PushMessageSection } from 'features/courseMembersList'

import { useGetGroupDetails } from '../api/useGetGroupDetails'

type SelectedGroupCourseDetailsProps = {
    selectedGroup: ID
    courseId: ID
}

export function SelectedGroupCourseDetails({ selectedGroup, courseId }: SelectedGroupCourseDetailsProps) {
    const { t } = useTranslation()
    const [displayedSection, setDisplayedSection] = useState<'details' | 'members'>('details')

    const fetcher = useGetGroupDetails(courseId)

    const cacheKey = cacheKeys.instructorCourseGroupDetails(courseId, selectedGroup)

    const { data: courseGroupDetails, error, isLoading } = useSWR(cacheKey, () => fetcher(selectedGroup))

    if (isLoading && !courseGroupDetails) {
        return <Loader />
    }

    if (error) {
        return (
            <StyledError
                title="instructorCourseDetails.getCourseGroupDetailsError.title"
                description="instructorCourseDetails.getCourseGroupDetailsError.description"
            />
        )
    }

    if (courseGroupDetails) {
        return (
            <StyledCourseBox
                disablePadding
                courseName={courseGroupDetails.courseName}
                isActive
                id={courseGroupDetails.id}
                activeDetails={
                    <>
                        <ToggleButton<'details', 'members'>
                            leftLabel={t('courseBox.toggleButton.detailsLabel')}
                            rightLabel={t('courseBox.toggleButton.membersLabel')}
                            leftValue="details"
                            rightValue="members"
                            value={displayedSection}
                            onChange={setDisplayedSection}
                        />
                        {displayedSection === 'details' ? (
                            <>
                                <CourseDescriptionAndPhoto
                                    description={courseGroupDetails.content}
                                    photoHash={courseGroupDetails.photoHash}
                                />

                                <CourseAttachments
                                    attachmentList={courseGroupDetails?.educationalAttachments || []}
                                    label="attachmentsManage.educationalAttachments"
                                />
                                <CourseAttachmentsWithEdit
                                    attachmentList={courseGroupDetails?.attachments || []}
                                    courseId={courseId}
                                    groupId={selectedGroup}
                                    revalidateKey={cacheKey}
                                />
                            </>
                        ) : (
                            <>
                                <PushMessageSection courseId={courseId} groupId={selectedGroup} />
                                <CourseMembersList
                                    additionalPropsForComponent={{
                                        selectedGroup,
                                        courseId,
                                        canManageMemberStatuses: courseGroupDetails.canManageMemberStatuses,
                                        cacheKeyToRevalidate: cacheKey
                                    }}
                                    membersList={courseGroupDetails?.members || []}
                                    component={CourseMemberListElementWithControls as FC<CourseMember>}
                                    groupChatId={courseGroupDetails.chatId}
                                />
                            </>
                        )}
                    </>
                }
            />
        )
    }

    return null
}

const StyledCourseBox = styled(CourseBox)`
    margin-top: 0.75rem;
`

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`
