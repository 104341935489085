import { useApi } from 'common/hooks'
import { ID, RequiredCourse } from 'common/types'

interface UseGetSearchCourseDetailsResponse {
    courseName: string
    instructorFullName: string
    startsAt: string
    voivodeshipName: string
    description: string
    photoHash: string
    requiredCourses: RequiredCourse[]
    courseId: ID
}

export function useGetSearchCourseDetails(id: ID) {
    const api = useApi()
    return () => api.get<any, UseGetSearchCourseDetailsResponse>(`/search/course/groups/${id}`)
}
