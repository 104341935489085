import { useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styled from 'styled-components'

import { CourseMembersList, CourseMembersListElement } from 'features/courseMembersList'

import { useGetCourseDetails } from 'common/api'
import { AppRoutes } from 'common/routing/routes'
import { CourseCompletionStatus } from 'common/types'
import { usePageName } from 'common/hooks'
import {
    CourseBox,
    CourseCertificate,
    CourseDatesAndVoivodeship,
    CourseDescriptionAndPhoto,
    Loader,
    ToggleButton,
    Error,
    CourseAttachments
} from 'common/components'

export function CourseDetails() {
    usePageName('pageHeaders.courseDetails')

    const { courseId } = useParams<{ courseId: string }>()
    const [displayedSection, setDisplayedSection] = useState<'details' | 'members'>('details')
    const navigate = useNavigate()
    const { t } = useTranslation()

    const fetcher = useGetCourseDetails(Number(courseId))

    const { data, error, isLoading } = useSWR(`/courses/groups/${courseId}`, fetcher, {
        onError: ({ response }: AxiosError) => {
            if ([403, 404].includes(response?.status || 0)) {
                navigate(AppRoutes.UserCourses.getPath())
            }
        }
    })

    if (error) {
        return (
            <Error
                title="courseDetails.getCourseDetails.errorTitle"
                description="courseDetails.getCourseDetails.detailsError"
            />
        )
    }

    if (isLoading || !data) {
        return <Loader flexFullPage />
    }

    return (
        <CourseBox
            disablePadding
            courseName={data.courseName}
            instructorFullName={data.instructorName}
            isActive
            id={data.id}
            details={
                <CourseDatesAndVoivodeship
                    startsAt={data.startsAt}
                    endsAt={data.endsAt}
                    statusName={data.statusName}
                    voivodeshipName={data.voivodeshipName}
                    showCourseDateRange
                />
            }
            activeDetails={
                <>
                    <StyledToggleButton<'details', 'members'>
                        leftLabel={t('courseBox.toggleButton.detailsLabel')}
                        rightLabel={t('courseBox.toggleButton.membersLabel')}
                        leftValue="details"
                        rightValue="members"
                        value={displayedSection}
                        onChange={setDisplayedSection}
                    />
                    {displayedSection === 'details' ? (
                        <>
                            <CourseDescriptionAndPhoto description={data.content} photoHash={data.photoHash} />
                            <CourseAttachments
                                attachmentList={data?.educationalAttachments || []}
                                label="attachmentsManage.educationalAttachments"
                            />
                            <CourseAttachments
                                attachmentList={data?.attachments || []}
                                label="attachmentsManage.supplementaryMaterials"
                            />
                            <CourseCertificate
                                canGenerate={data?.completionStatusName === CourseCompletionStatus.SUCCESS}
                                courseId={data?.courseId || 0}
                            />
                        </>
                    ) : (
                        <CourseMembersList
                            membersList={data?.members || []}
                            component={CourseMembersListElement}
                            groupChatId={data?.chatId}
                        />
                    )}
                </>
            }
        />
    )
}

const StyledToggleButton = styled(ToggleButton)`
    margin-top: 0.75rem;
` as typeof ToggleButton
