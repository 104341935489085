import { User } from 'common/types'

import { createContext, useContext } from 'react'

export interface UserContextData {
    user: User | null
    updateUserState: (user: User) => void
    setProfilePhotoHash: (photoHash: string) => void
}

export const UserContext = createContext<UserContextData | undefined>(undefined)

export const useUser = () => {
    const context = useContext(UserContext)

    if (context === undefined) {
        throw new Error('useUser used outside UserProvider')
    }

    return context
}
