import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'

import { Loader, Error, SearchResultBackToSearch, SearchCoursesList } from 'common/components'
import { AppRoutes } from 'common/routing/routes'
import { SearchCourse } from 'common/types'

import {
    getGuestCoursesQuerySchema,
    GetGuestCoursesQuerySchema,
    useGetGuestSearchResults,
    GuestSearchCourseListElement
} from 'features/guestFeatures/guestSearchResults'

export function GuestSearchResults() {
    const { t } = useTranslation()

    const [qsData, setQsData] = useState<GetGuestCoursesQuerySchema | null>(null)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const fetchCourses = useGetGuestSearchResults()

    const routeState = useMemo(
        () => ({
            region: qsData?.region ? Number(qsData.region) : undefined,
            dateRange: {
                from: qsData?.startsAtFrom ? new Date(qsData.startsAtFrom) : undefined,
                to: qsData?.startsAtTo ? new Date(qsData.startsAtTo) : undefined
            }
        }),
        [qsData]
    )

    const { data, error, isLoading } = useSWR(qsData ? `/search/course/groups?${JSON.stringify(qsData)}` : null, () =>
        fetchCourses({
            voivodeshipId: qsData?.region,
            startsAtFrom: qsData?.startsAtFrom || '',
            startsAtTo: qsData?.startsAtTo || ''
        })
    )

    useEffect(() => {
        const parseResult = getGuestCoursesQuerySchema.safeParse(Object.fromEntries(searchParams.entries()))

        if (parseResult.success) {
            setQsData(parseResult.data)
        } else {
            navigate(AppRoutes.GuestSearch.getPath())
        }
    }, [searchParams, navigate])

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="guest.searchResult.getError.title" description="guest.searchResult.getError.description" />
    }

    return (
        <>
            <SearchCoursesList<SearchCourse>
                Component={GuestSearchCourseListElement}
                coursesList={data || []}
                noDataTitle="guest.searchResult.noData.title"
                noDataDescription="guest.searchResult.noData.description"
            />

            <SearchResultBackToSearch
                url={AppRoutes.GuestSearch.getPath()}
                buttonLabel={t('guest.searchResult.backToSearch')}
                routeState={routeState}
            />
        </>
    )
}
