import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { Props as ReactSelectProps } from 'react-select'

import { Select } from '../Select'
import { FormFieldWrapper } from './FormFieldWrapper'
import { SelectOption } from '../../types/selectOption'

interface FormSelectFieldProps extends ReactSelectProps<SelectOption> {
    name: string
    options: SelectOption[]
    label?: string
}

export function FormSelectField({ name, defaultValue, options, label, ...rest }: FormSelectFieldProps) {
    const {
        field: { onChange, value, ...restField },
        fieldState: { error }
    } = useController({
        name,
        defaultValue
    })

    const handleChange = (option: SelectOption | null) => {
        onChange(option?.value || null)
    }

    const selectedValue = useMemo(
        () => options.find(singleOption => singleOption.value === value) || null,
        [value, options]
    )

    return (
        <FormFieldWrapper error={error?.message} label={label} name={name}>
            <Select
                {...rest}
                {...restField}
                // @ts-ignore
                onChange={handleChange}
                options={options}
                value={selectedValue}
                isMulti={false}
                id={name}
            />
        </FormFieldWrapper>
    )
}
