import styled from 'styled-components'

import { CourseBox, CourseDatesAndVoivodeship } from 'common/components'

import { CourseGroupAsMember } from '../types/courseGroupAsMember'

export function UserCoursesElement({
    courseName,
    endsAt,
    id,
    startsAt,
    statusName,
    voivodeshipName
}: CourseGroupAsMember) {
    return (
        <StyledCourseBox
            key={id}
            courseName={courseName}
            id={id}
            details={
                <CourseDatesAndVoivodeship
                    startsAt={startsAt}
                    endsAt={endsAt}
                    statusName={statusName}
                    voivodeshipName={voivodeshipName}
                    showCourseDateRange
                    showStatusTag
                />
            }
            activeDetails={null}
        />
    )
}

const StyledCourseBox = styled(CourseBox)`
    padding: 0.5rem;

    * {
        font-size: 0.75rem;
    }

    div {
        margin: 0;
    }
`
