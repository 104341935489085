import { useTheme } from 'styled-components'

export function CrossIcon(props: {}) {
    const { colors } = useTheme()

    return (
        <svg width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g id="Complete">
                <g id="x-circle">
                    <g>
                        <circle
                            cx="12"
                            cy="12"
                            data-name="--Circle"
                            fill="none"
                            id="_--Circle"
                            r="10"
                            stroke={colors.errorBorder}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                        />

                        <line
                            fill="none"
                            stroke={colors.errorBorder}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            x1="14.5"
                            x2="9.5"
                            y1="9.5"
                            y2="14.5"
                        />

                        <line
                            fill="none"
                            stroke={colors.errorBorder}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            x1="14.5"
                            x2="9.5"
                            y1="14.5"
                            y2="9.5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
