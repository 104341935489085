import { PropsWithChildren, useEffect, useState } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

import { User } from 'common/types'
import { Loader } from 'common/components'
import { useGetAsset } from 'common/api'
import { cacheKeys } from 'common/constants'

import { useAuth } from 'features/authorizationProvider/context'

import { useGetUser } from '../api/useGetUser'

import { UserContext, UserContextData } from './userContext'

interface UserState extends Pick<UserContextData, 'user'> {
    isBootstrapped: boolean
}

export function UserContextProvider({ children }: PropsWithChildren<{}>) {
    const [photoHashToFetch, setPhotoHashToFetch] = useState<string | null>(null)

    const { isLogged } = useAuth()
    const fetchUserImage = useGetAsset(photoHashToFetch ? cacheKeys.getFileAuthorized(photoHashToFetch) : '')

    const [userState, setUserState] = useState<UserState>({
        user: null,
        isBootstrapped: false
    })

    const fetchUser = useGetUser()

    const { trigger, isMutating: isFetchingUserSession } = useSWRMutation('/session', fetchUser, {
        onSuccess: response => {
            const { photoHash, ...rest } = response
            setUserState(prev => ({
                ...prev,
                user: {
                    ...rest,
                    photo: ''
                }
            }))

            if (photoHash) {
                setPhotoHashToFetch(photoHash)
            } else {
                setUserState(prev => ({
                    ...prev,
                    isBootstrapped: true
                }))
            }
        },
        throwOnError: false
    })

    const { isLoading: isLoadingPhoto } = useSWR(
        photoHashToFetch ? `/download/files/${photoHashToFetch}` : undefined,
        fetchUserImage,
        {
            onSuccess: response => {
                setPhotoHashToFetch(null)

                if (userState.user) {
                    const userWithPhoto: User = {
                        ...userState.user,
                        photo: URL.createObjectURL(response.data)
                    }

                    setUserState({
                        isBootstrapped: true,
                        user: userWithPhoto
                    })
                }
            },
            onError: () => {
                setPhotoHashToFetch(null)
                setUserState(prev => ({
                    ...prev,
                    isBootstrapped: true
                }))
            }
        }
    )

    useEffect(() => {
        if (!isLogged) {
            setUserState({
                isBootstrapped: true,
                user: null
            })
        } else {
            trigger()
        }
    }, [isLogged, trigger])

    const updateUserState = (user: User) => {
        setUserState(prev => ({
            ...prev,
            user
        }))
    }

    const contextValue: UserContextData = {
        user: userState.user,
        updateUserState,
        setProfilePhotoHash: setPhotoHashToFetch
    }

    if (!userState.isBootstrapped || isFetchingUserSession || isLoadingPhoto) {
        return <Loader flexFullPage />
    }

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
