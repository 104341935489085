import { MenuItem as ReactMenuItem } from '@szhsin/react-menu'
import styled from 'styled-components'

export const MenuItem = styled(ReactMenuItem)`
    font-size: 0.875rem;

    color: ${props => props.theme.colors.blue};

    padding: 0.375rem 0.75rem;

    align-items: center;
    justify-content: flex-start;

    border: none;

    background-color: transparent;
`
