import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ValidationError } from '../ValidationError'

export function FormFieldWrapper({
    children,
    error,
    label,
    name
}: PropsWithChildren<{ error?: string; label?: string; name: string }>) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            {label && <Label htmlFor={name}>{t(label)}</Label>}
            {children}
            {error && <ValidationError>{t(error)}</ValidationError>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    padding-bottom: 0.5rem;
`

const Label = styled.label`
    font-size: 0.75rem;
    padding-bottom: 0.25rem;
`
