import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ID } from 'common/types'

type CourseGroupsListProps<CourseGroupType> = {
    groups: CourseGroupType[]
    selectedGroup: ID
    setSelectedGroup: (groupId: ID) => void
    Component: FC<CourseGroupType & { setIsActive: (id: ID) => void; isActive: boolean; number: number }>
    listTitle: string
}

export function CourseGroupsList<CourseGroupType extends { id: ID }>({
    groups,
    selectedGroup,
    setSelectedGroup,
    Component,
    listTitle
}: CourseGroupsListProps<CourseGroupType>) {
    const { t } = useTranslation()

    return (
        <section>
            <Title>{t(listTitle)}</Title>
            <List>
                {groups.map((singleGroup, index) => (
                    <Component
                        key={singleGroup.id}
                        {...singleGroup}
                        isActive={selectedGroup === singleGroup.id}
                        setIsActive={setSelectedGroup}
                        number={index + 1}
                    />
                ))}
            </List>
        </section>
    )
}

const Title = styled.h4`
    font-weight: 700;
    font-size: 0.75rem;

    margin: 0px 0px 0.75rem;
`

const List = styled.ul`
    list-style: none;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`
