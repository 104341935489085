import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type GetCourseDetailResponse = {
    id: ID
    courseName: string
    content: string
    photoHash: string
}

export function useGetCourseDetail(courseId: ID, isLoggedUser?: boolean) {
    const api = useApi()

    const url = isLoggedUser ? `/courses/${courseId}` : `/guest/courses/${courseId}`

    return () => api.get<any, GetCourseDetailResponse>(url)
}
