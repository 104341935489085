import { SafeParseError, ZodIssueCode, z } from 'zod'

import { emailRegex } from 'common/constants'

const addressValidation = z.object({
    street: z
        .string({ required_error: 'basicProfileForm.field.street.validation.required' })
        .min(3, { message: 'basicProfileForm.field.street.validation.lengthError' })
        .max(255, { message: 'basicProfileForm.field.street.validation.lengthError' })
        .transform(val => val.trim()),
    postalCode: z
        .string({ required_error: 'basicProfileForm.field.postalCode.validation.required' })
        .min(5, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
        .max(32, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
        .transform(val => val.trim()),
    city: z
        .string({ required_error: 'basicProfileForm.field.city.validation.required' })
        .min(3, { message: 'basicProfileForm.field.city.validation.lengthError' })
        .max(32, { message: 'basicProfileForm.field.city.validation.lengthError' })
        .transform(val => val.trim())
})

export const addUserFormSchema = z
    .object({
        roleId: z.number({
            required_error: 'basicProfileForm.field.role.validation.required',
            invalid_type_error: 'basicProfileForm.field.role.validation.required'
        }),
        firstName: z
            .string({ required_error: 'basicProfileForm.field.firstName.validation.required' })
            .min(3, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
            .max(64, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
            .transform(val => val.trim()),
        lastName: z
            .string({ required_error: 'basicProfileForm.field.lastName.validation.required' })
            .min(3, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
            .max(64, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
            .transform(val => val.trim()),

        street: z.string().transform(val => val.trim()),
        postalCode: z.string().transform(val => val.trim()),
        city: z.string().transform(val => val.trim()),

        email: z
            .string({ required_error: 'basicProfileForm.field.email.validation.requiredError' })
            .regex(emailRegex, 'basicProfileForm.field.email.validation.incorrectEmail')
            .transform(val => val.trim())
    })
    .superRefine((val, ctx) => {
        const needValidateAddress = ['street', 'postalCode', 'city'].some(fieldName =>
            Boolean(val[fieldName as keyof AddUserFormData])
        )

        if (needValidateAddress) {
            const result = addressValidation.safeParse(val) as SafeParseError<any>

            if (result.error) {
                result.error.issues.forEach(validationError => {
                    ctx.addIssue({
                        message: validationError.message,
                        path: validationError.path,
                        code: ZodIssueCode.custom
                    })
                })
            }
        }
    })

export type AddUserFormData = z.infer<typeof addUserFormSchema>
