import styled from 'styled-components'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'

import { useUser } from '../features/userProvider/contexts'
import { ActiveCoursesList, useGetActiveCourses } from '../features/activeCoursesList'

import { Error, Loader, NoDataElement, FinancedBy } from 'common/components'
import { usePageName } from 'common/hooks'

export function Home() {
    usePageName('pageHeaders.home')

    const { t } = useTranslation()
    const { user } = useUser()
    const { data, error, isLoading } = useSWR('active-courses', useGetActiveCourses())

    return (
        <>
            <Welcome>{t('home.title', { username: user?.firstName || '' })}</Welcome>
            {isLoading && <Loader />}
            {error && <Error title="home.error.title" description="home.error.description" />}

            {!data?.length && !isLoading && <NoDataElement title="home.noElements" />}
            {!error && !isLoading && !!data && <ActiveCoursesList coursesList={data || []} />}

            <StyledFinancedBy />
        </>
    )
}

const Welcome = styled.h2`
    font-size: 2.5rem;
    font-weight: 700;

    margin: 0 0 1.5rem 0;
`

const StyledFinancedBy = styled(FinancedBy)`
    margin-top: auto;
`
