import { AppRoute } from './appRoute'
import { AppRoutes } from './routes'

interface RoutesGroup {
    routes: AppRoute[]
    redirectRout: AppRoute
}

export const unauthorizedRoutes: RoutesGroup = {
    routes: [
        AppRoutes.LoginPage,
        AppRoutes.ResetPassword,
        AppRoutes.Register,
        AppRoutes.GuestHome,
        AppRoutes.GuestSearch,
        AppRoutes.GuestSearchResults,
        AppRoutes.GuestAllCourses
    ],
    redirectRout: AppRoutes.LoginPage
}

export const userRoutes: RoutesGroup = {
    routes: [
        AppRoutes.HomePage,
        AppRoutes.SearchPage,
        AppRoutes.UserCourses,
        AppRoutes.ChatsPage,
        AppRoutes.ProfilePage,
        AppRoutes.SearchResultsPage,
        AppRoutes.PaymentStatus,
        AppRoutes.CourseDetails,
        AppRoutes.ChatPage
    ],
    redirectRout: AppRoutes.HomePage
}

export const instructorRoutes: RoutesGroup = {
    routes: [
        AppRoutes.HomePage,
        AppRoutes.SearchPage,
        AppRoutes.UserCourses,
        AppRoutes.ChatsPage,
        AppRoutes.ProfilePage,
        AppRoutes.SearchResultsPage,
        AppRoutes.PaymentStatus,
        AppRoutes.CourseDetails,
        AppRoutes.ChatPage,
        AppRoutes.InstructorCoursesPage,
        AppRoutes.InstructorCourseDetails,
        AppRoutes.InstructorAddUserGroup
    ],
    redirectRout: AppRoutes.HomePage
}

export const administratorRoutes: RoutesGroup = {
    routes: [
        AppRoutes.AdminHome,
        AppRoutes.AdminSearchPage,
        AppRoutes.AdminSearchResultPage,
        AppRoutes.AdminCourseDetailsPage,
        AppRoutes.AdminUserEditPage,
        AppRoutes.AdminAddUserGroup,
        AppRoutes.AdminSearchUser,
        AppRoutes.AdminAllCourses,
        AppRoutes.AdminCourses,
        AppRoutes.ChatsPage,
        AppRoutes.ChatPage,
        AppRoutes.ProfilePage,
        AppRoutes.AdminAddUser,
        AppRoutes.AdminExternalIdentifiers
    ],
    redirectRout: AppRoutes.AdminHome
}
