import { useTheme } from 'styled-components'

export function WarningIcon(props: {}) {
    const { colors } = useTheme()

    return (
        <svg
            {...props}
            fill="#000000"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            width="800px"
            height="800px"
            viewBox="0 0 123.996 123.996"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill={colors.warningFont}
                    d="M9.821,118.048h104.4c7.3,0,12-7.7,8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5,0l-52.2,92.5
		C-2.179,110.348,2.521,118.048,9.821,118.048z M70.222,96.548c0,4.8-3.5,8.5-8.5,8.5s-8.5-3.7-8.5-8.5v-0.2c0-4.8,3.5-8.5,8.5-8.5
		s8.5,3.7,8.5,8.5V96.548z M57.121,34.048h9.801c2.699,0,4.3,2.3,4,5.2l-4.301,37.6c-0.3,2.7-2.1,4.4-4.6,4.4s-4.3-1.7-4.6-4.4
		l-4.301-37.6C52.821,36.348,54.422,34.048,57.121,34.048z"
                />
            </g>
        </svg>
    )
}
