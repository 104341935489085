import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { PaymentStatus } from '../types'

type UsePaymentMockResponse = {
    id: ID
}

type UsePaymentMockParams = {
    arg: {
        paymentStatus: PaymentStatus
        withUserStatement: boolean
    }
}

export function usePaymentMock(courseGroupId: ID) {
    const api = useApi()

    return (_: string, { arg: { paymentStatus, withUserStatement } }: UsePaymentMockParams) =>
        api.post<any, UsePaymentMockResponse>(`/payments/mock/${paymentStatus}`, {
            courseGroupId,
            withUserStatement
        })
}
