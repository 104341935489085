import styled from 'styled-components'

import { formatChatDate } from 'common/utils/date'

import { FrontendMessage } from '../types/frontendMessage'

export const CHAT_MESSAGE_MIN_HEIGHT = 52

export function ChatMessage({ content, context, senderFullName, sentAt }: FrontendMessage) {
    return (
        <Wrapper context={context}>
            <MessageContent context={context}>{content}</MessageContent>
            <DateAndSender>
                {formatChatDate(sentAt)} {context === 'incoming' && senderFullName}
            </DateAndSender>
        </Wrapper>
    )
}

const Wrapper = styled.div<Pick<FrontendMessage, 'context'>>`
    display: flex;
    flex-direction: column;

    align-items: ${props => (props.context === 'outgoing' ? 'flex-end' : 'flex-start')};

    padding-bottom: 0.75rem;

    &:last-of-type {
        padding-bottom: 0;
    }
`

const MessageContent = styled.div<Pick<FrontendMessage, 'context'>>`
    max-width: 75%;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;

    padding: 0.375rem 0.625rem;
    margin-bottom: 0.5rem;

    background-color: ${props =>
        props.context === 'incoming' ? props.theme.colors.blueBackground : props.theme.colors.blue};

    color: ${props => (props.context === 'incoming' ? props.theme.colors.blue : props.theme.colors.white)};

    white-space: pre-line;
`

const DateAndSender = styled.div`
    max-width: 75%;

    font-size: 0.5rem;
    font-style: normal;
    font-weight: 700;

    color: rgba(19, 34, 88, 0.4);
`
