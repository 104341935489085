import { NoDataElement } from 'common/components'

import { GroupChat } from '../types'

import { GroupChatBox } from './GroupChatBox'

interface GroupChatListProps {
    chats: GroupChat[]
}

export function GroupChatList({ chats }: GroupChatListProps) {
    if (!chats?.length) {
        return <NoDataElement title="chats.noGroupChats" />
    }

    return (
        <>
            {chats.map(({ chatId, ...rest }) => (
                <GroupChatBox {...rest} key={chatId} chatId={chatId} />
            ))}
        </>
    )
}
