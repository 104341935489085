import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type UseGetChatIdResponse = {
    id: ID
}

export function useGetChatId(userId: ID) {
    const api = useApi()

    return () => api.get<any, UseGetChatIdResponse>(`chats/individual?userId=${userId}`)
}
