import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { formatDate } from 'common/utils/date'
import { CourseBoxWrapper } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

import { CourseGroupsAsInstructor } from '../types/courseGroupsAsInstructor'

export function InstructorCourseElement({
    courseId,
    courseName,
    endsAt,
    id,
    maximumMembers,
    startsAt,
    membersCount,
    voivodeshipName
}: CourseGroupsAsInstructor) {
    const { t } = useTranslation()

    return (
        <StyledLink
            to={AppRoutes.AdminCourseDetailsPage.getPath({ courseId, groupId: id })}
            aria-label="Przejdź do kursu"
        >
            <StyledWrapper>
                <GroupProperty className="title">{courseName}</GroupProperty>
                <GroupProperty>
                    {membersCount}/{maximumMembers} {t('admin.courseDetails.list.membersLabel')}
                </GroupProperty>
                <GroupProperty>{voivodeshipName}</GroupProperty>
                <GroupProperty>
                    {formatDate(startsAt)} - {formatDate(endsAt)}
                </GroupProperty>
            </StyledWrapper>
        </StyledLink>
    )
}

const StyledLink = styled(Link)`
    text-decoration: none;
`

const StyledWrapper = styled(CourseBoxWrapper)`
    flex-direction: column;

    padding: 0.5rem;
`

const GroupProperty = styled.p`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;

    margin: 0;

    line-height: 1.125rem;

    &.title {
        font-weight: 700;
    }
`
