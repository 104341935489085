import { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Modal as ReactModal } from 'react-responsive-modal'

import 'react-responsive-modal/styles.css'
import './ModalStyles.css'

import { TickIcon } from 'common/assets/TickIcon'
import { CrossIcon } from 'common/assets/CrossIcon'
import { WarningIcon } from 'common/assets/WarningIcon'

import { Button } from '../../Button'

export interface ModalProps {
    icon?: 'success' | 'warning' | 'error'
    title: string
    description: ReactNode
    cancelLabel?: string
    onClose: () => void
    isOpen: boolean
    onConfirm: () => void
    confirmLabel: string
    showCloseIcon?: boolean
}

export function Modal({
    title,
    description,
    cancelLabel,
    isOpen,
    onClose,
    onConfirm,
    confirmLabel,
    icon,
    showCloseIcon = true
}: ModalProps) {
    const IconToRender = useMemo(() => {
        if (!icon) {
            return null
        }

        if (icon === 'error') {
            return CrossIcon
        }

        if (icon === 'success') {
            return TickIcon
        }

        return WarningIcon
    }, [icon])

    if (!isOpen) {
        return null
    }

    return (
        <ReactModal
            showCloseIcon={showCloseIcon}
            closeOnOverlayClick={false}
            open={isOpen}
            center
            onClose={onClose}
            classNames={{
                modal: 'customModal'
            }}
        >
            <ContentWrapper showCloseIcon={showCloseIcon}>
                <Title>
                    {IconToRender && (
                        <IconWrapper>
                            <IconToRender />
                        </IconWrapper>
                    )}

                    {title}
                </Title>
                <Content paddingLeft={!!IconToRender}>{description}</Content>
            </ContentWrapper>

            <ButtonsWrapper>
                {cancelLabel && (
                    <Button isSmall secondary onClick={onClose}>
                        {cancelLabel}
                    </Button>
                )}
                <Button isSmall onClick={onConfirm}>
                    {confirmLabel}
                </Button>
            </ButtonsWrapper>
        </ReactModal>
    )
}

const ContentWrapper = styled.div<{ showCloseIcon: boolean }>`
    ${props =>
        props.showCloseIcon &&
        `
    padding-top: 1.5rem;
    `}
`

const Title = styled.h4`
    font-weight: 700;
    font-size: 1rem;
    margin: 0rem;
    width: 100%;

    display: flex;
    align-items: center;
`

const Content = styled.p<{ paddingLeft: boolean }>`
    font-size: 0.75rem;

    ${props =>
        props.paddingLeft &&
        `
    padding-left: 2.5rem
    `}
`

const ButtonsWrapper = styled.div`
    display: flex;

    justify-content: flex-end;

    margin-top: 1.5rem;

    > button:last-of-type {
        margin-left: 0.75rem;
    }
`

const IconWrapper = styled.div`
    line-height: 0;

    > svg {
        margin-right: 0.75rem;

        width: 1.75rem;
        height: 1.75rem;
    }
`
