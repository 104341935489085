import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AppRoutes } from 'common/routing/routes'
import { Error, Loader, NoDataElement, CourseGroupsList } from 'common/components'
import { usePageName } from 'common/hooks'
import { ID } from 'common/types'
import { cacheKeys } from 'common/constants'

import {
    AdminGroupElement,
    AdminSelectedGroupCourseDetails,
    useGetAdminCourseGroups,
    AdminCourseGroup
} from 'features/administratorFeatures/courseDetails'

export function AdminCourseDetails() {
    usePageName('pageHeaders.adminCourseDetails')

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { courseId, groupId } = useParams<{ courseId: string; groupId: string }>()

    const [selectedGroup, setSelectedGroup] = useState<AdminCourseGroup | null>(null)

    const getCourseGroups = useGetAdminCourseGroups(Number(courseId))

    const handleSetSelectedGroup = (selectedGroupId: ID) => {
        const group = data?.find(elem => elem.id === selectedGroupId)
        setSelectedGroup(group || null)
    }

    const cacheKeyForAdminGroups = cacheKeys.adminCourseGroups(Number(courseId))

    /**
     * redirect to search when there is no course id or its not a number
     */
    useEffect(() => {
        if (!courseId || isNaN(Number(courseId))) {
            navigate(AppRoutes.SearchPage.getPath())
        }
    }, [courseId, navigate])

    const { data, error, isLoading } = useSWR(
        !isNaN(Number(courseId)) ? cacheKeyForAdminGroups : null,
        getCourseGroups,
        {
            onSuccess: response => {
                if (selectedGroup) {
                    const groupToSet = response.find(group => group.id === selectedGroup.id)
                    if (groupToSet) {
                        return setSelectedGroup(groupToSet)
                    }
                }

                if (groupId) {
                    const groupToSet = response.find(group => group.id === Number(groupId))
                    if (groupToSet) {
                        return setSelectedGroup(groupToSet)
                    }
                }

                setSelectedGroup(response[0] || null)
            }
        }
    )

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="admin.courseDetails.getCourseGroupError.title"
                description="admin.courseDetails.getCourseGroupError.description"
            />
        )
    }

    if (!data?.length) {
        return (
            <NoDataElement
                title="admin.courseDetails.noGroups.title"
                description={
                    <RedirectToCoursesList to={AppRoutes.InstructorCoursesPage.getPath()}>
                        {t('admin.courseDetails.noGroups.description')}
                    </RedirectToCoursesList>
                }
            />
        )
    }

    return (
        <>
            <CourseGroupsList
                groups={data || []}
                selectedGroup={selectedGroup?.id || -1}
                setSelectedGroup={handleSetSelectedGroup}
                listTitle="admin.courseDetails.list.listLabel"
                Component={AdminGroupElement}
            />

            {!!selectedGroup && (
                <AdminSelectedGroupCourseDetails courseId={Number(courseId)} selectedGroup={selectedGroup} />
            )}
        </>
    )
}

const RedirectToCoursesList = styled(Link)`
    margin: 0.5rem 0px 0px;
    font-size: 0.75rem;
`
