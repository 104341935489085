import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, ErrorModal } from 'common/components'
import { ID } from 'common/types'
import { AppRoutes } from 'common/routing/routes'

import { useGetPaymentLink } from '../api/useGetPaymentLink'

import { usePaymentMock } from '../api'
import { PaymentStatus } from '../types'

type PaymentBarProps = {
    courseId: ID
    disabled: boolean
    withUserStatement: boolean
}

export function PaymentBar({ courseId, disabled, withUserStatement }: PaymentBarProps) {
    const navigate = useNavigate()

    const [error, setError] = useState<string | null>(null)
    const [productionPaymentError, setProductionPaymentError] = useState(false)

    const isProductionPayment = process.env.REACT_APP_PRODUCTION_PAYMENT === '1'

    const { t } = useTranslation()

    const updater = usePaymentMock(courseId)
    const getPaymentLink = useGetPaymentLink(courseId)

    const { trigger, isMutating } = useSWRMutation('/api/user', updater, {
        onSuccess: response => {
            navigate(AppRoutes.PaymentStatus.getPath({ paymentId: response.id }))
        },
        onError: error => {
            setError(`paymentProcess.error.apiError.${error?.response?.data?.message}`)
        },
        throwOnError: false
    })

    const { trigger: triggerGetPaymentLink, isMutating: isGettingPaymentLink } = useSWRMutation(
        `/payments/generate-link?courseId=${courseId}`,
        getPaymentLink,
        {
            onSuccess: response => {
                window.location.replace(response.paymentUrl)
            },
            onError: () => {
                setProductionPaymentError(true)
            },
            throwOnError: false
        }
    )

    const isDisabled = isMutating || disabled || isGettingPaymentLink

    const handlePayment = (paymentStatus: PaymentStatus) => () => {
        trigger({ paymentStatus, withUserStatement })
    }

    const handleProductionPayment = () => {
        triggerGetPaymentLink(withUserStatement)
    }

    const handleProductionErrorModalClose = () => setProductionPaymentError(false)

    if (isProductionPayment) {
        return (
            <>
                <BuyButton disabled={isDisabled} onClick={handleProductionPayment}>
                    {t('paymentProcess.paymentButton.label')}
                </BuyButton>
                <ErrorModal
                    isOpen={productionPaymentError}
                    onClose={handleProductionErrorModalClose}
                    onConfirm={handleProductionErrorModalClose}
                    title={t('paymentProcess.error.production.title')}
                    description={t('paymentProcess.error.production.description')}
                    confirmLabel={t('paymentProcess.error.production.confirmButtonLabel')}
                />
            </>
        )
    }

    return (
        <>
            <Title>Test płatność - statusy:</Title>
            <TestWrapper>
                <StyledButton disabled={isDisabled} onClick={handlePayment(PaymentStatus.SUCCESS)}>
                    Poprawna
                </StyledButton>
                <StyledButton disabled={isDisabled} onClick={handlePayment(PaymentStatus.FAILURE)}>
                    Negatywna
                </StyledButton>
                <StyledButton disabled={isDisabled} onClick={handlePayment(PaymentStatus.PENDING)}>
                    Oczekująca
                </StyledButton>
            </TestWrapper>
            {/* Ten error jest dla tryby dev tylko */}
            {!!error && !isMutating && <Error>{t([error, 'paymentProcess.error.globalError'])} </Error>}
        </>
    )
}

const Title = styled.h6`
    text-align: center;
    font-weight: bold;

    margin: 1rem 0 1rem 0;
`

const BuyButton = styled(Button)`
    width: 100%;

    margin-top: 0.75rem;

    padding: 0.5rem 2rem;
`

const TestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const StyledButton = styled(Button)`
    font-size: 0.75rem;
    padding: 0.5rem 0px;
`

const Error = styled.div`
    color: red;

    margin-top: 1rem;

    text-align: center;
`
