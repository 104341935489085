import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useSWRMutation from 'swr/mutation'

import { Button, ErrorModal, UserBasicForm, UserEmailAndPasswordForm } from 'common/components'

import { registerFormData, RegisterFormData, useRegister } from 'features/register'
import { useAuth } from 'features/authorizationProvider/context'

export function Register() {
    const { t } = useTranslation()
    const [showErrorModal, setShowErrorModal] = useState(false)
    const { login } = useAuth()

    const creator = useRegister()

    const { trigger, isMutating } = useSWRMutation(`/register`, creator, {
        onSuccess: response => {
            login(response.token)
        },
        onError: () => {
            setShowErrorModal(true)
        },
        throwOnError: false
    })

    const formMethods = useForm<RegisterFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(registerFormData)
    })

    const handleSubmit = ({
        city,
        email,
        firstName,
        lastName,
        password,
        postalCode,
        street,
        note
    }: RegisterFormData) => {
        trigger({
            city,
            email,
            firstName,
            lastName,
            password,
            postalCode,
            street,
            note: note || null
        })
    }
    const handleCloseModal = () => setShowErrorModal(false)

    const revalidatePasswordFields = () => {
        const errorsFields = Object.keys(formMethods.formState.errors)
        const passwordFields = ['password', 'passwordRepeat']

        if (errorsFields.some(singleErrorField => passwordFields.includes(singleErrorField))) {
            passwordFields.forEach(singlePasswordField => {
                formMethods.trigger(singlePasswordField as keyof RegisterFormData)
            })
        }
    }

    return (
        <>
            <Wrapper>
                <Title>{t('register.title')}</Title>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                        <UserEmailAndPasswordForm revalidatePasswordFields={revalidatePasswordFields} />
                        <UserBasicForm />
                        <SubmitButton type="submit" disabled={isMutating}>
                            {t('register.submitLabel')}
                        </SubmitButton>
                    </form>
                </FormProvider>
            </Wrapper>
            <ErrorModal
                confirmLabel={t('register.loginFailedModal.confirm')}
                description={t('register.loginFailedModal.description')}
                title={t('register.loginFailedModal.title')}
                onClose={handleCloseModal}
                onConfirm={handleCloseModal}
                isOpen={showErrorModal}
            />
        </>
    )
}

const Wrapper = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    z-index: 1;

    flex-grow: 1;
    display: flex;

    flex-direction: column;

    position: relative;

    padding: 0 1.5rem 1rem;
`

const Title = styled.h3`
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    margin: 0 0 1rem 0;
`

const SubmitButton = styled(Button)`
    width: 100%;

    margin-top: 1rem;
`
