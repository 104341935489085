import { useTranslation } from 'react-i18next'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import styled from 'styled-components'

export function Select<OptionType>(props: ReactSelectProps<OptionType>) {
    const { t } = useTranslation()

    const classes: ReactSelectProps['classNames'] = {
        control: props => (props.isFocused ? 'wrapper focused' : 'wrapper'),
        placeholder: () => 'placeholder',
        input: () => 'input',
        menu: () => 'menu',
        menuList: () => 'menu-list',
        option: props => (props.isSelected ? 'option selected' : 'option'),
        singleValue: () => 'placeholder',
        dropdownIndicator: () => 'icon-container'
    }

    return (
        <StyledReactSelect
            {...props}
            // @ts-ignore
            classNames={classes}
            noOptionsMessage={() => t('selectComponent.noData')}
            isClearable
            components={{
                IndicatorSeparator: () => null
            }}
        />
    )
}

const StyledReactSelect = styled(ReactSelect)`
    .wrapper {
        border-color: ${props => props.theme.colors.border};
        border-radius: 0;

        box-shadow: none;

        color: ${props => props.theme.colors.blue};

        &:hover {
            border-color: ${props => props.theme.colors.borderDark};
            box-shadow: none;
        }

        &.focused {
            -webkit-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            -moz-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);

            border-color: ${props => props.theme.colors.borderDark};
        }
    }

    .icon-container {
        svg {
            fill: ${props => props.theme.colors.blue};
        }
    }

    .placeholder {
        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;
    }

    .input {
        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;
    }

    .menu {
        margin-top: 0;
        border-radius: 0;

        width: calc(100% - 0.125rem);
        left: 0.0625rem;

        z-index: ${props => props.theme.zIndex.select};
    }

    .menu-list {
        padding-top: 0;
    }

    .option {
        cursor: pointer;

        background-color: ${props => props.theme.colors.blueBackground};

        margin-top: 0.375rem;
        margin-bottom: 0.125rem;

        color: ${props => props.theme.colors.darkBlue};
        font-size: 0.75rem;

        outline: unset;

        &.selected {
            font-weight: 700;
        }

        &:hover {
            background-color: ${props => props.theme.colors.darkBlueBackground};
            color: ${props => props.theme.colors.white};
        }

        &:active {
            background-color: ${props => props.theme.colors.blueBackground};
        }
    }
` as typeof ReactSelect
