/**
 * messages definition
 */
export const addMembersErrorsMessages = {
    courseGroupSlotsExceeded: {
        message: "CourseGroup can't handle all members. Maximum amount of available slots exceeded.",
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.slotsExceeded'
    },
    courseGroupSignTimeExpired: {
        message: 'CourseGroup is expired for registration.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupSignTimeExpired'
    },
    courseGroupAreFull: {
        message: 'CourseGroup is already full.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupFull'
    },
    groupUserAlreadyMember: {
        message: 'User is already member of courseGroup.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupUserAlreadyMember'
    },
    groupUserInvalidRole: {
        message: 'User does not match user role requirements.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupUserInvalidRole'
    },
    groupUserFailedCerts: {
        message: 'User does not match certificate requirements.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupUserFailedCerts'
    },
    groupUserAlreadyPass: {
        message: 'User has already finished same course.',
        translationKey: 'admin.courseDetails.addNewUsers.backendValidationErrors.groupUserAlreadyPass'
    }
}
