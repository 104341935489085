import { AppRoutes } from '../../../routing/routes'
import { MenuElement } from '../types/menuElement'

import home from '../assets/home.svg'
import schedule from '../assets/schedule.svg'
import courses from '../assets/courses.svg'
import instructorCourses from '../assets/instructorCourses.svg'
import chats from 'common/assets/chats.svg'
import userSearch from '../assets/userSearch.svg'

const homeElement = {
    label: 'Strona główna',
    ariaLabel: 'Przejdź do strony głównej',
    image: home,
    url: AppRoutes.HomePage.getPath()
}

const searchElement = {
    label: 'Terminarz',
    ariaLabel: 'Przejdź do terminarza',
    image: schedule,
    url: AppRoutes.SearchPage.getPath()
}

const userCoursesElement = {
    label: 'Kursy',
    ariaLabel: 'Przejdź do swoich kursów',
    image: courses,
    url: AppRoutes.UserCourses.getPath()
}

const instructorCoursesElement = {
    label: 'Prowadzone kursy',
    ariaLabel: 'Przejdź do prowadzonych kursów',
    image: instructorCourses,
    url: AppRoutes.InstructorCoursesPage.getPath()
}

const chatsElement = {
    label: 'Czaty',
    ariaLabel: 'Przejdź do listy czatów',
    image: chats,
    url: AppRoutes.ChatsPage.getPath()
}

const adminSearch = {
    label: 'Terminarz',
    ariaLabel: 'Przejdź do terminarza',
    image: schedule,
    url: AppRoutes.AdminSearchPage.getPath()
}

const adminHomeElement = {
    label: 'Strona główna',
    ariaLabel: 'Przejdź do strony głównej',
    image: home,
    url: AppRoutes.AdminHome.getPath()
}

const adminSearchUserElement = {
    label: 'Wyszukaj użytkownika',
    ariaLabel: 'Przejdź do wyszukiwarki użytkowników',
    image: userSearch,
    url: AppRoutes.AdminSearchUser.getPath()
}

const adminCoursesElement = {
    label: 'Kursy',
    ariaLabel: 'Przejdź do listy kursów',
    image: courses,
    url: AppRoutes.AdminCourses.getPath()
}

const guestHomeElement = {
    label: 'Strona główna',
    ariaLabel: 'Przejdź do strony głównej',
    image: home,
    url: AppRoutes.GuestHome.getPath()
}

const guestSearchElement = {
    label: 'Terminarz',
    ariaLabel: 'Przejdź do terminarza',
    image: schedule,
    url: AppRoutes.GuestSearch.getPath()
}

const guestCoursesElement = {
    label: 'Kursy',
    ariaLabel: 'Przejdź do listy kursów',
    image: courses,
    url: AppRoutes.GuestAllCourses.getPath()
}

export const userMenuElements: MenuElement[] = [homeElement, searchElement, userCoursesElement, chatsElement]

export const instructorMenuElements: MenuElement[] = [
    homeElement,
    searchElement,
    userCoursesElement,
    instructorCoursesElement,
    chatsElement
]

export const adminMenuElements: MenuElement[] = [
    adminHomeElement,
    adminSearchUserElement,
    adminSearch,
    adminCoursesElement,
    chatsElement
]

export const guestMenuElement: MenuElement[] = [guestHomeElement, guestSearchElement, guestCoursesElement]
