import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Button, FinancedBy } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

export function GuestHome() {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <HeaderWrapper>
                <Welcome>{t('guest.home.welcome')}</Welcome>
                <Description>{t('guest.home.description')}</Description>
            </HeaderWrapper>

            <Link to={AppRoutes.GuestAllCourses.getPath()} aria-label="Przejdź do listy kursów">
                <StyledButton>{t('guest.home.checkCourses')}</StyledButton>
            </Link>

            <FooterWrapper>
                <Link to={AppRoutes.LoginPage.getPath()} aria-label="Przejdź do strony logowania">
                    <StyledButton isSmall>{t('guest.home.backToLogin')}</StyledButton>
                </Link>

                <FinancedBy />
            </FooterWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
`

const HeaderWrapper = styled.div`
    padding: 0 1.44rem;

    z-index: 1;

    text-align: center;
`

const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const Welcome = styled.h2`
    font-size: 1.875rem;
    font-weight: 700;

    line-height: normal;
    margin: 1.5rem 0 0 0;

    z-index: 1;
`

const Description = styled.p`
    font-size: 1.25rem;
    font-weight: 700;
`

const StyledButton = styled(Button)`
    width: fit-content;

    z-index: 1;
`
