import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { AppRoutes } from 'common/routing/routes'

export function AfterReset() {
    const { t } = useTranslation()

    return (
        <>
            <Wrapper>
                <Content>
                    <Title>{t('resetPassword.afterReset.title')}</Title>
                    <p>
                        <Trans i18nKey="resetPassword.afterReset.description" components={{ 1: <br /> }} />
                    </p>
                </Content>
            </Wrapper>
            <BackToLogin to={AppRoutes.LoginPage.getPath()}>
                {t('resetPassword.afterReset.backButtonLabel')}
            </BackToLogin>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    background-color: rgba(255, 255, 255, 0.6);

    padding: 1rem;
`

const Content = styled.div`
    background-color: rgba(255, 255, 255, 0.6);

    padding: 1rem;

    text-align: center;
`

const Title = styled.h3`
    font-size: 1rem;
    font-weight: 700;
`

const BackToLogin = styled(Link)`
    display: block;

    width: 100%;

    margin-top: 1rem;
    padding: 0.25rem 0.625rem;

    background-color: ${props => props.theme.colors.blue};

    color: ${props => props.theme.colors.white};
    font-weight: 700;
    font-size: 0.875rem;
    text-decoration: none;
    text-align: center;
    text-transform: none;

    border-radius: 0.125rem;

    cursor: pointer;
`
