import { ZodIssueCode, z } from 'zod'

export const groupFormData = z
    .object({
        instructorId: z.number({
            required_error: 'admin.courseDetails.groupManage.fields.instructor.requiredError',
            invalid_type_error: 'admin.courseDetails.groupManage.fields.instructor.requiredError'
        }),
        voivodeshipId: z.number({
            required_error: 'admin.courseDetails.groupManage.fields.voivoidship.requiredError',
            invalid_type_error: 'admin.courseDetails.groupManage.fields.voivoidship.requiredError'
        }),
        maximumMembers: z.number({
            required_error: 'admin.courseDetails.groupManage.fields.maximumMembers.requiredError',
            invalid_type_error: 'admin.courseDetails.groupManage.fields.maximumMembers.requiredError'
        }),
        participantsCount: z.number(),
        dateRange: z.object(
            {
                from: z.date({
                    required_error: 'admin.courseDetails.groupManage.fields.dateRange.fromRequired',
                    invalid_type_error: 'admin.courseDetails.groupManage.fields.dateRange.fromRequired'
                }),
                to: z.date({
                    required_error: 'admin.courseDetails.groupManage.fields.dateRange.toRequired',
                    invalid_type_error: 'admin.courseDetails.groupManage.fields.dateRange.toRequired'
                })
            },
            {
                required_error: 'admin.courseDetails.groupManage.fields.dateRange.requiredError'
            }
        )
    })
    .superRefine((val, ctx) => {
        if (val.participantsCount > val.maximumMembers) {
            ctx.addIssue({
                message: 'admin.courseDetails.groupManage.fields.maximumMembers.toBig',
                path: ['maximumMembers'],
                code: ZodIssueCode.custom
            })
        }
    })

export type GroupFormData = z.infer<typeof groupFormData>
