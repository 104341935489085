import { MutableRefObject, forwardRef, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import { Loader, NoDataElement } from 'common/components'

import { FrontendMessage } from '../types/frontendMessage'

import { CHAT_MESSAGE_MIN_HEIGHT, ChatMessage } from './ChatMessage'

type ChatMessagesSectionProps = {
    messages: FrontendMessage[]
    hasMorePreviousMessages: boolean
    isLoading: boolean
    getPreviousMessages: () => void
    setItemsPerPage: (itemsPerPage: number) => void
}

export const ChatMessagesSection = forwardRef<HTMLDivElement, ChatMessagesSectionProps>(
    ({ messages, hasMorePreviousMessages, isLoading, setItemsPerPage, getPreviousMessages }, ref) => {
        const isScrollListenerAttached = useRef(false)

        const debouncedGetMessage = debounce(() => getPreviousMessages(), 250)

        const handleScroll = useCallback(() => {
            const messagesWrapper = (ref as MutableRefObject<HTMLDivElement | null>).current

            if (!messagesWrapper) {
                return null
            }

            const height = messagesWrapper.clientHeight
            const scrollTop = messagesWrapper.scrollTop

            if (!isLoading && hasMorePreviousMessages && scrollTop < 0.2 * height) {
                debouncedGetMessage()
            }
        }, [ref, hasMorePreviousMessages, isLoading, debouncedGetMessage])

        useEffect(() => {
            const messagesWrapper = (ref as MutableRefObject<HTMLDivElement | null>).current

            if (messagesWrapper && messages.length && !isScrollListenerAttached.current) {
                messagesWrapper?.scrollTo(0, messagesWrapper.scrollHeight)
                setTimeout(() => {
                    messagesWrapper?.addEventListener('scroll', handleScroll)
                }, 500)
                isScrollListenerAttached.current = true
            }

            return () => {
                messagesWrapper?.removeEventListener('scroll', handleScroll)
                isScrollListenerAttached.current = false
            }
        }, [ref, handleScroll, messages])

        useEffect(() => {
            const messagesWrapper = (ref as MutableRefObject<HTMLDivElement | null>).current

            if (messagesWrapper) {
                const height = messagesWrapper.clientHeight
                const width = messagesWrapper.clientWidth
                const biggestSize = Math.max(height, width)

                setItemsPerPage(Math.ceil((biggestSize / CHAT_MESSAGE_MIN_HEIGHT) * 1.5))
            }
        }, [ref, setItemsPerPage])

        return (
            <Wrapper>
                {!isLoading && !messages.length && <StyledNoData title="chat.noMessages" />}

                {isLoading && <Loader isSmall />}
                <MessagesContainer ref={ref}>
                    {messages.map(singleMessage => (
                        <ChatMessage {...singleMessage} key={singleMessage.messageId} />
                    ))}
                </MessagesContainer>
            </Wrapper>
        )
    }
)

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    flex-direction: column;

    width: 100%;
    height: 100%;

    max-height: calc(100vh - 262px);

    overflow: hidden;
`

const MessagesContainer = styled.div`
    flex: 1 0 0;
    display: block;

    overflow-y: scroll;
`

const StyledNoData = styled(NoDataElement)`
    margin-bottom: 0.75rem;
`
