import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type CreateContributeProps = {
    arg: {
        name: string
        dueDate: string
        paidAt: string | null
    }
}

export function useCreateContribute(userId: ID) {
    const api = useApi()

    return (_: string, { arg }: CreateContributeProps) =>
        api.post<any, any>(`/admin/users/${userId}/contributions/create`, arg)
}
