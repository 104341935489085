import { ChangeEvent, MutableRefObject, forwardRef } from 'react'
import styled from 'styled-components'

export const ChatTextInput = forwardRef<HTMLTextAreaElement, any>((props, ref) => {
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputRef = (ref as MutableRefObject<HTMLTextAreaElement | null>).current

        if (inputRef) {
            inputRef.style.height = '5px'
            inputRef.style.height = e.target.value ? `${Math.min(inputRef.scrollHeight, 84)}px` : 'auto'
        }
    }

    return <Input rows={1} ref={ref} onChange={handleChange} aria-label="Treść wiadomości" />
})

const Input = styled.textarea`
    width: 100%;

    min-height: 1.6875rem;

    resize: none;

    font-size: 0.875rem;
    font-weight: 400;

    color: ${props => props.theme.colors.darkBlue};
    padding: 0.25rem 0 0.25rem 0.75rem;

    border: 0;

    background-color: ${props => props.theme.colors.blueBackground};

    outline: none;
`
