import { useTheme } from 'styled-components'

export function DownloadIcon(props: any) {
    const theme = useTheme()

    return (
        <svg
            fill={theme.colors.blue}
            height="16PX"
            width="16PX"
            version="1.1"
            id="XMLID_288_"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            {...props}
        >
            <g id="download">
                <g>
                    <path d="M24,24H0v-8h2v6h20v-6h2V24z M12,18.4l-7.7-7.7l1.4-1.4l5.3,5.3V0h2v14.6l5.3-5.3l1.4,1.4L12,18.4z" />
                </g>
            </g>
        </svg>
    )
}
