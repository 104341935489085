import styled from 'styled-components'

export const UserPropertiesElementWrapper = styled.li`
    display: flex;

    width: 100%;

    background-color: ${props => props.theme.colors.blueBackground};

    padding: 0.5rem;
`
