import { FunctionComponent } from 'react'

export interface IAppRoute<RouteParameters> {
    routeComponent: FunctionComponent
    getPath(parameters: RouteParameters): string
}

export class AppRoute<RouteParameters extends Record<string, string | number | undefined> = {}>
    implements IAppRoute<RouteParameters>
{
    private pathPattern
    private component

    constructor(path: string, component: FunctionComponent) {
        this.pathPattern = path
        this.component = component
    }

    public get routeComponent(): FunctionComponent {
        return this.component
    }

    public getPath(parameters?: RouteParameters): string {
        if (!parameters) {
            return this.pathPattern
        }

        return Object.entries(parameters).reduce((prev, curr) => {
            const [parameter, value] = curr

            const valueToReplace = value ? String(value) : ''

            return prev.replaceAll(`:${parameter}`, valueToReplace).replaceAll(`:${parameter}?`, valueToReplace)
        }, this.pathPattern)
    }
}
