import { ID, SearchCourse } from 'common/types'

import { CourseBox, CourseDatesAndVoivodeship } from 'common/components'

import { AdminSearchCourseDetails } from './AdminSearchCourseDetails'

export interface AdminSearchCourseListElementProps extends SearchCourse {
    setAsActive: (id: ID) => void
    isActive: boolean
}

export function AdminSearchCourseListElement({
    courseName,
    id,
    instructorFullName,
    startsAt,
    voivodeshipName,
    statusName,
    setAsActive,
    isActive,
    endsAt
}: AdminSearchCourseListElementProps) {
    const handleOpen = (idToOpen: ID) => setAsActive(idToOpen)

    return (
        <CourseBox
            courseName={courseName}
            changeOpenElement={handleOpen}
            instructorFullName={instructorFullName}
            isActive={isActive}
            id={id}
            details={
                <CourseDatesAndVoivodeship
                    startsAt={startsAt}
                    endsAt={endsAt}
                    statusName={statusName}
                    voivodeshipName={voivodeshipName}
                    showStatusTag
                />
            }
            activeDetails={<AdminSearchCourseDetails id={id} />}
        />
    )
}
