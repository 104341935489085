import useSWR from 'swr'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loader, Error } from 'common/components'
import { AppRoutes } from 'common/routing/routes'
import { dateFormatForBackend } from 'common/utils/date'

import { GuestCoursesSearchForm, GuestSearchCourseFormData, useGetRegions } from 'features/guestFeatures/guestSearch'
import { SearchCourseFormData } from 'features/searchForm'

export function GuestSearch() {
    const location = useLocation()
    const navigate = useNavigate()

    const { data, error, isLoading } = useSWR('/search-configurator', useGetRegions())

    const handleSubmit = (formData: SearchCourseFormData) => {
        const basePath = AppRoutes.GuestSearchResults.getPath()
        const searchParams = new URLSearchParams()

        Object.entries(formData).forEach(([key, value]) => {
            if (value !== undefined && value !== null && key !== 'dateRange') {
                searchParams.set(key, String(value))
            }
        })

        searchParams.set('startsAtFrom', dateFormatForBackend(formData.dateRange.from))
        searchParams.set('startsAtTo', dateFormatForBackend(formData.dateRange.to))

        navigate(`${basePath}?${searchParams.toString()}`)
    }

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="guest.search.getError.title" description="guest.search.getError.description" />
    }

    return (
        <GuestCoursesSearchForm
            regions={data || []}
            defaultData={location.state as GuestSearchCourseFormData}
            handleSubmit={handleSubmit}
        />
    )
}
