import { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useGetAdminUserSearch } from 'common/api'
import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'
import { Loader, Error, NoDataElement, Button } from 'common/components'

import { SearchUserForm, SearchUserResults } from 'features/administratorFeatures/searchUser'

export function AdminUserSearch() {
    usePageName('pageHeaders.adminUserSearch')
    const { t } = useTranslation()

    const [firstGet, setFirstGet] = useState(false)

    const getUsers = useGetAdminUserSearch()
    const fetcher = (_: string, { arg }: { arg: string }) => getUsers(arg)

    const {
        data,
        trigger: searchUser,
        isMutating,
        error
    } = useSWRMutation(`/search-user`, fetcher, {
        throwOnError: false,
        onSuccess: () => {
            if (!firstGet) {
                setFirstGet(true)
            }
        }
    })

    const onSearch = (searchPhrase: string) => {
        searchUser(searchPhrase)
    }

    return (
        <>
            <SearchUserForm onSearch={onSearch} />

            <AddUserLink to={AppRoutes.AdminAddUser.getPath()}>
                <Button isSmall>{t('admin.searchUser.addUserButtonLabel')}</Button>
            </AddUserLink>

            {isMutating && <Loader />}
            {error && (
                <StyledError
                    title="admin.searchUser.searchError.title"
                    description="admin.searchUser.searchError.description"
                />
            )}

            {firstGet && !error && data?.length === 0 && <StyledNoData title="admin.searchUser.noData" />}
            {!isMutating && !error && !!data?.length && <SearchUserResults users={data} />}
        </>
    )
}

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`

const StyledNoData = styled(NoDataElement)`
    margin-top: 0.75rem;
`

const AddUserLink = styled(Link)`
    margin-top: 0.75rem;

    > button {
        width: 100%;
    }
`
