import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import { CourseMember, ID } from 'common/types'

import { AdminAddNewUsersForm } from './AdminAddNewUsersForm'

type AdminAddNewUsersSectionProps = {
    membersList: CourseMember[]
    maximumUsersCount: number
    currentUsersCount: number
    courseId: ID
    groupId: ID
}

export function AdminAddNewUsersSection({
    membersList,
    maximumUsersCount,
    currentUsersCount,
    courseId,
    groupId
}: AdminAddNewUsersSectionProps) {
    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleEditOn = () => setEdit(true)
    const handleEditOff = () => setEdit(false)

    const groupMembersIds = useMemo(() => membersList.map(singleMember => singleMember.id), [membersList])

    return (
        <Wrapper>
            {edit ? (
                <AdminAddNewUsersForm
                    groupMembersIds={groupMembersIds}
                    maximumUsersCount={maximumUsersCount}
                    currentUsersCount={currentUsersCount}
                    courseId={courseId}
                    groupId={groupId}
                    closeEdit={handleEditOff}
                />
            ) : (
                <StyledButton isSmall onClick={handleEditOn}>
                    {t('admin.courseDetails.addNewUsers.addNewUsersLabel')}
                </StyledButton>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-bottom: 0.75rem;
`

const StyledButton = styled(Button)`
    width: 100%;
`
