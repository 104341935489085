import { useState } from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

import { TopBar } from 'common/components/TopBar'

import { Menu } from './Menu'

export function AuthorizedLayout() {
    const [pageTitle, setPageTitle] = useState('')

    return (
        <>
            <TopBar label={pageTitle} />
            <Menu />
            <ContentWrapper role="main">
                <Outlet context={[pageTitle, setPageTitle]} />
            </ContentWrapper>
        </>
    )
}

export const ContentWrapper = styled.section`
    flex-grow: 1;
    display: flex;

    flex-direction: column;

    position: relative;

    padding: 0 1.5rem 5.0625rem;
`
