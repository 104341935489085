import styled from 'styled-components'

import { ActiveCourse, ActiveCourseProps } from './ActiveCourse'

interface ActiveCoursesListProps {
    coursesList: ActiveCourseProps[]
}

export function ActiveCoursesList({ coursesList }: ActiveCoursesListProps) {
    return (
        <Wrapper>
            {coursesList.map(({ id, ...rest }) => (
                <ActiveCourse key={id} id={id} {...rest} />
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 1rem;
`
