import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type CreateGroupParams = {
    arg: {
        courseId: ID
        voivodeshipId: ID
        maximumMembers: number
        startsAt: string
        endsAt: string
    }
}

type CreateGroupResponse = {
    courseGroupId: ID
    courseId: ID
}

export function useCreateInstructorGroup() {
    const api = useApi()

    return (_: string, { arg }: CreateGroupParams) =>
        api.post<any, CreateGroupResponse>('/session/instructor/courses/groups/create', arg)
}
