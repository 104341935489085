import { z } from 'zod'

export const profileFormData = z.object({
    firstName: z
        .string({ required_error: 'basicProfileForm.field.firstName.validation.required' })
        .min(3, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
        .max(64, { message: 'basicProfileForm.field.firstName.validation.lengthError' })
        .transform(val => val.trim()),
    lastName: z
        .string({ required_error: 'basicProfileForm.field.lastName.validation.required' })
        .min(3, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
        .max(64, { message: 'basicProfileForm.field.lastName.validation.lengthError' })
        .transform(val => val.trim()),
    street: z
        .string({ required_error: 'basicProfileForm.field.street.validation.required' })
        .min(3, { message: 'basicProfileForm.field.street.validation.lengthError' })
        .max(255, { message: 'basicProfileForm.field.street.validation.lengthError' })
        .transform(val => val.trim()),
    postalCode: z
        .string({ required_error: 'basicProfileForm.field.postalCode.validation.required' })
        .min(5, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
        .max(32, { message: 'basicProfileForm.field.postalCode.validation.lengthError' })
        .transform(val => val.trim()),
    city: z
        .string({ required_error: 'basicProfileForm.field.city.validation.required' })
        .min(3, { message: 'basicProfileForm.field.city.validation.lengthError' })
        .max(32, { message: 'basicProfileForm.field.city.validation.lengthError' })
        .transform(val => val.trim()),
    note: z.optional(z.string().max(1024, { message: 'basicProfileForm.field.note.validation.tooLong' }))
})

export type ProfileFormData = z.infer<typeof profileFormData>
