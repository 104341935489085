import styled from 'styled-components'

type LoaderProps = {
    flexFullPage?: boolean
    isSmall?: boolean
}

export function Loader({ flexFullPage, isSmall = false, ...rest }: LoaderProps) {
    return (
        <LoaderWrapper {...rest} fullPage={flexFullPage}>
            <LoaderWithAnimation isSmall={isSmall} />
        </LoaderWrapper>
    )
}

const LoaderWrapper = styled.div<{ fullPage?: boolean }>`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 1rem 0;

    ${props =>
        props.fullPage &&
        `
        flex-grow: 1;
    `}
`

const LoaderWithAnimation = styled.div<{ isSmall: boolean }>`
    display: inline-block;

    ${props =>
        props.isSmall
            ? `
                width: 3rem;
                height: 3rem;
                `
            : `
                width: 5rem;
                height: 5rem;
    `}

    &::after {
        content: ' ';
        display: block;

        margin: 0.5rem;

        ${props =>
            props.isSmall
                ? `
                width: 2em;
                height: 2rem;
                `
                : `
                width: 4rem;
                height: 4rem;
        `}

        border-radius: 50%;
        border: ${props => (props.isSmall ? 0.275 : 0.375)}rem solid ${props => props.theme.colors.blue};
        border-color: ${props => props.theme.colors.blue} transparent ${props => props.theme.colors.blue} transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
