import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ChatWithMember, UserBasicDetailsNoEdit } from 'common/components'
import { ID, UserRoles } from 'common/types'

import { ExtendedProfile } from '../types/extendedUserProfile'

type UserNoEditDetailsProps = {
    profile: ExtendedProfile
    userId: ID
}

export function UserNoEditDetails({ profile, userId }: UserNoEditDetailsProps) {
    const { t } = useTranslation()

    const profileType = useMemo(() => {
        const { roleName } = profile

        if (roleName === UserRoles.ADMINISTRATOR) {
            return t('admin.editUser.role.admin')
        }

        if (roleName === UserRoles.INSTRUCTOR) {
            return t('admin.editUser.role.instructor')
        }

        if (roleName === UserRoles.USER) {
            return t('admin.editUser.role.user')
        }

        return t('admin.editUser.role.unknown')
    }, [profile, t])

    return (
        <Wrapper>
            <Details>
                <Role>
                    <span>{t('admin.editUser.role.roleLabel')}:</span> {profileType}
                </Role>
                <UserBasicDetailsNoEdit {...profile} />
            </Details>

            <ChatWithMember id={userId} />
        </Wrapper>
    )
}

const Wrapper = styled.section`
    display: flex;
    align-items: center;

    > img {
        margin: 0;

        margin: 0.5rem;

        width: 1.5rem;
        height: 1.5rem;
    }
`

const Details = styled.div`
    flex-grow: 1;
`

const Role = styled.p`
    font-size: 0.75rem;

    margin: 0;

    > span {
        font-size: 0.75rem;

        font-weight: 700;
    }
`
