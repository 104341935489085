import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type useAddUsersToGroupParams = {
    arg: {
        userIds: ID[]
    }
}

export function useAddUsersToGroup(courseId: ID, groupId: ID) {
    const api = useApi()

    return (_: string, { arg }: useAddUsersToGroupParams) =>
        api.post<any, any>(`/admin/courses/${courseId}/groups/${groupId}/members/add-many`, arg)
}
