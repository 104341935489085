import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type UsePaymentMockResponse = {
    paymentUrl: string
}

type GetPaymentLinkParams = {
    arg: boolean
}

export function useGetPaymentLink(courseGroupId: ID) {
    const api = useApi()

    return (_: string, { arg }: GetPaymentLinkParams) =>
        api.post<any, UsePaymentMockResponse>(`/payments/generate-link`, {
            courseGroupId,
            withUserStatement: arg
        })
}
