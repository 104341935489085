import { useState } from 'react'
import styled from 'styled-components'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import useSWRMutation from 'swr/mutation'
import { useSWRConfig } from 'swr'

import { useUser } from 'features/userProvider/contexts'

import { Error, Loader, UserBasicDetailsNoEdit, UserBasicForm, UserImageWithUpdate } from 'common/components'
import { ProfileDetails } from 'common/types'

import { ProfileFormData, profileFormData } from '../types/profileFormData'

import { useUpdateProfile } from '../api/useUpdateProfile'
import { useUpdateProfilePhoto } from '../api/useUpdateProfilePhoto'

import { UserControls } from './UserControls'

type UserManageableSectionProps = {
    profile: ProfileDetails
}

export function UserManageableSection({ profile }: UserManageableSectionProps) {
    const [edit, setEdit] = useState(false)
    const [updateProfileError, setUpdateProfileError] = useState(false)

    const { updateUserState, user } = useUser()

    const updater = useUpdateProfile()
    const { mutate } = useSWRConfig()

    const { trigger, isMutating } = useSWRMutation('/user-profile/update', updater, {
        onError: () => {
            setUpdateProfileError(true)
        },
        throwOnError: false
    })

    const handleSubmit = (formData: ProfileFormData) => {
        setUpdateProfileError(false)
        if (profile && user) {
            trigger(formData).then(() => {
                updateUserState({
                    ...user,
                    firstName: formData.firstName,
                    lastName: formData.lastName
                })

                mutate(
                    '/user-profile',
                    {
                        ...profile,
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        address: {
                            ...(profile.address || {}),
                            street: formData.street,
                            postalCode: formData.postalCode,
                            city: formData.city
                        },
                        note: formData.note || null
                    },
                    { revalidate: false }
                )

                setEdit(false)
            })
        }
    }

    const formMethods = useForm<ProfileFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(profileFormData),
        defaultValues: {
            firstName: profile?.firstName || '',
            lastName: profile?.lastName || '',
            city: profile?.address?.city || '',
            postalCode: profile?.address?.postalCode || '',
            street: profile?.address?.street || '',
            note: profile?.note || undefined
        }
    })

    const handleEdit = () => setEdit(true)

    const handleCancel = () => {
        formMethods.reset()
        setEdit(false)
        setUpdateProfileError(false)
    }

    const photoUpdater = useUpdateProfilePhoto()

    const photoChangeCallback = (newImageUrl: string) => {
        if (user) {
            updateUserState({
                ...user,
                photo: newImageUrl
            })
        }
    }

    if (isMutating) {
        return <Loader />
    }

    return (
        <>
            <UserControls
                edit={edit}
                onCancel={handleCancel}
                onEdit={handleEdit}
                onSubmit={formMethods.handleSubmit(handleSubmit)}
            />

            <UserImageWithUpdate
                userImageUrl={user?.photo}
                revalidateCacheKey="/profile-photo-change"
                updater={photoUpdater}
                onSuccessCallback={photoChangeCallback}
            />

            {edit ? (
                <FormProvider {...formMethods}>
                    <UserBasicForm />
                </FormProvider>
            ) : (
                <UserBasicDetailsNoEdit {...profile} />
            )}
            {updateProfileError && (
                <StyledError
                    title="profile.form.updateError.title"
                    description="profile.form.updateError.description"
                />
            )}
        </>
    )
}

const StyledError = styled(Error)`
    margin-bottom: 0.875rem;
`
