import styled from 'styled-components'

export const Arrow = styled.div`
    width: 0px;
    height: 0px;

    border-top: 0.4375rem solid transparent;
    border-bottom: 0.4375rem solid transparent;
    border-left: 0.875rem solid ${props => props.theme.colors.blue};

    margin-left: 1rem;
`
