import { NoDataElement } from 'common/components'

import { IndividualChat } from '../types'

import { IndividualChatBox } from './IndividualChatBox'

interface IndividualChatListProps {
    chats: IndividualChat[]
}

export function IndividualChatList({ chats }: IndividualChatListProps) {
    if (!chats?.length) {
        return <NoDataElement title="chats.noIndividualChats" />
    }

    return (
        <>
            {chats.map(({ chatId, ...rest }) => (
                <IndividualChatBox {...rest} key={chatId} chatId={chatId} />
            ))}
        </>
    )
}
