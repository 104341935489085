import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ID } from 'common/types'
import { EditIcon } from 'common/assets/EditIcon'
import { ButtonIcon } from 'common/components'

import { AdminCourseGroup } from '../types/adminCourseGroup'

import { AdminSelectedGroupDetailsEditable } from './AdminSelectedGroupDetailsEditable'
import { AdminSelectedGroupDetailsNoEdit } from './AdminSelectedGroupDetailsNoEdit'

interface AdminSelectedGroupDetailsEditSectionProps extends AdminCourseGroup {
    courseId: ID
}

export function AdminSelectedGroupDetailsEditSection({
    startsAt,
    endsAt,
    maximumMembers,
    voivodeshipName,
    instructorFullName,
    instructorId,
    voivodeshipId,
    participantsCount,
    courseId,
    id
}: AdminSelectedGroupDetailsEditSectionProps) {
    const [edit, setEdit] = useState(false)

    const { t } = useTranslation()

    const handleEdit = () => setEdit(prev => !prev)

    useEffect(() => {
        setEdit(false)
    }, [id])

    return (
        <Wrapper border={edit}>
            <Title>
                {t('admin.courseDetails.groupManage.title')}

                {!edit && (
                    <ButtonIcon onClick={handleEdit} aria-label="Edytuj grupę">
                        <EditIcon />
                    </ButtonIcon>
                )}
            </Title>

            {edit ? (
                <AdminSelectedGroupDetailsEditable
                    closeEdit={handleEdit}
                    participantsCount={participantsCount}
                    voivodeshipId={voivodeshipId}
                    instructorId={instructorId}
                    startsAt={startsAt}
                    endsAt={endsAt}
                    maximumMembers={maximumMembers}
                    courseId={courseId}
                    groupId={id}
                />
            ) : (
                <AdminSelectedGroupDetailsNoEdit
                    endsAt={endsAt}
                    instructorFullName={instructorFullName}
                    maximumMembers={maximumMembers}
                    startsAt={startsAt}
                    voivodeshipName={voivodeshipName}
                />
            )}
        </Wrapper>
    )
}

const Wrapper = styled.section<{ border: boolean }>`
    margin-bottom: 0.75rem;

    ${props =>
        props.border &&
        `
            border-bottom: 1px solid ${props.theme.colors.border};
    `}
`

const Title = styled.h6`
    font-weight: 700;

    font-size: 0.875rem;

    margin: 0 0 0.75rem 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
`
