import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ComponentType, useState } from 'react'

import { ID } from 'common/types'

import { PlusIcon } from '../assets/PlusIcon'

import { InstructorManageSectionsWrapper } from './InstructorManageSectionsWrapper'
import { UserPropertiesElementWrapper } from './UserPropertiesElementWrapper'
import { InstructorPropertiesElement } from './InstructorPropertiesElement'
import { ButtonIcon } from 'common/components/ButtonIcon'

type InstructorPropertiesManageProps<EntityType> = {
    elements: EntityType[]
    userId: ID
    label: string
    editComponent: ComponentType<EntityType>
    noEditComponent: ComponentType<EntityType>
    createEmptyState: EntityType
}

export function InstructorPropertiesManage<EntityType extends { id: ID }>({
    elements,
    userId,
    label,
    editComponent: EditComponent,
    noEditComponent,
    createEmptyState
}: InstructorPropertiesManageProps<EntityType>) {
    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleEditOn = () => setEdit(true)
    const handleEditOff = () => setEdit(false)

    return (
        <InstructorManageSectionsWrapper>
            <h6>
                {t(label)}
                {!edit && (
                    <ButtonIcon className="edit" onClick={handleEditOn} aria-label="Dodaj nowy">
                        <PlusIcon />
                    </ButtonIcon>
                )}
            </h6>
            {!!elements.length && (
                <List>
                    {elements.map(singleElement => (
                        <InstructorPropertiesElement<EntityType>
                            key={singleElement.id}
                            element={singleElement}
                            userId={userId}
                            editComponent={EditComponent}
                            noEditComponent={noEditComponent}
                        />
                    ))}
                </List>
            )}
            {edit && (
                <StyledNewElementWrapper>
                    <EditComponent {...createEmptyState} userId={userId} turnOffEdit={handleEditOff} isCreate />
                </StyledNewElementWrapper>
            )}
        </InstructorManageSectionsWrapper>
    )
}

const List = styled.ul`
    list-style: none;

    margin: 0.75rem 0 0 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const StyledNewElementWrapper = styled(UserPropertiesElementWrapper)`
    margin-top: 0.75rem;
`
