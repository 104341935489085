export function getAddExternalIdentifiersError(errorMessage: string) {
    let translatedError = ''

    if (errorMessage === 'The numbers field format is invalid.') {
        translatedError = 'admin.externalIdentifiers.submitError.invalid'
    } else {
        translatedError = 'admin.externalIdentifiers.submitError.other'
    }

    return translatedError
}
