import { z } from 'zod'

export const addGroupFormData = z.object({
    courseId: z.number({
        required_error: 'addGroup.fields.course.requiredError',
        invalid_type_error: 'addGroup.fields.course.requiredError'
    }),
    instructorId: z.number({
        required_error: 'addGroup.fields.instructor.requiredError',
        invalid_type_error: 'addGroup.fields.instructor.requiredError'
    }),
    voivodeshipId: z.number({
        required_error: 'addGroup.fields.voivodeship.requiredError',
        invalid_type_error: 'addGroup.fields.voivodeship.requiredError'
    }),
    maximumMembers: z
        .number({
            required_error: 'addGroup.fields.maximumMembers.requiredError',
            invalid_type_error: 'addGroup.fields.maximumMembers.requiredError'
        })
        .min(1, { message: 'addGroup.fields.maximumMembers.toSmall' }),

    dateRange: z.object(
        {
            from: z.date({
                required_error: 'addGroup.fields.dateRange.from.requiredError',
                invalid_type_error: 'addGroup.fields.dateRange.from.requiredError'
            }),
            to: z.date({
                required_error: 'addGroup.fields.dateRange.to.requiredError',
                invalid_type_error: 'addGroup.fields.dateRange.to.requiredError'
            })
        },
        {
            required_error: 'addGroup.fields.dateRange.requiredError'
        }
    )
})

export type AddGroupFormData = z.infer<typeof addGroupFormData>
