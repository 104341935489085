import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { ReactNode, useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { AxiosError } from 'axios'

import { Button, ErrorModal, FormTextField } from 'common/components'
import { ID } from 'common/types'
import { cacheKeys } from 'common/constants'

import { PushMessageHeader } from './PushMessageHeader'
import { pushMessageErrorsMessages } from '../constants/pushMessageErrorsMessages'
import { pushMessageFormData, PushMessageFormData } from '../types/pushMessageFormData'
import { useSendPushMessage } from '../api/useSendPushMessage'

type PushMessageFormProps = {
    courseId: ID
    groupId: ID
    isAdmin?: boolean
    closeEdit: () => void
}

export function PushMessageForm({ courseId, groupId, isAdmin, closeEdit }: PushMessageFormProps) {
    const { t } = useTranslation()

    const [errorModal, setErrorModal] = useState<string | ReactNode>('')

    const sendPush = useSendPushMessage(courseId, groupId, isAdmin)

    const courseGroupsKey = cacheKeys.adminCourseGroups(courseId)

    const formMethods = useForm<PushMessageFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(pushMessageFormData),
        defaultValues: {
            message: ''
        }
    })

    const validatePushMessageErrors = (mainMessage: string) => {
        const availableErrorList = [pushMessageErrorsMessages.commonError]

        const error = availableErrorList.find(singleAvailableError =>
            mainMessage.startsWith(singleAvailableError.message)
        )

        return error?.translationKey || false
    }

    const { trigger, isMutating } = useSWRMutation(courseGroupsKey, sendPush, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string; errors: Record<string, string> }>) => {
            if (!error.response) {
                return setErrorModal(t('pushMessage.errorModal.title'))
            }

            const { status, data } = error.response

            if (status === 422) {
                const groupErrorTranslationKey = validatePushMessageErrors(data.message)

                if (groupErrorTranslationKey) {
                    return setErrorModal(t(groupErrorTranslationKey))
                }
            }

            setErrorModal(t('pushMessage.errorModal.title'))
        },
        onSuccess: () => {
            closeEdit()
        }
    })

    const handleSubmit = ({ message }: PushMessageFormData) => {
        trigger({ message })
    }

    const handleCancel = () => closeEdit()

    const closeErrorModal = () => setErrorModal(false)

    const hasFormErrors = Boolean(Object.values(formMethods.formState.errors).length)

    return (
        <>
            <Wrapper>
                <FormProvider {...formMethods}>
                    <PushMessageHeader />
                    <Form onSubmit={formMethods.handleSubmit(handleSubmit)}>
                        <FormTextField
                            name="message"
                            placeholder={t('pushMessage.form.field.message.placeholder') as string}
                        />

                        <ButtonsWrapper>
                            <Button isSmall secondary type="button" onClick={handleCancel}>
                                {t('pushMessage.cancel')}
                            </Button>
                            <Button isSmall disabled={hasFormErrors || isMutating} type="submit">
                                {t('pushMessage.confirm')}
                            </Button>
                        </ButtonsWrapper>
                    </Form>
                </FormProvider>
            </Wrapper>
            <ErrorModal
                confirmLabel={t('pushMessage.errorModal.confirm')}
                title={t('pushMessage.errorModal.title')}
                description={errorModal}
                isOpen={!!errorModal}
                onClose={closeErrorModal}
                onConfirm={closeErrorModal}
            />
        </>
    )
}

const Wrapper = styled.div`
    padding-bottom: 0.75rem;

    border-bottom: 1px solid ${props => props.theme.colors.border};
`

const Form = styled.form``

const ButtonsWrapper = styled.div`
    display: flex;

    margin: 0.75rem 0 0;

    gap: 0.75rem;

    > button {
        width: 100%;
    }
`
