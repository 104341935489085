import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AppRoutes } from 'common/routing/routes'
import { Error, Loader, NoDataElement, CourseGroupsList } from 'common/components'
import { usePageName } from 'common/hooks'
import { ID } from 'common/types'

import {
    CourseGroup,
    GroupElement,
    SelectedGroupCourseDetails,
    useGetCourseGroups
} from 'features/instructorCourseDetail'

export function InstructorCourseDetails() {
    usePageName('pageHeaders.instructorCourse')

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { courseId, groupId } = useParams<{ courseId: string; groupId: string }>()

    const [selectedGroup, setSelectedGroup] = useState<ID>(0)

    const getCourseGroups = useGetCourseGroups(Number(courseId))

    /**
     * redirect to courses list when there is no course id or its not a number
     */
    useEffect(() => {
        if (!courseId || isNaN(Number(courseId))) {
            navigate(AppRoutes.InstructorCoursesPage.getPath())
        }
    }, [courseId, navigate])

    const { data, error, isLoading } = useSWR(
        !isNaN(Number(courseId)) ? `/session/instructor/courses/${courseId}/groups` : null,
        getCourseGroups,
        {
            onSuccess: response => {
                if (groupId) {
                    const groupToSet = response.find(group => group.id === Number(groupId))
                    if (groupToSet) {
                        return setSelectedGroup(groupToSet.id)
                    }
                }

                setSelectedGroup(response[0]?.id || 0)
            }
        }
    )

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="instructorCourseDetails.getCourseGroupError.title"
                description="instructorCourseDetails.getCourseGroupError.details"
            />
        )
    }

    if (!data?.length) {
        return (
            <NoDataElement
                title="instructorCourseDetails.noGroups.title"
                description={
                    <RedirectToCoursesList to={AppRoutes.InstructorCoursesPage.getPath()}>
                        {t('instructorCourseDetails.noGroups.description')}
                    </RedirectToCoursesList>
                }
            />
        )
    }

    return (
        <>
            <CourseGroupsList<CourseGroup>
                listTitle="instructorCourseDetails.list.listLabel"
                groups={data || []}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                Component={GroupElement}
            />

            {!!selectedGroup && (
                <SelectedGroupCourseDetails courseId={Number(courseId)} selectedGroup={selectedGroup} />
            )}
        </>
    )
}

const RedirectToCoursesList = styled(Link)`
    margin: 0.5rem 0px 0px;
    font-size: 0.75rem;
`
