import { useApi } from 'common/hooks'
import { CoursesStatuses, InstructorAdminCourse } from 'common/types'

export function useGetAdminCourses() {
    const api = useApi()

    return () =>
        api.get<any, InstructorAdminCourse[]>(`/admin/courses/collapsed`).then(response => ({
            past: response.filter(elem => elem.statusName === CoursesStatuses.PAST),
            active: response.filter(elem => elem.statusName === CoursesStatuses.ACTIVE),
            future: response.filter(elem => elem.statusName === CoursesStatuses.FUTURE)
        }))
}
