import { useController } from 'react-hook-form'
import { useMemo } from 'react'

import { DateRange } from 'common/types'

import { CalendarPicker } from '../CalendarPicker'

import { FormFieldWrapper } from './FormFieldWrapper'

interface FormCalendarFieldProps {
    name: string
    label?: string
}

type Error = {
    message?: string
    from?: {
        message: string
    }
    to?: {
        message: string
    }
}

export function FormCalendarField({ name, label }: FormCalendarFieldProps) {
    const {
        field: { onChange, value },
        fieldState: { error }
    } = useController({
        name
    })

    const handleChange = (newValue: DateRange) => {
        onChange(newValue)
    }

    const errorMessage = useMemo(() => {
        if (!error) {
            return undefined
        }

        if (error.message) {
            return error.message
        }

        return (error as Error).from?.message || (error as Error).to?.message
    }, [error])

    return (
        <FormFieldWrapper error={errorMessage} label={label} name={name}>
            <CalendarPicker onChange={handleChange} value={value} />
        </FormFieldWrapper>
    )
}
