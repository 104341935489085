import { AxiosResponse } from 'axios'

import { useApi } from 'common/hooks'
import { ID } from 'common/types'

export function useGetStatistics(courseId: ID, courseGroupId?: ID) {
    const api = useApi('v1', true)

    let payload: { courseId: ID; courseGroupId?: ID } = { courseId }

    if (courseGroupId) {
        payload = {
            ...payload,
            courseGroupId
        }
    }

    return () =>
        api.post<any, AxiosResponse<Blob>>(`/admin/courses/statistics`, payload, {
            responseType: 'blob'
        })
}
