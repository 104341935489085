import { useApi } from 'common/hooks'
import { SearchUser } from 'common/types'

export const useGetAdminUserSearch = () => {
    const api = useApi()

    return (search: string) => {
        const query = new URLSearchParams()

        query.set(search.includes('@') ? 'likeEmail' : 'likeFullName', search)

        return api.get<any, SearchUser[]>(`/admin/users/search?${query.toString()}`)
    }
}
