import { ChangeEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'

import { convertToMB } from 'common/utils/convertToMB'
import { Button, ErrorModal } from 'common/components'
import { ID } from 'common/types'
import { useUploadNewAttachment } from 'common/api'

const MAX_FILE_SIZE_IN_MB = 8

type UploadNewAttachmentProps = {
    groupId: ID
    courseId: ID
    revalidateKey: string
    isAdmin: boolean
}

export function UploadNewAttachment({ courseId, groupId, revalidateKey, isAdmin }: UploadNewAttachmentProps) {
    const { t } = useTranslation()
    const uploader = useUploadNewAttachment(courseId, groupId, isAdmin)

    const [error, setError] = useState('')

    const inputRef = useRef<null | HTMLInputElement>(null)

    const handleUploadButtonClick = () => inputRef.current?.click()
    const handleErrorModalClose = () => setError('')

    const { trigger, isMutating } = useSWRMutation(revalidateKey, uploader, {
        onError: () => {
            setError(t('attachmentsManage.editAttachments.uploadErrorModal.otherError') as string)
        },
        throwOnError: false
    })

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.length) {
            return
        }

        const file = e.target.files[0]

        if (convertToMB(file.size) > MAX_FILE_SIZE_IN_MB) {
            return setError(
                t('attachmentsManage.editAttachments.uploadErrorModal.toBig', {
                    size: MAX_FILE_SIZE_IN_MB
                }) as string
            )
        }

        trigger(file)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }

    return (
        <>
            <AddFileButton isSmall secondary onClick={handleUploadButtonClick} disabled={isMutating}>
                {t('attachmentsManage.editAttachments.addNewFileLabel')}
            </AddFileButton>
            <FileInput type="file" ref={inputRef} accept="image/png, image/jpeg" onChange={handleFileChange} />
            <ErrorModal
                confirmLabel={t('attachmentsManage.editAttachments.uploadErrorModal.confirmLabel')}
                description={error}
                isOpen={!!error}
                onClose={handleErrorModalClose}
                onConfirm={handleErrorModalClose}
                title={t('attachmentsManage.editAttachments.uploadErrorModal.title')}
            />
        </>
    )
}

const AddFileButton = styled(Button)`
    margin-bottom: 0.75rem;
`

const FileInput = styled.input`
    display: none;
`
