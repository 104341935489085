import { useTranslation } from 'react-i18next'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { MembersFormData } from '../types/membersFormData'

type AdminAddNewUsersHeaderProps = {
    maximumUsersCount: number
    currentUsersCount: number
}

export function AdminAddNewUsersHeader({ maximumUsersCount, currentUsersCount }: AdminAddNewUsersHeaderProps) {
    const { t } = useTranslation()

    const selectedNewMembers = useWatch<MembersFormData, 'selectedMembers'>({
        name: 'selectedMembers'
    })

    return (
        <Label>
            {t('admin.courseDetails.addNewUsers.addNewUsersLabel')}{' '}
            <Counter>
                {currentUsersCount + selectedNewMembers.length}/{maximumUsersCount}
            </Counter>
        </Label>
    )
}

const Label = styled.h6`
    font-size: 0.875rem;
    font-weight: 700;

    margin: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;

    margin-bottom: 0.75rem;
`

const Counter = styled.span`
    font-size: 0.875rem;
    font-weight: 700;
`
