import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { usePageName } from 'common/hooks'
import { Button, Error, InstructorAdminCoursesListByStatus, Loader } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

import { useGetInstructorCourses } from 'features/instructorCoursesList'

export function InstructorCoursesPage() {
    usePageName('pageHeaders.instructorCourses')

    const { t } = useTranslation()

    const fetcher = useGetInstructorCourses()

    const { data, error, isLoading } = useSWR(`session/instructor/courses/collapsed`, fetcher)

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="instructorCoursesList.getCoursesError.title"
                description="instructorCoursesList.getCoursesError.description"
            />
        )
    }

    return (
        <>
            <ListWrapper>
                <InstructorAdminCoursesListByStatus
                    coursesList={data?.active || []}
                    noDataLabel="instructorCoursesList.noDataLabels.active"
                    title="instructorCoursesList.groupsLabel.active"
                />
                <InstructorAdminCoursesListByStatus
                    coursesList={data?.future || []}
                    noDataLabel="instructorCoursesList.noDataLabels.future"
                    title="instructorCoursesList.groupsLabel.future"
                />
                <InstructorAdminCoursesListByStatus
                    coursesList={data?.past || []}
                    noDataLabel="instructorCoursesList.noDataLabels.past"
                    title="instructorCoursesList.groupsLabel.past"
                />
            </ListWrapper>

            <StyledSearchResultBackToSearchWrapper>
                <Link to={AppRoutes.InstructorAddUserGroup.getPath()}>
                    <Button isSmall>{t('instructorCoursesList.createNewGroup')}</Button>
                </Link>
            </StyledSearchResultBackToSearchWrapper>
        </>
    )
}

const ListWrapper = styled.div`
    margin-bottom: 3rem;
`

const StyledSearchResultBackToSearchWrapper = styled.div`
    position: fixed;
    bottom: 5rem;
    width: calc(100vw - 3rem);

    button {
        width: 100%;
    }
`
