import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'

import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'
import { useGetCourses } from 'common/api'
import { Loader, Error, SearchResultBackToSearch, SearchCoursesList } from 'common/components'
import { SearchCourse } from 'common/types'

import {
    adminGetCoursesQuerySchema,
    AdminGetCoursesQueryData,
    AdminSearchCourseListElement
} from 'features/administratorFeatures/searchResults'

export function AdminSearchResult() {
    usePageName('pageHeaders.search')

    const { t } = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const fetchCourses = useGetCourses()

    const [qsData, setQsData] = useState<AdminGetCoursesQueryData | null>(null)

    const { data, error, isLoading } = useSWR(qsData ? `/search/course/groups?${JSON.stringify(qsData)}` : null, () =>
        fetchCourses({
            courseId: qsData?.course,
            voivodeshipId: qsData?.region,
            startsAtFrom: qsData?.startsAtFrom || '',
            startsAtTo: qsData?.startsAtTo || '',
            instructorId: qsData?.instructor
        })
    )

    const routeState = useMemo(
        () => ({
            region: qsData?.region ? Number(qsData.region) : undefined,
            course: qsData?.course ? Number(qsData.course) : undefined,
            instructor: qsData?.instructor ? Number(qsData.instructor) : undefined,
            dateRange: {
                from: qsData?.startsAtFrom ? new Date(qsData.startsAtFrom) : undefined,
                to: qsData?.startsAtTo ? new Date(qsData.startsAtTo) : undefined
            }
        }),
        [qsData]
    )

    useEffect(() => {
        const parseResult = adminGetCoursesQuerySchema.safeParse(Object.fromEntries(searchParams.entries()))

        if (parseResult.success) {
            setQsData(parseResult.data)
        } else {
            navigate(AppRoutes.AdminSearchPage.getPath())
        }
    }, [searchParams, navigate])

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error title="admin.searchResults.getError.title" description="admin.searchResults.getError.description" />
        )
    }

    return (
        <>
            <SearchCoursesList<SearchCourse>
                Component={AdminSearchCourseListElement}
                coursesList={data || []}
                noDataTitle="admin.searchResults.noData.title"
                noDataDescription="admin.searchResults.noData.description"
            />
            <SearchResultBackToSearch
                url={AppRoutes.AdminSearchPage.getPath()}
                buttonLabel={t('admin.searchResults.backToSearch')}
                routeState={routeState}
            />
        </>
    )
}
