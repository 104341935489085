import { useTranslation } from 'react-i18next'

import chats from 'common/assets/chats.svg'
import { formatDateTime } from 'common/utils/date'
import { AppRoutes } from 'common/routing/routes'
import { UserThumbnail } from 'common/components'

import { IndividualChat } from '../types'

import { ChatContentWrapper } from './ChatContentWrapper'
import { ChatTitle } from './ChatTitle'
import { ChatDetailsRow } from './ChatDetailsRow'
import { ChatMessageIcon } from './ChatMessageIcon'
import { ChatLinkWrapper } from './ChatLinkWrapper'

export function IndividualChatBox({ chatId, recipientFullName, latestMessageSentAt, photoHash }: IndividualChat) {
    const { t } = useTranslation()

    return (
        <li>
            <ChatLinkWrapper to={AppRoutes.ChatPage.getPath({ chatId })} aria-label="Przejdź do chatu">
                <UserThumbnail isGroup={false} photoHash={photoHash} />
                <ChatContentWrapper>
                    <ChatTitle>{recipientFullName}</ChatTitle>
                    {latestMessageSentAt && (
                        <ChatDetailsRow>
                            {t('chats.lastMessageLabel')} {formatDateTime(latestMessageSentAt)}
                        </ChatDetailsRow>
                    )}
                </ChatContentWrapper>

                <ChatMessageIcon src={chats} />
            </ChatLinkWrapper>
        </li>
    )
}
