import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import styled from 'styled-components'

import { ID } from 'common/types'
import { Arrow, CourseBoxWrapper, CourseTitle } from 'common/components'
import { formatDateTime } from 'common/utils/date'
import { AppRoutes } from 'common/routing/routes'

export interface ActiveCourseProps {
    id: ID
    courseName: string
    nextMeetDate?: string
    nextExamDate?: string
    unreadMessages?: string[]
}

export function ActiveCourse({ id, courseName, unreadMessages, nextExamDate, nextMeetDate }: ActiveCourseProps) {
    const { t } = useTranslation()

    const lastMessage = useMemo(() => {
        if (!Array.isArray(unreadMessages) || !unreadMessages.length) {
            return t('home.activeCourse.noNewMessages')
        }

        return unreadMessages[0]
    }, [unreadMessages, t])

    return (
        <StyledLink
            to={AppRoutes.CourseDetails.getPath({ courseId: id })}
            arial-label={`Przejdź do kursu ${courseName}`}
        >
            <CourseBoxWrapper>
                <Header>
                    <CourseTitle>{courseName}</CourseTitle>
                    <Arrow />
                </Header>
                <Content>
                    {!!nextMeetDate && (
                        <ContentItem>
                            {t('home.activeCourse.nextMeetingTermLabel')} <span>{formatDateTime(nextMeetDate)}</span>
                        </ContentItem>
                    )}

                    {!!nextExamDate && (
                        <ContentItem>
                            {t('home.activeCourse.nextExamTerm')} <span>{formatDateTime(nextExamDate)}</span>
                        </ContentItem>
                    )}

                    <MessagesItem>{lastMessage}</MessagesItem>
                </Content>
            </CourseBoxWrapper>
        </StyledLink>
    )
}

const StyledLink = styled(Link)`
    text-decoration: none;
`

const Header = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Content = styled.section`
    width: 100%;

    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`

const ContentItem = styled.p`
    color: ${props => props.theme.colors.darkBlue};

    margin: 0;

    font-size: 0.75rem;

    > span {
        font-weight: bold;
        font-size: inherit;

        white-space: nowrap;
    }
`

const MessagesItem = styled(ContentItem)`
    overflow: hidden;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`
