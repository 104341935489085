import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FormTextArea, FormTextField } from 'common/components'

type UserBasicFormProps = {
    useNoteField?: boolean
    revalidateAddressFields?: () => void
}

export function UserBasicForm({ useNoteField = true, revalidateAddressFields }: UserBasicFormProps) {
    const { t } = useTranslation()

    return (
        <>
            <NameAndSurnameRow>
                <FormTextField
                    name="firstName"
                    label={t('basicProfileForm.field.firstName.label') as string}
                    placeholder={t('basicProfileForm.field.firstName.placeholder') as string}
                />
                <FormTextField
                    name="lastName"
                    label={t('basicProfileForm.field.lastName.label') as string}
                    placeholder={t('basicProfileForm.field.lastName.placeholder') as string}
                />
            </NameAndSurnameRow>
            <FormTextField
                onKeyUp={revalidateAddressFields}
                name="street"
                label={t('basicProfileForm.field.street.label') as string}
                placeholder={t('basicProfileForm.field.street.placeholder') as string}
            />
            <PostalCodeAndCityRow>
                <FormTextField
                    onKeyUp={revalidateAddressFields}
                    name="postalCode"
                    label={t('basicProfileForm.field.postalCode.label') as string}
                    placeholder={t('basicProfileForm.field.postalCode.placeholder') as string}
                />
                <FormTextField
                    onKeyUp={revalidateAddressFields}
                    name="city"
                    label={t('basicProfileForm.field.city.label') as string}
                    placeholder={t('basicProfileForm.field.city.placeholder') as string}
                />
            </PostalCodeAndCityRow>
            {useNoteField && (
                <FormTextArea
                    name="note"
                    label="basicProfileForm.field.note.label"
                    placeholder={t('basicProfileForm.field.note.placeholder') as string}
                    rows={5}
                />
            )}
        </>
    )
}

const NameAndSurnameRow = styled.div`
    display: flex;
    gap: 0.75rem;
`

const PostalCodeAndCityRow = styled.div`
    display: flex;
    gap: 0.75rem;

    & > div:first-of-type {
        width: 9.75rem;
    }
`
