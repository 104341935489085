import styled from 'styled-components'

import { UserThumbnail } from 'common/components'

type ChatHeaderProps = {
    isGroup: boolean
    chatName: string
    photoHash?: string
}

export function ChatHeader({ chatName, isGroup, photoHash }: ChatHeaderProps) {
    return (
        <Wrapper>
            <UserThumbnail isGroup={isGroup} photoHash={photoHash} />
            <Title>{chatName}</Title>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: 0.75rem;
`

const Title = styled.h2`
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;

    margin: 0;
    padding-left: 0.75rem;
`
