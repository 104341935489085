import { useController } from 'react-hook-form'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/components'
import { ID } from 'common/types'

import { MembersFormData } from '../types/membersFormData'

export function AdminSelectedNewUsers() {
    const { t } = useTranslation()

    const {
        field: { value, onChange }
    } = useController<MembersFormData, 'selectedMembers'>({
        name: 'selectedMembers'
    })

    if (!value.length) {
        return null
    }

    const handleDelete = (userId: ID) => () => {
        const mewSelectedMembers = value.filter(singleMember => singleMember.value !== userId)

        onChange(mewSelectedMembers, { shouldValidate: true })
    }

    return (
        <Wrapper>
            {value.map(singleMember => (
                <Item key={singleMember.value}>
                    <ItemTitle>{singleMember.label}</ItemTitle>
                    <Button isSmall onClick={handleDelete(singleMember.value)} type="button">
                        {t('admin.courseDetails.addNewUsers.delete')}
                    </Button>
                </Item>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;

    gap: 0.75rem;

    padding: 0;
    margin: 0 0 0.75rem 0;
`

const Item = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
`

const ItemTitle = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
`
