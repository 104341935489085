import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TickIcon } from 'common/assets/TickIcon'
import { CrossIcon } from 'common/assets/CrossIcon'

import { ExclamationIcon } from 'common/assets/ExclamationIcon'
import { cacheKeys } from 'common/constants'
import { CourseCompletionStatus, CourseMember, ID, UserRoles } from 'common/types'
import { ErrorModal, Menu, UserThumbnail, ChatWithMember, ButtonIcon } from 'common/components'
import { DotsIcon } from 'common/assets/DotsIcon'
import { useDeleteMember, useSetMemberCourseStatus } from 'common/api'
import { AppRoutes } from 'common/routing/routes'

import { MemberName } from 'features/courseMembersList'

import { CourseMemberListElementControls } from './CourseMemberListElementControls'

interface CourseMemberListElementWithControlsProps extends CourseMember {
    selectedGroup: ID
    courseId: ID
    canManageMemberStatuses: boolean
    isAdmin?: boolean
    cacheKeyToRevalidate: string
}

export function CourseMemberListElementWithControls({
    fullName,
    id,
    roleName,
    photoHash,
    completionStatusName,
    courseId,
    selectedGroup,
    canManageMemberStatuses,
    cacheKeyToRevalidate,
    isAdmin
}: CourseMemberListElementWithControlsProps) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [modalStatus, setModalStatus] = useState<false | string>(false)
    const { mutate } = useSWRConfig()

    const cacheKeyForAdminGroups = cacheKeys.adminCourseGroups(courseId)

    const updater = useSetMemberCourseStatus(courseId, selectedGroup, id, isAdmin)
    const deleter = useDeleteMember(courseId, selectedGroup, id, isAdmin)

    const { trigger } = useSWRMutation(cacheKeyToRevalidate, updater, {
        onError: error => {
            const errorStatus: number = error?.response?.status

            const errorKey =
                errorStatus === 400 ? 'memberManage.modalError.tooLateError' : 'memberManage.modalError.otherError'

            setModalStatus(t(errorKey) as string)
        },
        throwOnError: false
    })

    const { trigger: deleteUser } = useSWRMutation(cacheKeyToRevalidate, deleter, {
        onError: () => {
            setModalStatus(t('memberManage.modalError.deleteError') as string)
        },
        onSuccess: () => {
            mutate(cacheKeyForAdminGroups)
        },
        throwOnError: false
    })

    const resultIcon = useMemo(() => {
        if (completionStatusName === CourseCompletionStatus.PENDING || roleName === UserRoles.INSTRUCTOR) {
            return false
        }

        if (completionStatusName === CourseCompletionStatus.SUCCESS) {
            return <TickIcon />
        }

        if (completionStatusName === CourseCompletionStatus.WAITING_FOR_APPROVAL) {
            return <ExclamationIcon />
        }

        return <CrossIcon />
    }, [completionStatusName, roleName])

    const canDisplayMenu = useMemo(() => {
        if (isAdmin) {
            return roleName !== UserRoles.INSTRUCTOR
        }

        return canManageMemberStatuses
    }, [canManageMemberStatuses, isAdmin, completionStatusName, roleName])

    const setMemberCourseStatus = (status: CourseCompletionStatus) => {
        trigger(status)
    }

    const handleDelete = () => deleteUser()

    const handleModalClose = () => setModalStatus(false)

    const handleRedirectToUserEdit = () => {
        if (!isAdmin) {
            return
        }

        navigate(AppRoutes.AdminUserEditPage.getPath({ userId: id }))
    }

    return (
        <>
            <Member key={id}>
                <ImageWrapper onClick={handleRedirectToUserEdit}>
                    <UserThumbnail
                        photoHash={photoHash}
                        isGroup={false}
                        isInstructor={roleName === UserRoles.INSTRUCTOR}
                    />
                    {resultIcon && <ResultImage>{resultIcon}</ResultImage>}
                </ImageWrapper>
                <MemberName fullName={fullName} roleName={roleName} onClick={handleRedirectToUserEdit} />
                <IconSection>
                    <ChatWithMember id={id} />
                    {canDisplayMenu && (
                        <Menu
                            arrow
                            menuButton={
                                <ButtonIcon className="menu-button" aria-label="Otwórz menu">
                                    <DotsIcon />
                                </ButtonIcon>
                            }
                            transition
                            direction="bottom"
                            align="end"
                        >
                            <CourseMemberListElementControls
                                isAdmin={!!isAdmin}
                                userName={fullName}
                                userCompletionStatusName={completionStatusName}
                                handleDelete={handleDelete}
                                setMemberCourseStatus={setMemberCourseStatus}
                            />
                        </Menu>
                    )}
                </IconSection>
            </Member>
            <ErrorModal
                confirmLabel={t('memberManage.modalError.confirmLabel')}
                description={
                    modalStatus === 'tooLate'
                        ? t('memberManage.modalError.tooLateError')
                        : t('memberManage.modalError.otherError')
                }
                isOpen={!!modalStatus}
                onClose={handleModalClose}
                onConfirm={handleModalClose}
                title={t('memberManage.modalError.errorTitle')}
            />
        </>
    )
}

const Member = styled.li`
    display: flex;
    align-items: center;
`

const ImageWrapper = styled.div`
    position: relative;
`

const ResultImage = styled.div`
    position: absolute;
    right: -0.1875rem;
    bottom: 0rem;
    background: white;
    line-height: 0;
    border-radius: 50%;

    svg {
        width: 0.9375rem;
        height: 0.9375rem;
    }
`

const IconSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .menu-button {
        margin: 0;
    }
`
