import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ProfileImageIcon } from '../assets/ProfileImageIcon'

type ProfileImageProps = {
    userImage?: string
}
export function ProfileImage({ userImage }: ProfileImageProps) {
    const { t } = useTranslation()

    return (
        <ImageWrapper>
            {userImage ? (
                // TODO: wyniesc to tlumaczenie
                <UserProfilePhoto src={userImage} alt={String(t('profile.imageSection.imageAlt'))} />
            ) : (
                <ProfileImageIcon />
            )}
        </ImageWrapper>
    )
}

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 12.5rem;

    background-color: ${props => props.theme.colors.blueBackground};

    cursor: pointer;

    margin-bottom: 0.875rem;
`

const UserProfilePhoto = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
