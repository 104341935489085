import { z } from 'zod'

import { emailRegex } from 'common/constants'

export const loginFormData = z.object({
    email: z
        .string({ required_error: 'login.fields.login.requireError' })
        .regex(emailRegex, 'login.fields.login.invalidEmail')
        .transform(val => val.trim()),
    password: z.string({ required_error: 'login.fields.password.requireError' }).transform(val => val.trim())
})

export type LoginFormData = z.infer<typeof loginFormData>
