import { useApi } from 'common/hooks'
import { ID } from 'common/types'

export type GetActiveCoursesResponse = {
    endsAt: string
    id: ID
    nextExamAt: string
    nextMeetingAt: string
    startsAt: string
    unreadMessages: string[]
    unreadMessagesCount: number
    courseName: string
}[]

export function useGetActiveCourses() {
    const api = useApi()
    return () => api.get<any, GetActiveCoursesResponse>('/active-courses')
}
