import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export function TextInput(props: TextInputProps) {
    return <StyledInput {...props} />
}

const StyledInput = styled.input`
    border: 1px solid ${props => props.theme.colors.border};

    padding: 0 0.625rem;

    width: 100%;
    height: 2.375rem;

    font-size: 0.75rem;

    color: ${props => props.theme.colors.darkBlue};

    outline: unset;

    &::placeholder {
        color: ${props => props.theme.colors.darkBlue};
    }

    &:focus {
        -webkit-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
        box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
        border: 1px solid ${props => props.theme.colors.borderDark};
    }

    &:hover {
        border: 1px solid ${props => props.theme.colors.borderDark};
    }
`
