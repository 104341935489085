import { ReactElement } from 'react'
import styled from 'styled-components'

import { useGetFileOnce } from 'common/hooks'

type UserThumbnailProps = {
    photoHash: string
    fallback: ReactElement
}

export function UserImage({ fallback, photoHash }: UserThumbnailProps) {
    const { error, fileUrl } = useGetFileOnce({
        fileHash: photoHash,
        queryString: '?imageSize=small&withRatioAspect=true'
    })

    if (error || !fileUrl) {
        return fallback
    }

    return <UserImageStyled src={fileUrl} alt={photoHash} />
}

const UserImageStyled = styled.img`
    width: 2.8125rem;
    height: 2.8125rem;

    object-fit: cover;

    border-radius: 50%;

    flex-shrink: 0;
`
