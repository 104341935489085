import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ID } from 'common/types'
import { UserThumbnail, ChatWithMember } from 'common/components'

type ListGroupChatProps = {
    id: ID
}

export function ListGroupChat({ id }: ListGroupChatProps) {
    const { t } = useTranslation()

    return (
        <Member key={id}>
            <UserThumbnail isGroup />
            <Name>{t('common.groupChat')}</Name>
            <ChatWithMember id={id} isGroupChat />
        </Member>
    )
}

const Member = styled.li`
    display: flex;
    align-items: center;
`

const Name = styled.p`
    font-size: 0.75rem;
    font-weight: 700;

    margin: 0 0 0 0.8125rem;

    width: 100%;
`
