import styled from 'styled-components'

export const ValidationError = styled.div`
    flex-direction: column;

    padding: 0.25rem 0 0 0;

    color: ${props => props.theme.colors.errorFont};

    font-size: 0.75rem;
`
