import { FormProvider, useForm } from 'react-hook-form'
import useSWR from 'swr'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { useGetSearchConfiguration } from 'common/api'
import { Loader, Error, ErrorModal } from 'common/components'
import { usePageName } from 'common/hooks'
import { dateFormatForBackend } from 'common/utils/date'
import { AppRoutes } from 'common/routing/routes'

import { AddGroupForm, getAddGroupError } from 'features/addGroupForm'
import {
    AddInstructorGroupFormData,
    addInstructorGroupFormData,
    useCreateInstructorGroup
} from 'features/instructorAddCourseGroup'

export function InstructorAddCourseGroup() {
    usePageName('pageHeaders.courseGroupAdd')

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [submitError, setSubmitError] = useState('')

    const fetcher = useGetSearchConfiguration()
    const creator = useCreateInstructorGroup()

    const { data, error, isLoading } = useSWR('search-configurator', fetcher)

    const formMethods = useForm<AddInstructorGroupFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(addInstructorGroupFormData)
    })

    const { trigger: createGroup, isMutating } = useSWRMutation(`/create-group`, creator, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string }>) => {
            const errorMessage = error.response?.data.message

            const translatedError = t(getAddGroupError(errorMessage || ''))

            setSubmitError(translatedError as string)
        },
        onSuccess: response => {
            navigate(
                AppRoutes.InstructorCourseDetails.getPath({
                    courseId: response.courseId,
                    groupId: response.courseGroupId
                })
            )
        }
    })

    const handleSubmit = (dataForm: AddInstructorGroupFormData) => {
        createGroup({
            courseId: dataForm.courseId,
            maximumMembers: dataForm.maximumMembers,
            voivodeshipId: dataForm.voivodeshipId,
            startsAt: dateFormatForBackend(dataForm.dateRange.from),
            endsAt: dateFormatForBackend(dataForm.dateRange.to)
        })
    }

    const closeError = () => setSubmitError('')

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return (
            <Error
                title="addGroup.getConfiguratorError.title"
                description="addGroup.getConfiguratorError.description"
            />
        )
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <AddGroupForm
                    courses={data?.courses || []}
                    instructors={[]}
                    voivodeships={data?.voivodeships || []}
                    handleSubmit={formMethods.handleSubmit(handleSubmit)}
                    isMutating={isMutating}
                    useInstructorField={false}
                />
            </FormProvider>
            <ErrorModal
                confirmLabel={t('addGroup.submitError.confirm')}
                description={submitError}
                isOpen={!!submitError}
                onClose={closeError}
                onConfirm={closeError}
                title={t('addGroup.submitError.title')}
            />
        </>
    )
}
