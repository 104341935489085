import styled from 'styled-components'
import DatePicker from 'react-date-picker'

export const DateInput = styled(DatePicker)`
    .react-date-picker__wrapper {
        background: ${props => props.theme.colors.white};

        border: 1px solid ${props => props.theme.colors.border};

        padding: 0 0.625rem;

        height: 2.375rem;

        &:focus {
            -webkit-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            -moz-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
            border: 1px solid ${props => props.theme.colors.borderDark};
        }

        &:hover {
            border: 1px solid ${props => props.theme.colors.borderDark};
        }
    }

    .react-date-picker__inputGroup,
    .react-date-picker__inputGroup__leadingZero {
        font-size: 0.75rem;
        color: ${props => props.theme.colors.darkBlue};
    }

    .react-date-picker__inputGroup__input {
        outline: none;

        font-size: 0.75rem;
        color: ${props => props.theme.colors.darkBlue};

        &::placeholder {
            color: ${props => props.theme.colors.darkBlue};
        }

        &:invalid {
            background: ${props => props.theme.colors.white};
        }
    }

    .react-date-picker__clear-button {
        > svg {
            stroke: ${props => props.theme.colors.blue};
        }
    }
`
