import styled from 'styled-components'

export const CourseTitle = styled.h4`
    font-weight: 700;
    font-size: 1rem;

    width: 100%;

    margin: 0;
`
