import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, FormCalendarField, FormSelectField, FormTextField } from 'common/components'
import { SelectOption } from 'common/types'

import { searchCourseFormSchema, SearchCourseFormData } from '../types/searchCourseFormData'

interface CoursesSearchFormProps {
    defaultData: SearchCourseFormData
    voivodeships: SelectOption[]
    courses: SelectOption[]
    onSubmit: (data: SearchCourseFormData) => void
}

export function CoursesSearchForm({ courses, voivodeships, onSubmit, defaultData }: CoursesSearchFormProps) {
    const { t } = useTranslation()
    const formMethods = useForm<SearchCourseFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(searchCourseFormSchema),
        defaultValues: defaultData
    })

    return (
        <FormProvider {...formMethods}>
            <StyledForm onSubmit={formMethods.handleSubmit(onSubmit)}>
                <div>
                    <FormSelectField
                        name="region"
                        options={voivodeships}
                        placeholder={t('search.fields.region.placeholder')}
                        label={t('search.fields.region.label') as string}
                    />
                    <FormSelectField
                        name="course"
                        options={courses}
                        placeholder={t('search.fields.course.placeholder')}
                        label={t('search.fields.course.label') as string}
                    />
                    <FormTextField
                        name="instructor"
                        placeholder={t('search.fields.instructor.placeholder') || ''}
                        label={t('search.fields.instructor.label') as string}
                    />
                    <CalendarInputLabel>{t('search.fields.dateRange.label')}</CalendarInputLabel>
                    <FormCalendarField name="dateRange" />
                </div>

                <StyledSubmitButton type="submit">{t('search.searchLabel')}</StyledSubmitButton>
            </StyledForm>
        </FormProvider>
    )
}

const StyledSubmitButton = styled(Button)`
    width: 100%;
`

const StyledForm = styled.form`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const CalendarInputLabel = styled.div`
    font-size: 0.75rem;

    color: ${props => props.theme.colors.blue};

    padding-left: 0.625rem;
`
