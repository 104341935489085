import { Breakpoint, Breakpoints } from 'common/theme/breakpoint'
import styled from 'styled-components'

export const Button = styled.button<{ disabled?: boolean; isSmall?: boolean; secondary?: boolean }>`
    background-color: ${props => props.theme.colors.blue};

    color: ${props => props.theme.colors.white};

    font-weight: 700;

    padding: 1rem 2rem;

    border: 1px solid ${props => props.theme.colors.blue};
    border-radius: 0.125rem;

    cursor: pointer;

    text-transform: uppercase;

    @media (${Breakpoint.BreakpointsUp(Breakpoints.tablet)}) {
        &:hover {
            background-color: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.blue};

            outline: none;
        }
    }

    &:focus-visible,
    &:active {
        outline: none;

        -webkit-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
        box-shadow: 8px 8px 24px 0px rgba(241, 241, 255, 1);
    }

    ${props =>
        props.secondary &&
        `
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.blue};


        @media (${Breakpoint.BreakpointsUp(Breakpoints.tablet)}) {
            &:hover {
                background-color: ${props.theme.colors.blue};
                color: ${props.theme.colors.white};
            }
        }
    `}

    ${props =>
        props.disabled &&
        `
        background-color: ${props.theme.colors.disabledBackground};
        border: none;

        &&& {
            &:hover,
            &:focus-visible,
            &:active {
                background-color: ${props.theme.colors.disabledBackground};
                border-color: ${props.theme.colors.disabledBackground};
                color: ${props.theme.colors.white};
            }
        }

    `}

    ${props =>
        props.isSmall &&
        `
        padding: 0.38rem 0.625rem;

        font-size: 0.75rem;
    `}
`
