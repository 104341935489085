export function getAddGroupError(errorMessage: string) {
    let translatedError = ''

    if (errorMessage === 'The starts at must not be past.') {
        translatedError = 'addGroup.submitError.pastError'
    } else if (errorMessage === 'Instructor is not available for leading this course.') {
        translatedError = 'addGroup.submitError.busyError'
    } else {
        translatedError = 'addGroup.submitError.other'
    }

    return translatedError
}
