import { Trans } from 'react-i18next'

import { Loader } from 'common/components'

import { StatusWrapper } from './StatusWrapper'
import { StatusTitle } from './StatusTitle'

export function PendingPayment() {
    return (
        <>
            <StatusWrapper>
                <Loader />
                <StatusTitle>
                    <Trans i18nKey="paymentProcess.pendingStatus.label" components={{ 1: <br /> }} />
                </StatusTitle>
            </StatusWrapper>
        </>
    )
}
