import { useApi } from 'common/hooks'
import { ID } from 'common/types'

import { PaymentStatus } from '../types'

type UsePaymentStatusResponse = {
    id: ID
    courseGroupId: ID
    statusName: PaymentStatus
    transactionName: string
    amount: number
}

export function usePaymentStatus() {
    const api = useApi()

    return (paymentId: ID) => api.get<any, UsePaymentStatusResponse>(`/payments/${paymentId}`)
}
