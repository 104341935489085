import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { ErrorModal } from 'common/components'
import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'

import {
    AddExternalIdentifiersForm,
    addExternalIdentifiersFormData,
    AddExternalIdentifiersFormData,
    getAddExternalIdentifiersError,
    useCreateExternalIdentifiers
} from 'features/externalIdentifiers'

export function AdminExternalIdentifiers() {
    usePageName('pageHeaders.externalIdentifiers')

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [submitError, setSubmitError] = useState('')

    const creator = useCreateExternalIdentifiers()

    const formMethods = useForm<AddExternalIdentifiersFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(addExternalIdentifiersFormData)
    })

    const { trigger: createExternalIdentifiers, isMutating } = useSWRMutation(`/add-external-identifiers`, creator, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string }>) => {
            const errorMessage = error.response?.data.message

            const translatedError = t(getAddExternalIdentifiersError(errorMessage || ''))

            setSubmitError(translatedError as string)
        },
        onSuccess: () => {
            navigate(AppRoutes.AdminHome.getPath())
        }
    })

    const handleSubmit = (dataForm: AddExternalIdentifiersFormData) => {
        createExternalIdentifiers({
            numbers: dataForm.numbers
        })
    }

    const closeError = () => setSubmitError('')

    return (
        <>
            <FormProvider {...formMethods}>
                <AddExternalIdentifiersForm
                    handleSubmit={formMethods.handleSubmit(handleSubmit)}
                    isMutating={isMutating}
                />
            </FormProvider>
            <ErrorModal
                confirmLabel={t('admin.externalIdentifiers.submitError.confirm')}
                description={submitError}
                isOpen={!!submitError}
                onClose={closeError}
                onConfirm={closeError}
                title={t('admin.externalIdentifiers.submitError.title')}
            />
        </>
    )
}
