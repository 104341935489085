import useSWR from 'swr'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePageName } from 'common/hooks'
import { dateFormatForBackend } from 'common/utils/date'
import { Error } from 'common/components'
import { useGetSearchConfiguration } from 'common/api'

import { CoursesSearchForm } from '../features/searchForm'

import { Loader } from '../common/components/Loader'
import { AppRoutes } from '../common/routing/routes'

import { SearchCourseFormData } from '../features/searchForm/types/searchCourseFormData'

export function Search() {
    const location = useLocation()

    const { data, error, isLoading } = useSWR('search-configurator', useGetSearchConfiguration())
    usePageName('pageHeaders.search')

    const navigate = useNavigate()

    const handleSubmit = (formData: SearchCourseFormData) => {
        const basePath = AppRoutes.SearchResultsPage.getPath()
        const searchParams = new URLSearchParams()

        Object.entries(formData).forEach(([key, value]) => {
            if (value !== undefined && value !== null && key !== 'dateRange') {
                searchParams.set(key, String(value))
            }
        })

        searchParams.set('startsAtFrom', dateFormatForBackend(formData.dateRange.from))
        searchParams.set('startsAtTo', dateFormatForBackend(formData.dateRange.to))

        navigate(`${basePath}?${searchParams.toString()}`)
    }

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="search.getError.title" description="search.getError.description" />
    }

    return (
        <CoursesSearchForm
            courses={data?.courses || []}
            voivodeships={data?.voivodeships || []}
            onSubmit={handleSubmit}
            defaultData={location.state as SearchCourseFormData}
        />
    )
}
