import styled from 'styled-components'

export const StatusTitle = styled.h3`
    font-weight: 700;
    font-size: 0.75rem;

    margin-top: 1rem;

    width: 75%;
`
