function getDateComponents(date: Date) {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return {
        day,
        month,
        year
    }
}

function convertToDateObject(date: string | Date) {
    return typeof date === 'string' ? new Date(date.replace(' ', 'T')) : date
}

export function formatDate(date: string | Date) {
    const dateToFormat = convertToDateObject(date)

    if (!date || isNaN(dateToFormat.getMilliseconds())) {
        return 'invalid date'
    }

    const { day, month, year } = getDateComponents(dateToFormat)

    return `${day}.${month}.${year}`
}

export function dateFormatForBackend(date: Date) {
    const { day, month, year } = getDateComponents(date)

    return `${year}-${month}-${day}`
}

export function dateTimeFormatForBackend(date: Date) {
    const { day, month, year } = getDateComponents(date)

    return `${year}-${month}-${day} ${formatTime(date, true)}`
}

function formatTime(date: Date, showSeconds: boolean = false) {
    const hours = `${date.getHours()}`.padStart(2, '0')
    const minutes = `${date.getMinutes()}`.padStart(2, '0')
    const seconds = `${date.getSeconds()}`.padStart(2, '0')

    return `${hours}:${minutes}${showSeconds ? `:${seconds}` : ''}`
}

export function formatDateTime(date: string | Date) {
    const dateToFormat = convertToDateObject(date)

    if (!date || isNaN(dateToFormat.getMilliseconds())) {
        return 'invalid date'
    }

    return `${formatDate(dateToFormat)} ${formatTime(dateToFormat)}`
}

export function formatChatDate(date: string | Date) {
    const dateToFormat = convertToDateObject(date)

    const dateToFormatComponents = getDateComponents(dateToFormat)
    const todayDateComponents = getDateComponents(new Date())

    const isSameDay = JSON.stringify(dateToFormatComponents) === JSON.stringify(todayDateComponents)

    return isSameDay ? formatTime(dateToFormat) : formatDateTime(dateToFormat)
}
