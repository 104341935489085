import styled from 'styled-components'
import { useMemo } from 'react'

import { useUser } from 'features/userProvider/contexts'

import { Breakpoint, Breakpoints } from 'common/theme/breakpoint'
import { AppRoutes } from 'common/routing/routes'
import { UserRoles } from 'common/types/userRoles'

import {
    adminMenuElements,
    guestMenuElement,
    instructorMenuElements,
    userMenuElements
} from '../constants/menuElements'

import profile from '../assets/profile.svg'

import { MenuElement } from './MenuElement'

export function Menu() {
    const { user } = useUser()

    const useProfileElement = useMemo(
        () => ({
            label: 'Profil',
            image: user?.photo || profile,
            url: AppRoutes.ProfilePage.getPath(),
            ariaLabel: 'Przejdź do profilu'
        }),
        [user?.photo]
    )

    const menuElementsToRender = useMemo(() => {
        if (user) {
            if (user.roleName === UserRoles.INSTRUCTOR) {
                return instructorMenuElements
            }

            if (user.roleName === UserRoles.ADMINISTRATOR) {
                return adminMenuElements
            }

            if (user.roleName === UserRoles.USER) {
                return userMenuElements
            }
        }

        return guestMenuElement
    }, [user])

    return (
        <Wrapper>
            <List>
                {menuElementsToRender.map(({ label, ...rest }) => (
                    <MenuElement {...rest} label={label} key={label} />
                ))}
                {user && (
                    <MenuElement
                        {...useProfileElement}
                        label={useProfileElement.label}
                        key={useProfileElement.label}
                        rounded
                    />
                )}
            </List>
        </Wrapper>
    )
}

const Wrapper = styled.nav`
    background-color: ${props => props.theme.colors.white};

    @media (${Breakpoint.BreakpointsDown(Breakpoints.tablet)}) {
        z-index: ${props => props.theme.zIndex.menu};

        position: fixed;
        bottom: 0;

        width: 100%;
    }
`

const List = styled.ul`
    margin: 0;
    padding: 1.5rem;

    list-style: none;

    display: flex;
    align-items: center;
    justify-content: space-around;
`
