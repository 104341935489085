import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CoursesStatuses } from 'common/types'
import { formatDate } from 'common/utils/date'

import { CourseStatusTag } from './CourseStatusTag'

type CourseDatesANdVoivodeshipProps = {
    startsAt: string
    endsAt: string
    voivodeshipName: string
    statusName: CoursesStatuses
    showStatusTag?: boolean
    showCourseDateRange?: boolean
}

export function CourseDatesAndVoivodeship({
    startsAt,
    endsAt,
    statusName,
    voivodeshipName,
    showStatusTag,
    showCourseDateRange
}: CourseDatesANdVoivodeshipProps) {
    const { t } = useTranslation()

    return (
        <>
            <ShortDescriptionDetail>
                {showCourseDateRange ? (
                    <>
                        {formatDate(startsAt)} - {formatDate(endsAt)}
                    </>
                ) : (
                    <>
                        {/* // TODO: wyniesc te tlumaczenia do commona a nie pod search */}
                        {t('searchResults.courseElement.startDate')} {formatDate(startsAt)}
                    </>
                )}
            </ShortDescriptionDetail>
            <VoivodeshipAndStatus>
                {voivodeshipName}
                {showStatusTag && <CourseStatusTag status={statusName} />}
            </VoivodeshipAndStatus>
        </>
    )
}

const ShortDescriptionDetail = styled.p`
    font-size: 0.75rem;

    margin: 0;
`

const VoivodeshipAndStatus = styled(ShortDescriptionDetail)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
