import { InputHTMLAttributes } from 'react'
import { useController } from 'react-hook-form'

import { DateInput } from '../DateInput'

import { FormFieldWrapper } from './FormFieldWrapper'

interface FormDateFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
    label?: string
}

export function FormDateField({ name, defaultValue, label }: FormDateFieldProps) {
    const {
        field: { onChange, ...restField },
        fieldState: { error }
    } = useController({
        name,
        defaultValue
    })

    const handleChange = (date: Date) => {
        onChange(date)
    }

    return (
        <FormFieldWrapper error={error?.message} label={label} name={name}>
            <DateInput
                id={name}
                // @ts-ignore
                onChange={handleChange}
                disableCalendar
                locale="pl-PL"
                format="dd-MM-y"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                yearPlaceholder="yyyy"
                {...restField}
            />
        </FormFieldWrapper>
    )
}
