import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormEvent } from 'react'

import { Button, FormTextArea } from 'common/components'

type AddExternalIdentifiersProps = {
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void
    isMutating: boolean
}

export function AddExternalIdentifiersForm({ handleSubmit, isMutating }: AddExternalIdentifiersProps) {
    const { t } = useTranslation()

    return (
        <form onSubmit={handleSubmit}>
            <FormTextArea
                name="numbers"
                label="admin.externalIdentifiers.fields.numbers.label"
                placeholder={t('admin.externalIdentifiers.fields.numbers.placeholder') as string}
                rows={5}
            />

            <SubmitButton type="submit" disabled={isMutating}>
                {t('admin.externalIdentifiers.submitLabel')}
            </SubmitButton>
        </form>
    )
}

const SubmitButton = styled(Button)`
    width: 100%;
    margin-top: 0.25rem;
`
