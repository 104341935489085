import { PropsWithChildren } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { theme } from './theme'
import { BaseStyles } from './baseStyle'

export const ThemeProvider = ({ children }: PropsWithChildren<{}>) => (
    <>
        <BaseStyles />
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </>
)
