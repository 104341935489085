import { useApi } from 'common/hooks'
import { Attachment, ID } from 'common/types'

type UploadNewAttachmentParams = {
    arg: File
}

export function useUploadNewAttachment(courseId: ID, courseGroupId: ID, isAdmin?: boolean) {
    const api = useApi()

    const url = isAdmin
        ? `/admin/courses/${courseId}/groups/${courseGroupId}/files/upload`
        : `/session/instructor/courses/${courseId}/groups/${courseGroupId}/files/upload`

    return (_: string, { arg: file }: UploadNewAttachmentParams) =>
        api.post<any, Attachment>(
            url,
            { file },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
}
