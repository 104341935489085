import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

import { useGetAdminSearchConfiguration } from 'common/api'
import { Loader, Error, ErrorModal } from 'common/components'
import { ID } from 'common/types'
import { dateFormatForBackend } from 'common/utils/date'
import { cacheKeys } from 'common/constants'

import { GroupFormData } from '../types/groupFormData'
import { useUpdateCourseGroup } from '../api/useUpdateCourseGroup'

import { AdminSelectedGroupDetailsForm } from './AdminSelectedGroupDetailsForm'

type AdminSelectedGroupDetailsEditableProps = {
    participantsCount: number
    voivodeshipId: ID
    instructorId: ID
    startsAt: string
    endsAt: string
    maximumMembers: number
    closeEdit: () => void
    courseId: ID
    groupId: ID
}

export function AdminSelectedGroupDetailsEditable({
    endsAt,
    instructorId,
    maximumMembers,
    startsAt,
    voivodeshipId,
    participantsCount,
    closeEdit,
    courseId,
    groupId
}: AdminSelectedGroupDetailsEditableProps) {
    const [displayErrorModal, setDisplayErrorModal] = useState(false)

    const { t } = useTranslation()

    const fetcher = useGetAdminSearchConfiguration()
    const updater = useUpdateCourseGroup(courseId, groupId)

    const cacheKeyForAdminGroups = cacheKeys.adminCourseGroups(courseId)

    const { data, error, isLoading } = useSWR('/admin-search-configurator', fetcher)

    const { trigger, isMutating } = useSWRMutation(cacheKeyForAdminGroups, updater, {
        throwOnError: false,
        onError: () => {
            setDisplayErrorModal(true)
        },
        onSuccess: () => {
            closeEdit()
        }
    })

    const defaultData: GroupFormData = {
        instructorId,
        maximumMembers,
        voivodeshipId,
        participantsCount,
        dateRange: {
            from: new Date(startsAt),
            to: new Date(endsAt)
        }
    }

    const handleSubmit = (data: GroupFormData) => {
        trigger({
            instructorId: data.instructorId,
            maximumMembers: data.maximumMembers,
            voivodeshipId: data.voivodeshipId,
            startsAt: dateFormatForBackend(data.dateRange.from),
            endsAt: dateFormatForBackend(data.dateRange.to)
        })
    }

    const closeErrorModal = () => setDisplayErrorModal(false)

    if (isLoading || isMutating) {
        return <Loader />
    }

    if (error) {
        return (
            <Error
                title="admin.courseDetails.groupManage.getConfigurationError.title"
                description="admin.courseDetails.groupManage.getConfigurationError.description"
            />
        )
    }

    return (
        <>
            <AdminSelectedGroupDetailsForm
                closeEdit={closeEdit}
                defaultData={defaultData}
                instructorsOptions={data?.instructors || []}
                voivodeshipOptions={data?.voivodeships || []}
                handleSubmit={handleSubmit}
                participantsCount={participantsCount}
            />
            <ErrorModal
                confirmLabel={t('admin.courseDetails.groupManage.saveErrorModal.confirmLabel')}
                description={t('admin.courseDetails.groupManage.saveErrorModal.description')}
                isOpen={displayErrorModal}
                onClose={closeErrorModal}
                onConfirm={closeErrorModal}
                title={t('admin.courseDetails.groupManage.saveErrorModal.title')}
            />
        </>
    )
}
