import useSWR, { useSWRConfig } from 'swr'
import { useMemo } from 'react'

import { useGetAsset } from 'common/api'
import { cacheKeys } from 'common/constants'

type UseGetFileOnceProps = {
    fileHash: string
    queryString?: string
    isAuthorized?: boolean
}

export function useGetFileOnce({ fileHash, isAuthorized = true, queryString = '' }: UseGetFileOnceProps) {
    const { cache } = useSWRConfig()

    const basicUrl = isAuthorized ? cacheKeys.getFileAuthorized(fileHash) : cacheKeys.getFileUnauthorized(fileHash)
    const fullUrl = `${basicUrl}${queryString ? `?${queryString}` : ''}`

    const fetcher = useGetAsset(fullUrl)

    const cacheKeyValue = cache.get(fullUrl)

    const shouldGetFileUrl = !!fileHash

    const shouldFetchData = useMemo(() => {
        if (!shouldGetFileUrl) {
            return false
        }

        return !cacheKeyValue?.data
    }, [shouldGetFileUrl, cacheKeyValue])

    const fileUrl = useMemo(() => {
        if (!shouldGetFileUrl) {
            return undefined
        }

        return cacheKeyValue?.data ? URL.createObjectURL(cacheKeyValue.data.data) : undefined
    }, [shouldGetFileUrl, cacheKeyValue])

    const { isLoading, error } = useSWR(shouldFetchData ? fullUrl : undefined, fetcher)

    return {
        fileUrl,
        isLoading,
        error
    }
}
