import { z } from 'zod'

export const adminSearchCourseFormSchema = z.object({
    region: z.optional(z.number()).nullable(),
    course: z.optional(z.number()).nullable(),
    instructor: z.optional(z.number()).nullable(),
    dateRange: z.object(
        {
            from: z.date({
                required_error: 'admin.search.fields.dateRange.from.required',
                invalid_type_error: 'admin.search.fields.dateRange.from.required'
            }),
            to: z.date({
                required_error: 'admin.search.fields.dateRange.to.required',
                invalid_type_error: 'admin.search.fields.dateRange.to.required'
            })
        },
        {
            required_error: 'admin.search.fields.dateRange.required'
        }
    )
})

export type AdminSearchCourseFormData = z.infer<typeof adminSearchCourseFormSchema>
