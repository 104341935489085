import { useMemo, useState } from 'react'

import { ProfileImage } from 'common/components'

import { UserImageUpload } from './UserImageUpload'

type UserImageWithUpdateProps = {
    userImageUrl?: string
    updater: (_: string, params: { arg: File }) => Promise<unknown>
    onSuccessCallback?: (newFileUrl: string) => void
    revalidateCacheKey: string
}

export function UserImageWithUpdate({
    userImageUrl,
    revalidateCacheKey,
    updater,
    onSuccessCallback
}: UserImageWithUpdateProps) {
    const [newFileToSet, setNewFileToSet] = useState<File | undefined>(undefined)

    const userImage = useMemo(() => {
        if (newFileToSet) {
            return URL.createObjectURL(newFileToSet)
        }

        return userImageUrl
    }, [newFileToSet, userImageUrl])

    return (
        <section>
            <ProfileImage userImage={userImage} />
            <UserImageUpload
                onSetNewFile={setNewFileToSet}
                newFile={newFileToSet}
                userHasPhoto={!!userImageUrl}
                revalidateCacheKey={revalidateCacheKey}
                updater={updater}
                onSuccessCallback={onSuccessCallback}
            />
        </section>
    )
}
