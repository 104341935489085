import styled from 'styled-components'
import { t } from 'i18next'
import { FC } from 'react'

import { CourseMember, ID } from 'common/types'
import { NoDataElement } from 'common/components'

import { ListGroupChat } from './ListGroupChat'

type CourseMembersListProps = {
    title?: string
    membersList: CourseMember[]
    component: FC<CourseMember>
    additionalPropsForComponent?: Record<string, unknown>
    groupChatId?: ID
}

export function CourseMembersList({
    membersList,
    title,
    component: Component,
    additionalPropsForComponent = {},
    groupChatId
}: CourseMembersListProps) {
    return (
        <Wrapper>
            {title && <Title>{title}</Title>}
            {membersList?.length || groupChatId ? (
                <MemberList>
                    {groupChatId && <ListGroupChat id={groupChatId} />}
                    {membersList.map(({ id, ...rest }) => (
                        <Component key={id} id={id} {...rest} {...additionalPropsForComponent} />
                    ))}
                </MemberList>
            ) : (
                <NoDataElement title={t('membersList.empty.title')} />
            )}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;

    width: 100%;
`

const Title = styled.h5`
    font-size: 0.75rem;
    font-weight: 700;

    margin: 0 0 0.75rem 0;
`

const MemberList = styled.ul`
    list-style: none;

    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`
