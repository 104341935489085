import { ID } from 'common/types'

import { CourseBox, CourseDatesAndVoivodeship } from 'common/components'

import { UserCourse as UserCourseType } from '../types/userCourse'

import { UserCourseDetail } from './UserCourseDetails'

interface UserCourseProps extends UserCourseType {
    isActive: boolean
    setAsActive: (id: ID) => void
}

export function UserCourse({
    courseName,
    instructorName,
    id,
    setAsActive,
    isActive,
    endsAt,
    startsAt,
    voivodeshipName,
    statusName
}: UserCourseProps) {
    const handleOpen = (idToOpen: ID) => setAsActive(idToOpen)

    return (
        <CourseBox
            courseName={courseName}
            changeOpenElement={handleOpen}
            instructorFullName={instructorName}
            isActive={isActive}
            id={id}
            details={
                <CourseDatesAndVoivodeship
                    startsAt={startsAt}
                    endsAt={endsAt}
                    statusName={statusName}
                    voivodeshipName={voivodeshipName}
                    showCourseDateRange
                />
            }
            activeDetails={<UserCourseDetail id={id} />}
        />
    )
}
