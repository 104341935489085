import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from './Button'

type SearchResultBackToSearchWrapperProps = {
    url: string
    routeState?: Record<string, unknown>
    buttonLabel: string
}

export function SearchResultBackToSearch({ url, routeState, buttonLabel }: SearchResultBackToSearchWrapperProps) {
    return (
        <StyledSearchResultBackToSearchWrapper>
            <Link to={url} state={routeState}>
                <Button isSmall>{buttonLabel}</Button>
            </Link>
        </StyledSearchResultBackToSearchWrapper>
    )
}

const StyledSearchResultBackToSearchWrapper = styled.div`
    position: fixed;
    bottom: 5rem;
    width: calc(100vw - 3rem);

    button {
        width: 100%;
    }
`
