import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type AdminUsersErrorProps = {
    errorList: string[]
}

export function AdminUsersError({ errorList }: AdminUsersErrorProps) {
    const { t } = useTranslation()

    return (
        <Wrapper>
            <Title>{t('admin.courseDetails.addNewUsers.errorModal.usersErrorTitle')}</Title>
            <List>
                {errorList.map(singleError => (
                    <Element key={singleError}>{singleError}</Element>
                ))}
            </List>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Title = styled.h6`
    margin: 0;
    font-size: 0.75rem;
`

const List = styled.ul`
    margin: 0.5rem 0 0 0;
    padding: 0 0 0 20px;
`

const Element = styled.li`
    font-size: 0.75rem;
`
