export enum Breakpoints {
    mobile = '0',
    tablet = '769',
    desktop = '1200'
}

const BreakpointsUp = (breakpoint: Breakpoints) => `min-width: ${breakpoint}px`

const BreakpointsDown = (breakpoint: Breakpoints) => `max-width: ${Number(breakpoint) - 0.05}px`

export const Breakpoint = {
    BreakpointsUp,
    BreakpointsDown
}
