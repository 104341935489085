import styled from 'styled-components'

export const UserPropertiesElement = styled.li`
    width: 100%;

    background-color: ${props => props.theme.colors.blueBackground};

    padding: 0.5rem;

    p {
        font-size: 0.75rem;

        margin: 0;
    }

    p.title {
        font-weight: 700;
    }
`
