import useSWR from 'swr'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ID } from 'common/types'
import { Button, CourseDescriptionAndPhoto, CourseRequiredCoursesList, Error, Loader } from 'common/components'
import { AppRoutes } from 'common/routing/routes'

import { useGetGuestSearchCourseDetails } from '../api/useGetGuestSearchCourseDetails'

type GuestSearchCourseDetailsProps = {
    id: ID
}

export function GuestSearchCourseDetails({ id }: GuestSearchCourseDetailsProps) {
    const fetcher = useGetGuestSearchCourseDetails(id)

    const { t } = useTranslation()

    const { data, error, isLoading } = useSWR(`/search/course/groups/${id}`, fetcher)

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return (
            <StyledError
                title="guest.searchResult.getDetailsError.title"
                description="guest.searchResult.getDetailsError.description"
            />
        )
    }

    return (
        <>
            <CourseDescriptionAndPhoto
                description={data?.description || ''}
                photoHash={data?.photoHash}
                authorizedImage={false}
            />
            <CourseRequiredCoursesList coursesList={data?.requiredCourses || []} />
            <Link to={AppRoutes.Register.getPath()} aria-label="Przejdź do rejestracji">
                <StyledButton isSmall type="button">
                    {t('guest.searchResult.resultElement.registerToParticipate')}
                </StyledButton>
            </Link>
        </>
    )
}

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`

const StyledButton = styled(Button)`
    margin-top: 0.75rem;

    width: 100%;
`
