import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type CreateDegreeParams = {
    arg: {
        name: string
        assignedAt: string
        expiresAt: string | null
    }
}

export function useCreateDegree(userId: ID) {
    const api = useApi()

    return (_: string, { arg }: CreateDegreeParams) => api.post<any, any>(`/admin/users/${userId}/degrees/create`, arg)
}
