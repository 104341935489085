import { useApi } from 'common/hooks'
import { ID } from 'common/types'

type UpdatePhotoParams = {
    arg: File
}

type UpdatePhotoResponse = {
    photoHash: string
}

export function useUpdatePhoto(userId: ID) {
    const api = useApi()

    return (_: string, { arg: file }: UpdatePhotoParams) =>
        api.post<any, UpdatePhotoResponse>(
            `/admin/users/${userId}/upload-photo`,
            { file },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
}
