export const polishLocales = {
    months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
    ],
    weekDays: [
        {
            name: 'Poniedziałek',
            short: 'pn'
        },
        {
            name: 'Wtorek',
            short: 'wt'
        },
        {
            name: 'Środa',
            short: 'śr'
        },
        {
            name: 'Czwartek',
            short: 'czw'
        },
        {
            name: 'Piątek',
            short: 'pt'
        },
        {
            name: 'Sobota',
            short: 'sb',
            isWeekend: true
        },
        {
            name: 'Niedziela',
            short: 'nd',
            isWeekend: true
        }
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject: any) {
        return gregorainTodayObject
    },

    // return a native JavaScript date here
    toNativeDate(date: any) {
        return new Date(date.year, date.month - 1, date.day)
    },

    // return a number for date's month length
    getMonthLength(date: any) {
        return new Date(date.year, date.month, 0).getDate()
    },

    // return a transformed digit to your locale
    transformDigit(digit: any) {
        return digit
    },

    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    // for input range value
    from: 'from',
    to: 'to',

    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false
}
