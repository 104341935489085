import { createContext, useContext } from 'react'

export interface AuthorizationContextData {
    isLogged: boolean
    token: string | null
    login: (token: string) => void
    logout: () => Promise<boolean>
}

export const AuthorizationContext = createContext<AuthorizationContextData | undefined>(undefined)

export const useAuth = () => {
    const context = useContext(AuthorizationContext)

    if (context === undefined) {
        throw new Error('useAuth used outside AuthorizationProvider')
    }

    return context
}
