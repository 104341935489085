export const theme = {
    colors: {
        black: '#000',
        white: '#fff',

        blue: '#233C98',
        darkBlue: '#132258',

        blueBackground: 'rgba(35, 60, 152, 0.1)',
        darkBlueBackground: 'rgba(35, 60, 152, 0.4)',

        border: '#1322581A',
        borderDark: `#1322584d`,

        errorFont: '#cf1322',
        errorBackground: '#fff1f0',
        errorBorder: '#ffa39e',

        warningBackground: '#fffbe6',
        warningBorder: '#ffe58f',
        warningFont: '#d48806',

        successBackground: '#f6ffed',
        successBorder: '#b7eb8f',
        successFont: '#52c41a',

        disabledBackground: 'rgba(19, 34, 88, 0.4)',

        menuBackground: '#eef2fd',

        backgroundGrey: '#f0f4f8'
    },
    zIndex: {
        menu: 5,
        select: 4
    }
}

type ThemeInterface = typeof theme

declare module 'styled-components' {
    // eslint-disable-next-line no-unused-vars
    interface DefaultTheme extends ThemeInterface {}
}
