import { AxiosResponse } from 'axios'

import { useApi } from 'common/hooks'
import { ID } from 'common/types'

export function useGetCertificate(courseId: ID) {
    const api = useApi('v1', true)

    return () =>
        api.get<any, AxiosResponse<Blob>>(`download/certificate?courseId=${courseId}`, {
            responseType: 'blob'
        })
}
