import { useApi } from 'common/hooks'
import { GroupChat, IndividualChat } from '../types'

interface UseGetChatsListResponse {
    groupChats: GroupChat[]
    individualChats: IndividualChat[]
}

export function useGetChatsList() {
    const api = useApi('v2')
    return () => api.get<any, UseGetChatsListResponse>(`/chats/collapsed`)
}
