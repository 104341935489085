import useSWR from 'swr'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CourseMembersList, CourseMembersListElement } from 'features/courseMembersList'

import {
    CourseCertificate,
    CourseDescriptionAndPhoto,
    Loader,
    ToggleButton,
    Error,
    CourseAttachments
} from 'common/components'
import { CourseCompletionStatus, ID } from 'common/types'
import { useGetCourseDetails } from 'common/api'

type UserCourseDetailProps = {
    id: ID
}

export function UserCourseDetail({ id }: UserCourseDetailProps) {
    const fetcher = useGetCourseDetails(id)
    const [displayedSection, setDisplayedSection] = useState<'details' | 'members'>('details')
    const { t } = useTranslation()

    const { data, error, isLoading } = useSWR(`/courses/groups/${id}`, fetcher)

    if (isLoading) {
        return <Loader />
    }

    if (error) {
        return (
            <StyledError
                title="userCourses.getCourseDetails.errorTitle"
                description="userCourses.getCourseDetails.detailsError"
            />
        )
    }

    return (
        <>
            <StyledToggle<'details', 'members'>
                leftLabel={t('courseBox.toggleButton.detailsLabel')}
                rightLabel={t('courseBox.toggleButton.membersLabel')}
                leftValue="details"
                rightValue="members"
                value={displayedSection}
                onChange={setDisplayedSection}
            />
            {displayedSection === 'details' ? (
                <>
                    <CourseDescriptionAndPhoto description={data?.content || ''} photoHash={data?.photoHash} />
                    <CourseAttachments
                        attachmentList={data?.educationalAttachments || []}
                        label="attachmentsManage.educationalAttachments"
                    />
                    <CourseAttachments
                        attachmentList={data?.attachments || []}
                        label="attachmentsManage.supplementaryMaterials"
                    />
                    <CourseCertificate
                        canGenerate={data?.completionStatusName === CourseCompletionStatus.SUCCESS}
                        courseId={data?.courseId || 0}
                    />
                </>
            ) : (
                <CourseMembersList
                    membersList={data?.members || []}
                    component={CourseMembersListElement}
                    groupChatId={data?.chatId}
                />
            )}
        </>
    )
}

const StyledToggle = styled(ToggleButton)`
    margin-top: 0.75rem;
` as typeof ToggleButton

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`
