import { AxiosResponse } from 'axios'

export function downloadFile(response: AxiosResponse<Blob, any>, fallbackName: string) {
    const cookieWithName: string = response.headers['content-disposition']
    const fileNamePart = (cookieWithName?.split(';') || []).find(substring =>
        substring.toLowerCase().includes('filename=')
    )
    const fileName = fileNamePart ? fileNamePart.toLowerCase().replace('filename=', '') : fallbackName
    const formattedFileName = fileName.replaceAll('"', '').trim()

    const fileUrl = URL.createObjectURL(response.data)

    const tempLink = document.createElement('a')
    tempLink.href = fileUrl
    if (formattedFileName) {
        tempLink.setAttribute('download', formattedFileName)
    }
    tempLink.click()
}
