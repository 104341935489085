import { useTranslation } from 'react-i18next'

import { UserThumbnail } from 'common/components'

import chats from 'common/assets/chats.svg'
import { formatDateTime } from 'common/utils/date'
import { AppRoutes } from 'common/routing/routes'

import { GroupChat } from '../types'
import { ChatContentWrapper } from './ChatContentWrapper'
import { ChatTitle } from './ChatTitle'
import { ChatDetailsRow } from './ChatDetailsRow'
import { ChatMessageIcon } from './ChatMessageIcon'
import { ChatLinkWrapper } from './ChatLinkWrapper'

export function GroupChatBox({ chatId, chatMembersCount, courseName, latestMessageSentAt }: GroupChat) {
    const { t } = useTranslation()

    return (
        <li>
            <ChatLinkWrapper to={AppRoutes.ChatPage.getPath({ chatId })} aria-label="Przejdź do chatu grupowego">
                <UserThumbnail isGroup />
                <ChatContentWrapper>
                    <ChatTitle>{courseName}</ChatTitle>
                    <ChatDetailsRow>
                        {chatMembersCount} {t('chats.membersLabel')}
                        {latestMessageSentAt && (
                            <>
                                , {t('chats.lastMessageLabel')} {formatDateTime(latestMessageSentAt)}
                            </>
                        )}
                    </ChatDetailsRow>
                </ChatContentWrapper>

                <ChatMessageIcon src={chats} />
            </ChatLinkWrapper>
        </li>
    )
}
