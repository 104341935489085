import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PasswordResetFormData, ResetPasswordForm, AfterReset } from 'features/passwordReset'

import { Button, Error } from 'common/components'
import { AppRoutes } from 'common/routing/routes'
import { resetPassword } from 'common/api'

export function ResetPassword() {
    const { t } = useTranslation()

    const [afterReset, setAfterReset] = useState(false)
    const [resetError, setResetError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (formData: PasswordResetFormData) => {
        setResetError(false)
        setIsLoading(true)

        try {
            await resetPassword(formData.email)
            setAfterReset(true)
        } catch (e) {
            setResetError(true)
        } finally {
            setIsLoading(false)
        }
    }

    if (afterReset) {
        return (
            <Wrapper>
                <AfterReset />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <ResetPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
            {resetError && (
                <StyledError
                    title="resetPassword.globalError.title"
                    description="resetPassword.globalError.description"
                />
            )}
            <GuestLink to={AppRoutes.LoginPage.getPath()}>
                <Button isSmall>{t('resetPassword.backToLoginPage')}</Button>
            </GuestLink>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 320px;
    width: 75%;

    z-index: 1;

    display: flex;
    flex-direction: column;
`

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`

const GuestLink = styled(Link)`
    font-weight: 700;
    line-height: 3;

    text-decoration: none;

    z-index: 1;

    > button {
        width: 100%;

        text-transform: none;

        font-size: 0.875rem;
    }
`
