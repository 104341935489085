import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Attachment, ID } from 'common/types'
import { EditIcon } from 'common/assets/EditIcon'
import { ButtonIcon } from 'common/components'

import { CourseAttachmentsSectionWrapper } from './CourseAttachmentsSectionWrapper'
import { CourseAttachmentsListComponents } from './CourseAttachmentsListComponents'
import { CourseAttachmentsEdit } from './CourseAttachmentsEdit'

type CourseAttachmentsWithEditProps = {
    attachmentList: Attachment[]
    courseId: ID
    groupId: ID
    revalidateKey: string
    isAdmin?: boolean
}

export function CourseAttachmentsWithEdit({
    attachmentList,
    courseId,
    groupId,
    revalidateKey,
    isAdmin = false
}: CourseAttachmentsWithEditProps) {
    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleTurnOnEdit = () => setEdit(true)
    const handleTurnOffEdit = () => setEdit(false)

    return (
        <CourseAttachmentsSectionWrapper>
            <h6>
                {t('attachmentsManage.supplementaryMaterials')}
                {!edit && (
                    <ButtonIcon onClick={handleTurnOnEdit} aria-label="Edytuj materiały">
                        <EditIcon />
                    </ButtonIcon>
                )}
            </h6>
            {edit ? (
                <CourseAttachmentsEdit
                    attachmentList={attachmentList}
                    closeEdit={handleTurnOffEdit}
                    courseId={courseId}
                    groupId={groupId}
                    revalidateKey={revalidateKey}
                    isAdmin={isAdmin}
                />
            ) : (
                <CourseAttachmentsListComponents attachmentList={attachmentList} />
            )}
        </CourseAttachmentsSectionWrapper>
    )
}
