import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMemo } from 'react'

import { TopBar } from 'common/components/TopBar'

import { Menu } from '../../AuthorizedLayout/components/Menu'

import { unauthorizedRoutesLayoutConfiguration } from '../constants/unathorizedRoutesLayoutConfiguration'

export function UnauthorizedLayout() {
    const location = useLocation()

    const outletState = useMemo(() => unauthorizedRoutesLayoutConfiguration[location.pathname], [location])

    return (
        <>
            <TopBar smallLabel={outletState?.isSmallLabel || false} label={outletState?.pageTitle || ''} />
            {outletState?.showMenu && <Menu />}

            <ContentWrapper
                showMenu={outletState?.showMenu}
                greyBackground={outletState?.useGreyBackground}
                role="main"
            >
                <Outlet />
            </ContentWrapper>
        </>
    )
}

export const ContentWrapper = styled.section<{ showMenu: boolean; greyBackground: boolean }>`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    position: relative;

    background-color: ${props => (props.greyBackground ? props.theme.colors.backgroundGrey : props.theme.colors.white)};

    ${props =>
        props.showMenu &&
        `
        padding: 0 1.5rem 5.0625rem;

        justify-content: flex-start;
        align-items: unset;
    `}
`
