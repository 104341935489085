import { ComponentType, useState } from 'react'

import { ID } from 'common/types'

import { UserPropertiesElementWrapper } from './UserPropertiesElementWrapper'

interface InstructorPropertiesElementProps<EntityType> {
    userId: ID
    element: EntityType
    editComponent: ComponentType<EntityType>
    noEditComponent: ComponentType<EntityType>
}

export function InstructorPropertiesElement<EntityType extends { id: ID }>({
    userId,
    element,
    editComponent: EditComponent,
    noEditComponent: NoEditComponent
}: InstructorPropertiesElementProps<EntityType>) {
    const [rowEditOn, setRowEditOn] = useState(false)

    const handleEditOn = () => setRowEditOn(true)
    const handleEditOff = () => setRowEditOn(false)

    return (
        <UserPropertiesElementWrapper>
            {rowEditOn ? (
                <EditComponent {...element} userId={userId} turnOffEdit={handleEditOff} />
            ) : (
                <NoEditComponent {...element} turnOnEdit={handleEditOn} userId={userId} />
            )}
        </UserPropertiesElementWrapper>
    )
}
