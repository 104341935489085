import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

export function usePageName(newPageName: string) {
    const [pageName, setPageName] = useOutletContext<[string, (pageName: string) => void]>()

    useEffect(() => {
        setPageName(newPageName)
    }, [newPageName, setPageName])

    return [pageName, setPageName]
}
