import { useEffect, useState } from 'react'

import { UserImageWithUpdate } from 'common/components'
import { useGetFileOnce } from 'common/hooks'
import { ID } from 'common/types'

import { useUpdatePhoto } from '../api/useUpdatePhoto'

type UserPhotoSectionProps = {
    userPhotoHash: string
    userID: ID
}

export function UserPhotoSection({ userPhotoHash, userID }: UserPhotoSectionProps) {
    const [userPhotoUrl, setUserPhotoUrl] = useState('')

    const { fileUrl } = useGetFileOnce({ fileHash: userPhotoHash })

    useEffect(() => {
        if (fileUrl) {
            setUserPhotoUrl(fileUrl)
        }
    }, [fileUrl])

    const photoUpdater = useUpdatePhoto(userID)

    const onUpdatePhotoSuccess = (photoUrl: string) => {
        setUserPhotoUrl(photoUrl)
    }

    return (
        <UserImageWithUpdate
            userImageUrl={userPhotoUrl}
            revalidateCacheKey="/profile-photo-change"
            updater={photoUpdater}
            onSuccessCallback={onUpdatePhotoSuccess}
        />
    )
}
