import { Menu as ReactMenu } from '@szhsin/react-menu'
import styled from 'styled-components'

export const Menu = styled(ReactMenu)`
    z-index: 5;

    > ul {
        background-color: ${props => props.theme.colors.menuBackground};

        > li.szh-menu__arrow {
            background-color: ${props => props.theme.colors.menuBackground};
        }
    }
`
