import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, FormTextField } from 'common/components'

import { PasswordResetFormData, passwordResetSchema } from '../types/passwordResetFormData'

type ResetPasswordFormProps = {
    onSubmit: (formData: PasswordResetFormData) => void
    isLoading: boolean
}

export function ResetPasswordForm({ onSubmit, isLoading }: ResetPasswordFormProps) {
    const { t } = useTranslation()

    const formMethods = useForm<PasswordResetFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(passwordResetSchema)
    })

    const placeholder = t('resetPassword.emailField.label')

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <StyledInput name="email" placeholder={placeholder} />
                <StyledButton type="submit" disabled={isLoading}>
                    {t('resetPassword.submitButtonLabel')}
                </StyledButton>
            </Form>
        </FormProvider>
    )
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const StyledButton = styled(Button)`
    width: 100%;

    text-transform: none;

    font-size: 0.875rem;
`

const StyledInput = styled(FormTextField)`
    background-color: rgba(255, 255, 255, 0.6);

    font-size: 1rem;

    height: 3.375rem;
`
