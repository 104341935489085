import { useMemo } from 'react'
import useSWR from 'swr'

import { ID } from 'common/types'
import { Error, Loader } from 'common/components'

import { useUser } from 'features/userProvider/contexts'

import { useGetChatDetails } from '../api/useGetChatDetails'

import { Chat } from './Chat'

type ChatWrapperProps = {
    chatId: ID
}

export function ChatWrapper({ chatId }: ChatWrapperProps) {
    const fetcher = useGetChatDetails(chatId)
    const { user } = useUser()

    const { data, error, isLoading } = useSWR(`/chats/${chatId}`, fetcher)

    const courseDetails = useMemo(() => {
        if (!data) {
            return {
                coursePhotoHash: '',
                isGroup: false,
                chatName: ''
            }
        }

        const isGroup = data.type !== 'individual'
        const recipmentForIndividual = data.members.filter(member => member.userId !== user?.id)[0]

        const chatName = isGroup ? data.courseName : recipmentForIndividual.fullName

        return {
            isGroup,
            chatName: chatName || '',
            coursePhotoHash: isGroup ? undefined : recipmentForIndividual.photoHash
        }
    }, [data, user?.id])

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        return <Error title="chat.error.errorTitle" description="chat.error.detailsError" />
    }

    return (
        <Chat
            chatId={Number(chatId)}
            isGroup={courseDetails.isGroup}
            chatName={courseDetails.chatName}
            chatImageHash={courseDetails.coursePhotoHash}
            chatMembers={data?.members || []}
        />
    )
}
