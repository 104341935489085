import { useApi } from 'common/hooks'

type CreateExternalIdentifiersParams = {
    arg: {
        numbers: string
    }
}

export function useCreateExternalIdentifiers() {
    const api = useApi()

    return (_: string, { arg }: CreateExternalIdentifiersParams) =>
        api.post<any, undefined>('/admin/courses/external-numeration/add-numbers', arg)
}
