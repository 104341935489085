import styled from 'styled-components'

export const StatusWrapper = styled.div`
    margin: 0px 0px 2.875rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;

    text-align: center;
`
