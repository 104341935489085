import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'

import { Button } from 'common/components'
import { ID } from 'common/types'
import { useGetCertificate } from 'common/api'
import { downloadFile } from 'common/utils/downloadFile'

type CourseCertificateProps = {
    canGenerate: boolean
    courseId: ID
}

export function CourseCertificate({ canGenerate, courseId }: CourseCertificateProps) {
    const { t } = useTranslation()

    const fetcher = useGetCertificate(courseId)

    const { trigger: triggerGetCert, isMutating } = useSWRMutation(
        `download/certificate?courseId=${courseId}`,
        fetcher,
        {
            throwOnError: false,
            onError: e => {
                // eslint-disable-next-line no-console
                console.error(e)
            },
            onSuccess: response => {
                downloadFile(response, 'certificate.pdf')
            }
        }
    )

    const handleGenerate = () => triggerGetCert()

    return (
        <Wrapper>
            <Title>{t('courseBox.courseElement.certificate.sectionLabel')}</Title>
            <CertButton disabled={!canGenerate || isMutating} onClick={handleGenerate}>
                {t('courseBox.courseElement.certificate.buttonLabel')}
            </CertButton>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    margin-top: 0.75rem;
`

const Title = styled.h6`
    font-weight: 700;

    font-size: 0.875rem;

    margin: 0;
`

const CertButton = styled(Button)`
    width: 100%;

    padding: 0.5rem 2rem;

    margin-top: 0.75rem;
`
