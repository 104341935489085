import styled from 'styled-components'
import useSWRMutation from 'swr/mutation'

import { useGetAsset } from 'common/api'
import { Attachment } from 'common/types'
import { DownloadIcon } from 'common/assets/DownloadIcon'
import { cacheKeys } from 'common/constants'
import { ButtonIcon } from 'common/components'
import { downloadFile } from 'common/utils/downloadFile'

export function CourseSingleAttachment({ name, pathHash }: Attachment) {
    const fileUrl = cacheKeys.getFileAuthorized(pathHash)

    const fileFetcher = useGetAsset(fileUrl)

    const { trigger: triggerGetMessages } = useSWRMutation(fileUrl, fileFetcher, {
        throwOnError: false,
        onError: e => {
            // eslint-disable-next-line no-console
            console.error(e)
        },
        onSuccess: response => {
            downloadFile(response, 'attachment')
        }
    })

    const handleGetFile = () => {
        triggerGetMessages()
    }

    return (
        <SingleAttachment onClick={handleGetFile} role="button" aria-label="Pobierz plik">
            {name}
            <ButtonIcon>
                <StyledDownloadIcon />
            </ButtonIcon>
        </SingleAttachment>
    )
}

const SingleAttachment = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: ${props => props.theme.colors.blue};

    color: ${props => props.theme.colors.white};

    font-size: 0.875rem;
    font-size: 0.75rem;
    font-weight: 700;

    text-transform: uppercase;

    padding: 0.5rem;

    button {
        margin: 0;
    }
`

const StyledDownloadIcon = styled(DownloadIcon)`
    fill: ${props => props.theme.colors.white};
`
