import { useApi } from 'common/hooks'

type RegisterParams = {
    arg: {
        firstName: string
        lastName: string
        email: string
        password: string
        street: string
        postalCode: string
        city: string
        note: string | null
    }
}

interface RegisterResponse {
    token: string
}

export function useRegister() {
    const api = useApi()

    return (_: string, { arg }: RegisterParams) => api.post<any, RegisterResponse>(`/auth/register`, arg)
}
