import { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

import { ArrowEmpty } from 'common/assets/ArrowEmpty'

import { ID } from 'common/types'
import { CourseBoxWrapper } from 'common/components'

import { CourseTitle } from './CourseTitle'

type CourseBoxProps = {
    changeOpenElement?: (id: ID) => void
    isActive?: boolean
    courseName: string
    instructorFullName?: string
    details?: ReactNode
    activeDetails: ReactNode
    id: ID
    disablePadding?: boolean
}

export function CourseBox({
    changeOpenElement,
    courseName,
    instructorFullName,
    details,
    activeDetails,
    isActive,
    id,
    disablePadding,
    ...rest
}: CourseBoxProps) {
    const isCallapsable = !!changeOpenElement

    const onDisplayChange = useCallback(() => {
        changeOpenElement?.(isActive ? -1 : id)
    }, [id, changeOpenElement, isActive])

    return (
        <CourseBoxWrapper isActive={isActive} id={`course-${id}`} disablePadding={disablePadding} {...rest}>
            <Header
                onClick={onDisplayChange}
                hideMarginBottom={!isActive && !instructorFullName && !details}
                role="button"
                aria-label="Pokaż szczegóły"
            >
                <CourseTitle>{courseName}</CourseTitle>
                {isCallapsable && <StyledArrow isActive={!!isActive} />}
            </Header>
            {(instructorFullName || details) && (
                <ShortSection>
                    {instructorFullName && <InstructorName>{instructorFullName}</InstructorName>}
                    {details}
                </ShortSection>
            )}
            {(isActive || !isCallapsable) && (
                <>
                    <DetailsWrapper>{activeDetails}</DetailsWrapper>
                    {isCallapsable && (
                        <BottomArrowContainer role="button" aria-label="Schowaj szczegóły">
                            <StyledArrow isActive onClick={onDisplayChange} />
                        </BottomArrowContainer>
                    )}
                </>
            )}
        </CourseBoxWrapper>
    )
}

const Header = styled.div<{ hideMarginBottom: boolean }>`
    margin-bottom: 1rem;

    width: 100%;

    display: flex;
    align-items: baseline;

    ${props =>
        props.hideMarginBottom &&
        `
        margin-bottom: 0rem;

    `}
`

const ShortSection = styled.section`
    display: flex;
    flex-direction: column;

    width: 100%;
`

const InstructorName = styled.p`
    font-weight: 700;

    font-size: 0.75rem;

    margin: 0;
`

const StyledArrow = styled(ArrowEmpty)<{ isActive: boolean }>`
    fill: ${props => props.theme.colors.blue};

    rotate: 180deg;

    transition: all 300ms;

    ${props =>
        props.isActive &&
        `
        rotate: 0deg;
        fill: transparent;

    `}
`

const DetailsWrapper = styled.section`
    width: 100%;
`

const BottomArrowContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-end;

    margin: 1rem 0.5rem 0 0;
`
