import { ChangeEvent } from 'react'
import { useController } from 'react-hook-form'

import { TextInput, TextInputProps } from '../TextInput'

import { FormFieldWrapper } from './FormFieldWrapper'

interface FormSelectFieldProps extends TextInputProps {
    name: string
    label?: string
}

export function FormTextField({ name, defaultValue, label, ...rest }: FormSelectFieldProps) {
    const {
        field: { onChange, ...restField },
        fieldState: { error }
    } = useController({
        name,
        defaultValue
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value)
    }

    return (
        <FormFieldWrapper error={error?.message} label={label} name={name}>
            <TextInput {...rest} {...restField} onChange={handleChange} id={name} />
        </FormFieldWrapper>
    )
}
