import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type NoDataElementProps = {
    title: string
    description?: string | ReactNode
}
export function NoDataElement({ description, title, ...rest }: NoDataElementProps) {
    const { t } = useTranslation()

    return (
        <Wrapper {...rest}>
            <Title>{t(title)}</Title>
            {description && <Description>{typeof description === 'string' ? t(description) : description}</Description>}
        </Wrapper>
    )
}

const Wrapper = styled.section`
    padding: 1rem;

    background-color: ${props => props.theme.colors.blueBackground};

    text-align: center;
`

const Title = styled.p`
    margin: 0;

    font-weight: 700;

    font-size: 0.875rem;
`

const Description = styled.p`
    margin: 0.5rem 0 0 0;

    font-size: 0.75rem;
`
