import useSWR from 'swr'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ID } from 'common/types'
import { Button, CourseDescriptionAndPhoto, CourseRequiredCoursesList, Error, Loader } from 'common/components'
import { useGetSearchCourseDetails } from 'common/api'
import { AppRoutes } from 'common/routing/routes'

type AdminSearchCourseDetailsProps = {
    id: ID
}

export function AdminSearchCourseDetails({ id }: AdminSearchCourseDetailsProps) {
    const { t } = useTranslation()

    const fetcher = useGetSearchCourseDetails(id)
    const { data, error, isLoading } = useSWR(`/search/course/groups/${id}`, fetcher)

    if (error) {
        return (
            <StyledError
                title="admin.searchResults.getCourseDetailsError.title"
                description="admin.searchResults.getCourseDetailsError.description"
            />
        )
    }

    if (isLoading || !data) {
        return <Loader />
    }

    return (
        <>
            <CourseDescriptionAndPhoto description={data?.description || ''} photoHash={data?.photoHash} />
            <CourseRequiredCoursesList coursesList={data?.requiredCourses || []} />

            <StyledLink to={AppRoutes.AdminCourseDetailsPage.getPath({ courseId: data.courseId, groupId: id })}>
                <Button>{t('admin.searchResults.courseElement.goToDetails')}</Button>
            </StyledLink>
        </>
    )
}

const StyledError = styled(Error)`
    margin-top: 0.75rem;
`

const StyledLink = styled(Link)`
    margin-top: 0.75rem;

    display: block;

    > button {
        width: 100%;
    }
`
