import styled from 'styled-components'

import { Attachment } from 'common/types'

import { CourseSingleAttachment } from './CourseSingleAttachment'

type CourseAttachmentsListComponentsProps = {
    attachmentList: Attachment[]
}

export function CourseAttachmentsListComponents({ attachmentList }: CourseAttachmentsListComponentsProps) {
    if (!attachmentList?.length) {
        return null
    }

    return (
        <List>
            {attachmentList.map(({ id, ...rest }) => (
                <CourseSingleAttachment {...rest} key={id} id={id} />
            ))}
        </List>
    )
}

const List = styled.ul`
    list-style: none;

    margin: 0.75rem 0 0 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`
